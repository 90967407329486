import { useContext, useEffect, useState } from "react";
import { format } from "fecha";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../../context/AppContext";
import { getAllTools } from "../../../services/ToolService";

import Autocomplete from "../../../components/Autocomplete";
import BarcodeScannerInput from "../../../components/BarcodeScannerInput/BarcodeScannerInput";
import ImageUploadCard from "../../../components/ImageUploadCard";

const useStyles = makeStyles({
  header: {
    backgroundColor: "lightGray",
    padding: 7,
  },
  addBtn: {
    backgroundColor: "orange",
    color: "white",
  },
});

export default function AddTool({ tool, submitting = false, handleSubmit }) {
  const { allTools, user, setAllTools } = useContext(AppContext);
  const classes = useStyles();
  const [formData, setFormData] = useState(tool);
  const [loadingTools, setLoadingTools] = useState(false);
  const [errors, setErrors] = useState({
    idTag: "",
    tool: "",
    description: "",
    serviceStatus: "",
    price: "",
  });

  const retrieveAllTools = async () => {
    try {
      setLoadingTools(true);
      const newTools = await getAllTools();
      setAllTools(newTools);
      setLoadingTools(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error while loading all tools");
    }
  };

  useEffect(() => {
    retrieveAllTools();
  }, []);

  const formValidate = () => {
    const valid =
      formData.idTag &&
      formData.shortDescription &&
      formData.description &&
      formData.serviceStatus &&
      formData.price;

    setErrors({
      idTag: formData.idTag ? "" : "ID Tag is required.",
      tool: formData.shortDescription ? "" : "Tool is required.",
      description: formData.description ? "" : "Description is required.",
      serviceStatus: formData.serviceStatus
        ? ""
        : "Service status is required.",
      price: formData.price ? "" : "Price is required.",
    });

    return valid;
  };

  const handleAddTool = () => {
    if (!formValidate()) return;

    let data = {
      ID_TAG: formData.idTag,
      TOOL_SHORT_DESCRIPTION: formData.shortDescription,
      TOOL_DESCRIPTION: formData.description,
      SERVICE_STATUS: formData.serviceStatus,
      TOOL_STATUS: user.USERNAME,
      PRICE: parseFloat(formData.price),
      PURCHASE_DATE: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
      TOOL_IMAGES: formData.images
        .map((imageData) => ({
          NAME:
            formData.idTag + "_" + new Date().getTime() + "_" + imageData.name,
          FILE_STREAM: imageData.file,
        }))
        .filter((imageData) => imageData.FILE_STREAM !== null),
    };

    handleSubmit(data);
  };

  const handleIdTagChange = (e) => {
    setErrors({
      ...errors,
      idTag: e.target.value ? "" : "ID Tag is required.",
    });
    setFormData({
      ...formData,
      idTag: e.target.value,
    });
  };

  const handleToolChange = (e, newValue) => {
    setErrors({
      ...errors,
      tool: newValue ? "" : "Tool is required.",
    });
    setFormData({
      ...formData,
      shortDescription: newValue,
    });
  };

  const handleDescriptionChange = (e) => {
    setErrors({
      ...errors,
      description: e.target.value ? "" : "Description is required.",
    });
    setFormData({
      ...formData,
      description: e.target.value,
    });
  };

  const handleServiceStatusChange = (e) => {
    setErrors({
      ...errors,
      serviceStatus: e.target.value ? "" : "Service status is required.",
    });
    setFormData({
      ...formData,
      serviceStatus: e.target.value,
    });
  };

  const handlePriceChange = (e) => {
    setErrors({ ...errors, price: e.target.value ? "" : "Price is required." });
    setFormData({
      ...formData,
      price: e.target.value,
    });
  };

  const handleImageChanged = (fileData, index) => {
    let newImages = [...formData.images];

    if (index < formData.images.length - 1) {
      if (fileData.file !== null)
        newImages = newImages.map((imageData, imageIndex) =>
          index === imageIndex ? fileData : imageData
        );
      else
        newImages = newImages.filter(
          (imageData, imageIndex) => imageIndex !== index
        );
    } else newImages.splice(newImages.length - 1, 0, fileData);

    setFormData({
      ...formData,
      images: newImages,
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader title={<Typography variant="h6">Add New Tool</Typography>} />
      <Divider />
      <CardContent>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BarcodeScannerInput
                  label="ID-Tag"
                  type="search"
                  variant="outlined"
                  value={formData.idTag}
                  onChange={handleIdTagChange}
                  InputLabelProps={{
                    shrink: Boolean(formData.idTag),
                  }}
                  error={!!errors.idTag}
                  helperText={errors.idTag}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Tool"
                    variant="outlined"
                    loading={loadingTools}
                    disabled={loadingTools}
                    options={
                      [
                        ...new Set(
                          allTools.map((tool) => tool.TOOL_SHORT_DESCRIPTION) // to handle the duplicated options
                        ),
                      ] || []
                    }
                    value={formData.tool}
                    onChange={handleToolChange}
                  />
                  {errors.tool && (
                    <FormHelperText error>{errors.tool}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  type="text"
                  variant="outlined"
                  value={formData.description}
                  onChange={handleDescriptionChange}
                  error={!!errors.description}
                  helperText={errors.description}
                  rows={3}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Service Status</InputLabel>
                  <Select
                    value={formData.serviceStatus || ""}
                    onChange={handleServiceStatusChange}
                    error={!!errors.serviceStatus}
                  >
                    <MenuItem value="" key="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Out" key="Out">
                      Broken
                    </MenuItem>
                    <MenuItem value="In" key="In">
                      Ready To Use
                    </MenuItem>
                  </Select>
                  {errors.serviceStatus !== "" && (
                    <FormHelperText error>
                      {errors.serviceStatus}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Price"
                  type="number"
                  variant="outlined"
                  value={formData.price}
                  onChange={handlePriceChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={!!errors.price}
                  helperText={errors.price}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container justifyContent="center" spacing={4}>
              {formData.images.map((image, index) => (
                <Grid item key={index} xs={12} md={3}>
                  <ImageUploadCard
                    image={image.file}
                    handleChange={(file) => handleImageChanged(file, index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={handleAddTool}
              variant="contained"
              className={classes.addBtn}
              disabled={submitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
