import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {},
});

export default function TrimHours({ values, setValues }) {
  return <div></div>;
}
