import { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

export default function SortableTable(props) {
  const {
    columnTitles,
    items,
    accessOrder,
    buttonFunc,
    buttonText,
    itemID = "id",
  } = props;

  const [order, setOrder] = useState("asc");
  const [orderedBy, setOrderedBy] = useState(null);

  useEffect(() => {
    if (columnTitles === null) {
      return;
    }
    setOrderedBy(columnTitles[0]);
  }, [columnTitles]);

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderedBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderedBy(property);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columnTitles.map((title) => (
                <TableCell sortDirection={orderedBy === title ? order : false}>
                  <TableSortLabel
                    active={orderedBy === title}
                    direction={orderedBy === title ? order : "asc"}
                    onClick={createSortHandler(title)}
                  >
                    <b>{title}</b>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              stableSort(items, getComparator(order, orderedBy)).map((row) => (
                <TableRow key={row[itemID]}>
                  {accessOrder.map((accessor, index) =>
                    index === 0 ? (
                      <TableCell component="th" scope="row">
                        {row[accessor] || ""}
                      </TableCell>
                    ) : (
                      <TableCell>{row[accessor] || ""}</TableCell>
                    )
                  )}
                  {buttonFunc && (
                    <TableCell align="right">
                      <Button
                        onClick={() => buttonFunc(row[itemID])}
                        variant="outlined"
                      >
                        {buttonText}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
