import {
  DeleteData,
  GetData,
  PostData,
  PutData,
} from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const addToolOrder = async (toolOrder) => {
  return await PostData(API_URL + "/ToolOrder/POST", toolOrder);
};

const approveToolOrder = async (toolOrder) => {
  return await PutData(API_URL + "/ToolOrder/PUTShop", toolOrder);
};

const editToolOrder = async (toolOrder) => {
  return await PutData(API_URL + "/ToolOrder/PUT", toolOrder);
};

const deleteToolOrder = async (requestId) => {
  return await GetData(API_URL + `/ToolOrder/GETDeleteToolOrder/${requestId}`);
};

const getAllToolOrders = async () => {
  return await GetData(API_URL + `/ToolOrder/GETAll`);
};

const getReviewerToolOrders = async (user) => {
  return await GetData(API_URL + `/ToolOrder/GETReviewer/${user.USERNAME}`);
};

const getToolOrders = async (user) => {
  return await GetData(API_URL + `/ToolOrder/GET/${user.USERNAME}`);
};

export {
  addToolOrder,
  approveToolOrder,
  deleteToolOrder,
  editToolOrder,
  getAllToolOrders,
  getReviewerToolOrders,
  getToolOrders,
};
