import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import {
  deleteSupplier,
  getSuppliers,
  postSupplier,
  putSupplier,
} from "../../../services/SupplierEmailService";

import SuppliersTable from "../../../components/CustomTable/SuppliersTable";

const useStyles = makeStyles({
  root: {},
});

export default function Suppliers() {
  const classes = useStyles();
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const retrieveSuppliers = async () => {
    try {
      setIsLoading(true);
      const resSuppliers = await getSuppliers();
      setSuppliers(resSuppliers);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading suppliers!");
    }
  };

  useEffect(() => {
    retrieveSuppliers();
  }, []);

  const handleSupplierAdd = (newRowData) => {
    const postData = {
      SUPPLIER_NAME: newRowData.SUPPLIER_NAME,
      SUPPLIER_EMAIL: newRowData.SUPPLIER_EMAIL,
    };

    setIsLoading(true);

    postSupplier(postData)
      .then(async (res) => {
        setIsLoading(false);
        if (res.status === 200) {
          await retrieveSuppliers();
          toast.success("Supplier info added.");
        } else toast.error("Supplier info not added!");
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleSupplierEdit = (rowData) => {
    const putData = {
      EMAIL_ID: rowData.EMAIL_ID,
      SUPPLIER_NAME: rowData.SUPPLIER_NAME,
      SUPPLIER_EMAIL: rowData.SUPPLIER_EMAIL,
    };

    setIsLoading(true);

    putSupplier(putData)
      .then(async (res) => {
        console.log(res);
        setIsLoading(false);
        if (res.status === 200) {
          await retrieveSuppliers();
          toast.success("Supplier info updated.");
        } else toast.error("Supplier info not updated!");
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleSupplierDelete = (rowData) => {
    const emailId = rowData.EMAIL_ID;

    setIsLoading(true);

    deleteSupplier(emailId)
      .then(async (res) => {
        setIsLoading(false);
        await retrieveSuppliers();
        toast.success("Supplier info deleted.");
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SuppliersTable
            title="Suppliers"
            loading={isLoading}
            items={suppliers}
            hiddenColumns={["EMAIL_ID"]}
            handleAdd={handleSupplierAdd}
            handleEdit={handleSupplierEdit}
            handleDelete={handleSupplierDelete}
          />
        </Grid>
      </Grid>
    </div>
  );
}
