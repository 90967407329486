import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { getOrder, getUnitMaterials } from "../../../services/OrderService";
import { convertDate } from "../../../utils/function";

import OrderDetails from "../OrdersTable/OrderDetails";

const useStyles = makeStyles({});

export default function OrderSummaryTable({
  title,
  loading,
  items,
  hiddenColumns = [],
  handleSubmit,
}) {
  const classes = useStyles();
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [unitMaterials, setUnitMaterials] = useState(null);
  const [totalCost, setTotalCost] = useState(0);

  const getUnitMaterialsTotalCost = (unitMaterials) => {
    let sum = 0;
    for (let i = 0; i < unitMaterials.length; i++) {
      const item = unitMaterials[i];
      sum += parseFloat(item.ITEM_COST) * parseInt(item.ITEM_QTY);
    }
    return parseFloat(sum).toFixed(2);
  };

  useEffect(() => {
    let mounted = true;

    const retrieveUnitMaterials = async () => {
      let promises = items.map(async (item) => {
        const resMaterials = await getUnitMaterials(item.ORDER_ID);
        return { [item.ORDER_ID]: resMaterials };
      });

      Promise.all(promises)
        .then((results) => {
          const materials = Object.assign({}, ...results);
          let total = 0;
          if (Object.keys(materials).length) {
            for (let i = 0; i < Object.keys(materials).length; i++) {
              const item = materials[Object.keys(materials)[i]];
              total += parseFloat(getUnitMaterialsTotalCost(item));
            }
          }
          if (mounted) {
            setUnitMaterials(materials);
            setTotalCost(total);
          }
        })
        .catch((error) => {
          console.error("Error fetching unit materials:", error);
        });
    };
    retrieveUnitMaterials();

    return () => {
      mounted = false;
    };
  }, [items]);

  const columns = [
    {
      name: "ORDER_ID",
      label: "PO",
    },
    {
      name: "",
      label: "PO Total",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = items[rowIndex];
          const orderId = rowData.ORDER_ID;

          return Object.keys(unitMaterials).length
            ? "$" + getUnitMaterialsTotalCost(unitMaterials[orderId] || [])
            : "";
        },
      },
    },
    {
      name: "PHASE_ID",
      label: "Phase",
    },
    {
      name: "",
      label: "Load",
      options: {
        customBodyRender: (value, tableMeta) => {
          return value ? value : 1;
        },
      },
    },
    {
      name: "",
      label: "Supplier",
    },
    {
      name: "ORDER_TIME",
      label: "Date Ordered",
      options: {
        customBodyRender: (value, tableMeta) => {
          return value ? convertDate(value) : "";
        },
      },
    },
    {
      name: "PO_CREATE_DATE",
      label: "Delivery Date",
      options: {
        customBodyRender: (value, tableMeta) => {
          return value ? convertDate(value) : "";
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    expandableRows: true,
    rowsExpanded: rowsExpanded,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const selectedOrderID = rowData[0];
      const selectedOrder = items.find(
        (item) => item.ORDER_ID === selectedOrderID
      );

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12}>
                <OrderDetails
                  data={selectedOrder}
                  handleUpdate={handleOrderUpdate}
                />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    },
  };

  const handleRowExpandToggle = (rowData) => {
    const expandedRowIndex = rowsExpanded.indexOf(rowData.index);

    if (expandedRowIndex === -1) {
      // Row is currently not expanded, so add it to the rowsExpanded array
      setRowsExpanded([...rowsExpanded, rowData.index]);
    } else {
      // Row is currently expanded, so remove it from the rowsExpanded array
      setRowsExpanded(rowsExpanded.filter((index) => index !== rowData.index));
    }
  };

  const handleOrderUpdate = (payload) => {
    handleSubmit(payload);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading ? (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              ) : (
                <Grid item>
                  <Typography variant="body1" color="grey">
                    (Total Cost: <b>${totalCost.toFixed(2)}</b>)
                  </Typography>
                </Grid>
              )}
            </Grid>
          }
          data={items}
          columns={columns}
          options={options}
          onRowExpansionChange={handleRowExpandToggle}
        />
      </Grid>
    </Grid>
  );
}
