import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { CircularProgress, Grid, Typography } from "@mui/material";

export default function HoursSummaryTable({
  title,
  items,
  hiddenColumns = [],
  loading,
}) {
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "JOB_ID",
      label: "JOB_ID",
    },
    {
      name: "BUILDER",
      label: "BUILDER",
    },
    {
      name: "JOB",
      label: "PROJECT",
    },
    {
      name: "LOT",
      label: "JOB",
    },
    {
      name: "PERCENT_COMPLETE",
      label: "PERCENT_COMPLETE",
    },
    {
      name: "LABOR_HOURS_EST",
      label: "LABOR_HOURS_EST",
    },
    {
      name: "LABOR_HOURS_ACT",
      label: "LABOR_HOURS_ACT",
    },
    {
      name: "LABOR_HRS_ADJ",
      label: "LABOR_HRS_ADJ",
    },
    {
      name: "EST_VS_ACT_HRS",
      label: "EST_VS_ACT_HRS",
    },
    {
      name: "EST_VS_ADJ_HRS",
      label: "EST_VS_ADJ_HRS",
    },
    {
      name: "PHASE1_LABOR_HRS_EST",
      label: "PHASE1_LABOR_HRS_EST",
    },
    {
      name: "PHASE1_LABOR_HRS_ACT",
      label: "PHASE1_LABOR_HRS_ACT",
    },
    {
      name: "PHASE2_LABOR_HRS_EST",
      label: "PHASE2_LABOR_HRS_EST",
    },
    {
      name: "PHASE2_LABOR_HRS_ACT",
      label: "PHASE2_LABOR_HRS_ACT",
    },
    {
      name: "PHASE3_LABOR_HRS_EST",
      label: "PHASE3_LABOR_HRS_EST",
    },
    {
      name: "PHASE3_LABOR_HRS_ACT",
      label: "PHASE3_LABOR_HRS_ACT",
    },
    {
      name: "PHASE4_LABOR_HRS_EST",
      label: "PHASE4_LABOR_HRS_EST",
    },
    {
      name: "PHASE4_LABOR_HRS_ACT",
      label: "PHASE4_LABOR_HRS_ACT",
    },
    {
      name: "PHASE5_LABOR_HRS_EST",
      label: "PHASE5_LABOR_HRS_EST",
    },
    {
      name: "PHASE5_LABOR_HRS_ACT",
      label: "PHASE5_LABOR_HRS_ACT",
    },
    {
      name: "PHASE6_LABOR_HRS_EST",
      label: "PHASE6_LABOR_HRS_EST",
    },
    {
      name: "PHASE6_LABOR_HRS_ACT",
      label: "PHASE6_LABOR_HRS_ACT",
    },
    {
      name: "PHASE7_LABOR_HRS_EST",
      label: "PHASE7_LABOR_HRS_EST",
    },
    {
      name: "PHASE7_LABOR_HRS_ACT",
      label: "PHASE7_LABOR_HRS_ACT",
    },
    {
      name: "PHASE8_LABOR_HRS_EST",
      label: "PHASE8_LABOR_HRS_EST",
    },
    {
      name: "PHASE8_LABOR_HRS_ACT",
      label: "PHASE8_LABOR_HRS_ACT",
    },
    {
      name: "PHASE9_LABOR_HRS_EST",
      label: "PHASE9_LABOR_HRS_EST",
    },
    {
      name: "PHASE9_LABOR_HRS_ACT",
      label: "PHASE9_LABOR_HRS_ACT",
    },
    {
      name: "PHASE10_LABOR_HRS_EST",
      label: "PHASE10_LABOR_HRS_EST",
    },
    {
      name: "PHASE10_LABOR_HRS_ACT",
      label: "PHASE10_LABOR_HRS_ACT",
    },
    {
      name: "PHASE11_LABOR_HRS_EST",
      label: "PHASE11_LABOR_HRS_EST",
    },
    {
      name: "PHASE11_LABOR_HRS_ACT",
      label: "PHASE11_LABOR_HRS_ACT",
    },
    {
      name: "PHASE12_LABOR_HRS_EST",
      label: "PHASE12_LABOR_HRS_EST",
    },
    {
      name: "PHASE12_LABOR_HRS_ACT",
      label: "PHASE12_LABOR_HRS_ACT",
    },
    {
      name: "PHASE13_LABOR_HRS_EST",
      label: "PHASE13_LABOR_HRS_EST",
    },
    {
      name: "PHASE13_LABOR_HRS_ACT",
      label: "PHASE13_LABOR_HRS_ACT",
    },
    {
      name: "PHASE14_LABOR_HRS_EST",
      label: "PHASE14_LABOR_HRS_EST",
    },
    {
      name: "PHASE14_LABOR_HRS_ACT",
      label: "PHASE14_LABOR_HRS_ACT",
    },
    {
      name: "PHASE15_LABOR_HRS_EST",
      label: "PHASE15_LABOR_HRS_EST",
    },
    {
      name: "PHASE15_LABOR_HRS_ACT",
      label: "PHASE15_LABOR_HRS_ACT",
    },
    {
      name: "PHASE16_LABOR_HRS_EST",
      label: "PHASE16_LABOR_HRS_EST",
    },
    {
      name: "PHASE16_LABOR_HRS_ACT",
      label: "PHASE16_LABOR_HRS_ACT",
    },
    {
      name: "PHASE17_LABOR_HRS_EST",
      label: "PHASE17_LABOR_HRS_EST",
    },
    {
      name: "PHASE17_LABOR_HRS_ACT",
      label: "PHASE17_LABOR_HRS_ACT",
    },
    {
      name: "PHASE18_LABOR_HRS_EST",
      label: "PHASE18_LABOR_HRS_EST",
    },
    {
      name: "PHASE18_LABOR_HRS_ACT",
      label: "PHASE18_LABOR_HRS_ACT",
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  };

  useEffect(() => {
    if (items !== null) setData([...items]);
  }, [items]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
