const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        "&.MuiButton-contained": {
          "&.MuiButton-containedPrimary": {
            backgroundColor: "#FFA500",
            "&:hover": {
              backgroundColor: "#F59F00",
            },
          },
        },
        "&.MuiButton-outlined": {
          "&.MuiButton-outlinedPrimary": {
            backgroundColor: "transparent",
            color: "#FFA500",
            borderColor: "#FFA500",
            "&:hover": {
              backgroundColor: "#FFA50010",
              color: "#F59F00",
              borderColor: "#F59F00",
            },
          },
        },
      },
    },
  },
};

export default components;
