import { useContext, useState } from "react";
import { format } from "fecha";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";

import AppContext from "../../../context/AppContext";

const useStyles = makeStyles({
  header: {
    backgroundColor: "lightGray",
    padding: 7,
  },
  addBtn: {
    backgroundColor: "orange",
    color: "white",
  },
});

export default function AddUser({ user, submitting = false, handleSubmit }) {
  const { allTools, users } = useContext(AppContext);
  const classes = useStyles();
  const [formData, setFormData] = useState(user);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleAddUser = () => {
    if (
      formData.username === "" ||
      formData.password === "" ||
      formData.confirmPassword === "" ||
      formData.userRole === ""
    ) {
      toast.error("All fields need to be entered!");
      return;
    } else if (
      users.filter((user) => user.USERNAME === formData.username).length === 1
    ) {
      toast.error("Username already taken!");
      return;
    } else if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords aren't equal!");
      return;
    }

    let data = {
      USERNAME: formData.username,
      PASSWORD: formData.password,
      USER_ROLE: formData.userRole,
      IS_ACTIVE: 0,
    };

    handleSubmit(data);
  };

  const handlePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordShow = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={<Typography variant="h6">Create New User</Typography>}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  value={formData.username}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      username: e.target.value,
                    })
                  }
                  label="Username"
                  type="text"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handlePasswordShow} edge="end">
                          {showPassword ? (
                            <VisibilityOffIcon fontSize="small" />
                          ) : (
                            <VisibilityIcon color="primary" fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={formData.confirmPassword}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      confirmPassword: e.target.value,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleConfirmPasswordShow}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffIcon fontSize="small" />
                          ) : (
                            <VisibilityIcon color="primary" fontSize="small" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <RadioGroup
                    value={formData.userRole}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        userRole: e.target.value,
                      })
                    }
                    row
                  >
                    <FormControlLabel
                      value="EMPLOYEE"
                      control={<Radio />}
                      label="Employee"
                    />
                    <FormControlLabel
                      value="FOREMAN"
                      control={<Radio />}
                      label="Foreman"
                    />
                    <FormControlLabel
                      value="TRIM"
                      control={<Radio />}
                      label="Trim"
                    />
                    <FormControlLabel
                      value="ESTIMATOR"
                      control={<Radio />}
                      label="Estimator"
                    />
                    <FormControlLabel
                      value="SUPPLIER"
                      control={<Radio />}
                      label="Supplier"
                    />
                    <FormControlLabel
                      value="ADMIN"
                      control={<Radio />}
                      label="Admin"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={handleAddUser}
              variant="contained"
              className={classes.addBtn}
              disabled={submitting}
            >
              Add User
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
