import React from "react";
import ReactDOM from "react-dom";
import { debugContextDevtool } from "react-context-devtool";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import AppContextProvider from "./context/AppContextProvider";
import createTheme from "./theme";

const container = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <AppContextProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={createTheme()}>
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </AppContextProvider>
  </React.StrictMode>,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Debugging
debugContextDevtool(container);
