import { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/system";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Flip as FlipIcon } from "@mui/icons-material";

const useStyles = makeStyles({
  barcodeScannerContainer: {
    width: "100%",
    height: "100%",
    "& > video": {
      objectFit: "cover",
    },
  },
});

const BarcodeScannerInput = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showScanner, setShowScanner] = useState(false);
  const [barcodeData, setBarcodeData] = useState("");
  const [scanCompleted, setScanCompleted] = useState(false);

  const openBarcodeScanner = () => {
    setShowScanner(true);
  };

  const handleScannerUpdate = (err, result) => {
    if (result) {
      setScanCompleted(true);
      setBarcodeData(result.text);
    } else {
      setScanCompleted(false);
    }
  };

  const handleBarcodeScanConfirm = () => {
    setShowScanner(false);
    props.onChange({ target: { value: barcodeData } });
  };

  const handleDialogClose = () => {
    setShowScanner(false);
  };

  return (
    <>
      <TextField
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={openBarcodeScanner} title="Barcode Scan">
                <FlipIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog
        open={showScanner}
        onClose={handleDialogClose}
        fullScreen={fullScreen}
        aria-labelledby="scanner-dialog"
      >
        <DialogTitle id="scanner-dialog">Barcode Scanner</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="h5" align="center">
            <strong>Barcode: </strong>
            {scanCompleted ? barcodeData : "Not Found"}
          </Typography>
          <div className={classes.barcodeScannerContainer}>
            <BarcodeScannerComponent
              torch="true"
              onUpdate={handleScannerUpdate}
            />
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={handleBarcodeScanConfirm}
            color="primary"
            variant="contained"
            disabled={!scanCompleted}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BarcodeScannerInput;
