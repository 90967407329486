import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import PropTypes from "prop-types";

export default function FuzzySearchSelect({
  label,
  valueLabel,
  values,
  setValues,
  options,
  optionLabel,
  key,
}) {
  let isDisabled = options === null;

  if (isDisabled) {
    return (
      <>
        <Autocomplete
          key={"loading"}
          disabled
          renderInput={(params) => (
            <TextField {...params} label={label} variant="outlined" />
          )}
        />
      </>
    );
  } else {
    return (
      <>
        <Autocomplete
          key={key}
          disabled={isDisabled}
          onChange={(event, newValue) =>
            setValues({ ...values, [valueLabel]: newValue || "" })
          }
          options={options}
          getOptionLabel={(option) =>
            option[optionLabel]
              ? isNaN(option[optionLabel])
                ? option[optionLabel]
                : `${option[optionLabel]}`
              : "Error"
          }
          renderInput={(params) => (
            <TextField {...params} label={label} variant="outlined" />
          )}
        />
      </>
    );
  }
}

FuzzySearchSelect.propTypes = {
  key: PropTypes.any.isRequired,
};
