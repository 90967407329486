import { useContext, useEffect, useState } from "react";
import { format } from "fecha";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarToday as CalendarTodayIcon } from "@mui/icons-material";

import AppContext from "../../../context/AppContext";
import { getAllTools } from "../../../services/ToolService";
import { getUsers } from "../../../services/UserService";

import Autocomplete from "../../../components/Autocomplete";
import BarcodeScannerInput from "../../../components/BarcodeScannerInput/BarcodeScannerInput";
import ImageUploadCard from "../../../components/ImageUploadCard";

const useStyles = makeStyles({
  header: {
    backgroundColor: "lightGray",
    padding: 7,
  },
  addBtn: {
    backgroundColor: "orange",
    color: "white",
  },
});

export default function EditTool({ tool, submitting = false, handleSubmit }) {
  const classes = useStyles();
  const { allTools, users, setAllTools, setUsers } = useContext(AppContext);
  const [formData, setFormData] = useState({ ...tool });
  const [loadingTools, setLoadingTools] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [errors, setErrors] = useState({
    idTag: "",
    tool: "",
    description: "",
    toolStatus: "",
    price: "",
    serviceStatus: "",
    purchaseDate: null,
  });

  const retrieveAllTools = async () => {
    try {
      setLoadingTools(true);
      const newTools = await getAllTools();
      setAllTools(newTools);
      setLoadingTools(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error while loading all tools!");
    }
  };
  const retrieveUsers = async () => {
    try {
      setLoadingUsers(true);
      const newUsers = await getUsers();
      setUsers(newUsers);
      setLoadingUsers(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading users!");
    }
  };

  useEffect(() => {
    retrieveAllTools();
    retrieveUsers();
  }, []);

  useEffect(() => {
    setFormData({ ...tool });
  }, [tool]);

  const formValidate = () => {
    const valid =
      formData.idTag &&
      formData.shortDescription &&
      formData.description &&
      formData.toolStatus &&
      formData.price &&
      formData.serviceStatus &&
      formData.purchaseDate;

    setErrors({
      idTag: formData.idTag ? "" : "ID Tag is required.",
      tool: formData.shortDescription ? "" : "Tool is required.",
      description: formData.description ? "" : "Description is required.",
      toolStatus: formData.toolStatus ? "" : "Employee/shop is required.",
      price: formData.price ? "" : "Price is required.",
      serviceStatus: formData.serviceStatus
        ? ""
        : "Service status is required.",
      purchaseDate: formData.purchaseDate ? "" : "Purchase date is required.",
    });

    return valid;
  };

  const handleToolUpdate = () => {
    if (!formValidate()) return;

    let data = {
      TOOL_ID: formData.toolId,
      ID_TAG: formData.idTag,
      TOOL_SHORT_DESCRIPTION: formData.shortDescription,
      TOOL_DESCRIPTION: formData.description,
      TOOL_STATUS: formData.toolStatus,
      SERVICE_STATUS: formData.serviceStatus,
      PRICE: parseFloat(formData.price),
      PURCHASE_DATE: formData.purchaseDate || null,
      DATE_LAST_CHECKED: formData.dateLastChecked || null,
      DATE_TO_CHECK: formData.dateToCheck || null,
      TOOL_IMAGES: formData.images
        .map((imageData) => ({
          NAME:
            formData.idTag + "_" + new Date().getTime() + "_" + imageData.name,
          FILE_STREAM: imageData.file,
        }))
        .filter((imageData) => imageData.FILE_STREAM !== null),
      CHECKED: formData.checked ? 1 : 0,
    };

    handleSubmit(data);
  };

  const handleIDTagChange = (e) => {
    setErrors({
      ...errors,
      idTag: e.target.value ? "" : "ID Tag is required.",
    });
    setFormData({
      ...formData,
      idTag: e.target.value,
    });
  };

  const handleToolChange = (e, newValue) => {
    setErrors({ ...errors, tool: newValue ? "" : "Tool is required." });
    setFormData({
      ...formData,
      shortDescription: newValue,
    });
  };

  const handleDescriptionChange = (e) => {
    setErrors({
      ...errors,
      description: e.target.value ? "" : "Description is required.",
    });
    setFormData({
      ...formData,
      description: e.target.value,
    });
  };

  const handleToolStatusChange = (e, newValue) => {
    setErrors({
      ...errors,
      toolStatus: newValue ? "" : "Employee/shop is required.",
    });
    setFormData({
      ...formData,
      toolStatus: newValue,
    });
  };

  const handlePriceChange = (e) => {
    setErrors({
      ...errors,
      price: e.target.value ? "" : "Price is required.",
    });
    setFormData({
      ...formData,
      price: e.target.value,
    });
  };

  const handleServiceStatusChange = (e) => {
    setErrors({
      ...errors,
      serviceStatus: e.target.value ? "" : "Service status is required.",
    });
    setFormData({
      ...formData,
      serviceStatus: e.target.value,
    });
  };

  const handlePurchaseDateChange = (value) => {
    setErrors({
      ...errors,
      purchaseDate: value ? "" : "Purchase date is required.",
    });
    setFormData({
      ...formData,
      purchaseDate: value,
    });
  };

  const handleLastCheckedDateChange = (value) => {
    setFormData({
      ...formData,
      dateLastChecked: value,
    });
  };

  const handleNextCheckDateChange = (value) => {
    setFormData({
      ...formData,
      dateToCheck: value,
    });
  };

  const handleCheckChange = (e) => {
    setFormData({
      ...formData,
      checked: e.target.checked,
    });
  };

  const handleImageChanged = (fileData, index) => {
    let newImages = [...formData.images];

    if (index < formData.images.length - 1) {
      if (fileData.file !== null)
        newImages = newImages.map((imageData, imageIndex) =>
          index === imageIndex ? fileData : imageData
        );
      else
        newImages = newImages.filter(
          (imageData, imageIndex) => imageIndex !== index
        );
    } else newImages.splice(newImages.length - 1, 0, fileData);

    setFormData({
      ...formData,
      images: newImages,
    });
  };

  const datePickerRenderInput = (params) => {
    return (
      <TextField
        {...params}
        placeholder="Select a date"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <IconButton>
              <CalendarTodayIcon />
            </IconButton>
          ),
        }}
        fullWidth
      />
    );
  };

  return (
    <Card variant="outlined">
      <CardHeader title={<Typography variant="h6">Edit Tool</Typography>} />
      <Divider />
      <CardContent>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} md={8}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <BarcodeScannerInput
                  label="ID-Tag"
                  type="search"
                  variant="outlined"
                  value={formData.idTag}
                  onChange={handleIDTagChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Tool"
                    variant="outlined"
                    loading={loadingTools}
                    disabled={loadingTools}
                    options={[
                      ...new Set(
                        allTools.map((tool) => tool.TOOL_SHORT_DESCRIPTION) // to handle the duplicated options
                      ),
                    ]}
                    value={formData.shortDescription}
                    onChange={handleToolChange}
                  />
                  {errors.tool && (
                    <FormHelperText error>{errors.tool}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  type="text"
                  variant="outlined"
                  value={formData.description}
                  onChange={handleDescriptionChange}
                  error={!!errors.description}
                  helperText={errors.description}
                  multiline
                  rows={3}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Employee / Shop"
                    variant="outlined"
                    loading={loadingUsers}
                    disabled={loadingUsers}
                    options={[
                      ...new Set(
                        users.map((user) => user.USERNAME) // to handle the duplicated options
                      ),
                    ]}
                    value={formData.toolStatus}
                    onChange={handleToolStatusChange}
                  />
                  {errors.toolStatus && (
                    <FormHelperText error>{errors.toolStatus}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Price"
                  type="number"
                  variant="outlined"
                  value={formData.price || ""}
                  onChange={handlePriceChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  error={!!errors.price}
                  helperText={errors.price}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Service Status</InputLabel>
                  <Select
                    value={formData.serviceStatus || ""}
                    onChange={handleServiceStatusChange}
                    error={!!errors.serviceStatus}
                  >
                    <MenuItem value="" key="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Out" key="Out">
                      Broken
                    </MenuItem>
                    <MenuItem value="In" key="In">
                      Ready To Use
                    </MenuItem>
                  </Select>
                  {errors.serviceStatus && (
                    <FormHelperText error>
                      {errors.serviceStatus}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Purchase Date"
                    value={
                      formData.purchaseDate
                        ? new Date(formData.purchaseDate)
                        : null
                    }
                    onChange={handlePurchaseDateChange}
                    renderInput={datePickerRenderInput}
                  />
                  {errors.purchaseDate && (
                    <FormHelperText error>{errors.purchaseDate}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Date Last Checked"
                  value={
                    formData.dateLastChecked
                      ? new Date(formData.dateLastChecked)
                      : null
                  }
                  onChange={handleLastCheckedDateChange}
                  renderInput={datePickerRenderInput}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Date To Check"
                  value={
                    formData.dateToCheck ? new Date(formData.dateToCheck) : null
                  }
                  onChange={handleNextCheckDateChange}
                  renderInput={datePickerRenderInput}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Checked"
                  control={
                    <Checkbox
                      checked={Boolean(formData.checked)}
                      onChange={handleCheckChange}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container justifyContent="center" spacing={4}>
              {formData.images.map((image, index) => (
                <Grid item key={index} xs={12} md={3}>
                  <ImageUploadCard
                    image={image.file}
                    handleChange={(file) => handleImageChanged(file, index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              className={classes.addBtn}
              onClick={handleToolUpdate}
              disabled={submitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
