import { useContext, useEffect, useState } from "react";
import { format } from "fecha";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { FormControl, Grid } from "@mui/material";

import AppContext from "../../../context/AppContext";

import { getJobSuppliers } from "../../../services/ChangeRequestService";
import {
  getAllJobMaterials,
  postJobMaterials,
} from "../../../services/DocumentService";
import { getProjects } from "../../../services/ProjectService";
import { getProjectJobs } from "../../../services/JobService";

import Autocomplete from "../../../components/Autocomplete";
import JobMaterialsTable from "../../../components/CustomTable/JobMaterialsTable";
import AddJobMaterials from "./AddJobMaterials";

const useStyles = makeStyles({
  root: {},
});

export default function JobMaterials() {
  const classes = useStyles();
  const { projects, jobs, suppliers, setProjects, setJobs, setSuppliers } =
    useContext(AppContext);

  const [jobMaterials, setJobMaterials] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);

  const retrieveProjects = async () => {
    try {
      setLoadingProjects(true);
      const newProjects = await getProjects();
      setProjects(newProjects);
      setLoadingProjects(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading projects!");
    }
  };

  const retrieveJobs = async (proId) => {
    try {
      setLoadingJobs(true);
      const newJobs = await getProjectJobs(proId);
      setJobs(newJobs);
      setLoadingJobs(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading jobs!");
    }
  };

  const retrieveSuppliers = async (jobId) => {
    try {
      setLoadingSuppliers(true);
      const newSuppliers = await getJobSuppliers(jobId);
      setSuppliers(newSuppliers);
      setLoadingSuppliers(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading suppliers");
    }
  };

  const retrieveJobMaterials = async () => {
    try {
      setLoading(true);
      const newJobMaterials = await getAllJobMaterials();

      setJobMaterials([...newJobMaterials]);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading job materials!");
    }
  };

  useEffect(() => {
    retrieveJobMaterials();
    retrieveProjects();
  }, []);

  const handleJobMaterialSave = (data) => {
    let postData = [];
    data.materials.map((item) =>
      postData.push({
        PRO_ID: data.project.PRO_ID,
        JOB_ID: data.job.JOB_ID,
        SUPPLIER_ID: data.supplier.SUPPLIER_ID,
        ITEM_ID: item.ITEM_ID,
        ITEM_DESC: item.ITEM_DESC,
        ITEM_SUPPLIER: item.ITEM_SUPPLIER,
        ITEM_COSTxUNIT: item.ITEM_COSTxUNIT,
        ITEM_UNIT: item.ITEM_UNIT,
        ITEM_DIV: item.ITEM_DIV,
        ITEM_COST: item.ITEM_COST,
        ITEM_SHORT_DESC: item.ITEM_SHORT_DESC,
        ITEM_CATEGORY: item.ITEM_CATEGORY,
        ITEM_ORDER: item.ITEM_ORDER,
        CREATE_DATE: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
      })
    );

    setSubmitting(true);
    const promises = postData.map((item) => postJobMaterials(item));

    Promise.all(promises)
      .then((res) => {
        setSubmitting(false);
        setIsAdding(false);
        retrieveJobMaterials();
        toast.success("Job materials submitted");
      })
      .catch((err) => {
        console.error(err);
        setSubmitting(false);
        toast.error("Job materials not submitted");
      });
  };

  const handleJobMaterialCancel = () => {
    setIsAdding(false);
  };

  const handleJobMaterialUpdate = (rowData) => {};

  const handleJobMaterialAdd = () => {
    setIsAdding(true);
  };

  const handleProjectChange = async (event, newValue) => {
    setSelectedProject(newValue);
    if (newValue) retrieveJobs(newValue?.PRO_ID);
    else setSelectedJob(null);
  };

  const handleJobChange = (event, newValue) => {
    setSelectedJob(newValue);
    if (newValue) retrieveSuppliers(newValue?.JOB_ID);
    else setSelectedSupplier(null);
  };

  const handleSupplierChange = (event, newValue) => {
    setSelectedSupplier(newValue);
  };

  const getJobsFromProject = (proId) => {
    return jobs.filter((job) => proId === "" || job.PRO_ID === proId);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {isAdding && (
          <Grid item xs={12}>
            <AddJobMaterials
              submitting={submitting}
              handleSubmit={handleJobMaterialSave}
              handleCancel={handleJobMaterialCancel}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <FormControl sx={{ minWidth: "240px" }}>
                <Autocomplete
                  label="Project"
                  variant="outlined"
                  size="small"
                  loading={loadingProjects}
                  disabled={loadingProjects}
                  options={projects || []}
                  getOptionLabel={(option) => option.DESCRIPTION}
                  value={selectedProject}
                  onChange={handleProjectChange}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ minWidth: "240px" }}>
                <Autocomplete
                  label="Job"
                  variant="outlined"
                  size="small"
                  loading={loadingJobs}
                  disabled={loadingJobs || !selectedProject}
                  options={
                    selectedProject
                      ? getJobsFromProject(selectedProject.PRO_ID)
                      : []
                  }
                  getOptionLabel={(option) => option.JOB_DESC}
                  value={selectedJob}
                  onChange={handleJobChange}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ minWidth: "240px" }}>
                <Autocomplete
                  label="Supplier"
                  variant="outlined"
                  size="small"
                  loading={loadingSuppliers}
                  disabled={loadingSuppliers || !selectedJob}
                  options={suppliers || []}
                  getOptionLabel={(option) => option.NAME}
                  value={selectedSupplier}
                  onChange={handleSupplierChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <JobMaterialsTable
            title="Job Materials"
            items={jobMaterials.filter(
              (item) =>
                (!selectedProject || item.PRO_ID === selectedProject.PRO_ID) &&
                (!selectedJob || item.JOB_ID === selectedJob.JOB_ID) &&
                (!selectedSupplier ||
                  item.SUPPLIER_ID === selectedSupplier.SUPPLIER_ID)
            )}
            loading={loading}
            handleAdd={handleJobMaterialAdd}
          />
        </Grid>
      </Grid>
    </div>
  );
}
