import { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import AppContext from "../../../context/AppContext";

import AddUser from "./AddUser";
import EditUser from "./EditUser";
import UsersTable from "../../../components/CustomTable/UsersTable";
import {
  addUser,
  editUserInfo,
  editUserStatus,
  getUsers,
} from "../../../services/UserService";

const useStyles = makeStyles({
  root: {},
});

export default function Users() {
  const classes = useStyles();
  const { users, setUsers } = useContext(AppContext);
  const initialUser = {
    username: "",
    password: "",
    confirmPassword: "",
    userRole: "EMPLOYEE",
    isActive: false,
  };
  const [selectedUser, setSelectedUser] = useState(initialUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const retrieveUsers = () => {
    setIsLoading(true);
    getUsers()
      .then((res) => {
        setUsers(res);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs while loading users!");
      });
  };

  useEffect(() => {
    retrieveUsers();
  }, []);

  const handleUserAdd = () => {
    setIsAdding(true);
    setIsEditing(false);
  };
  const handleUserEdit = (rowData) => {
    setIsAdding(false);
    setIsEditing(true);
    setSelectedUser({
      username: rowData.USERNAME,
      password: "",
      confirmPassword: "",
      userRole: rowData.USER_ROLE,
      isActive: rowData.IS_ACTIVE,
    });
  };

  const handleUserCreate = (postData) => {
    setIsSubmitting(true);
    addUser(postData)
      .then(async (res) => {
        if (res.status === 200) {
          setSelectedUser(initialUser);
          setIsSubmitting(false);
          setIsAdding(false);
          toast.success("User Added!");

          retrieveUsers();
        } else {
          setIsSubmitting(false);
          toast.error("User Not Added!");
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast.error("Unexpected error occurs while adding a user!");
      });
  };

  const handleUserUpdate = (postData) => {
    setIsSubmitting(true);
    editUserInfo(postData)
      .then(async (res) => {
        if (res.status === 200) {
          setSelectedUser(initialUser);
          setIsSubmitting(false);
          setIsEditing(false);
          toast.success("User Updated!");

          retrieveUsers();
        } else {
          setIsSubmitting(false);
          toast.error("User Not Updated!");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error("Unexpected error occurs while updating a user!");
      });
  };

  const handleUserDelete = () => {};

  const handleUserActivate = (rowData) => {
    delete rowData["ACTIONS"];
    setIsSubmitting(true);
    editUserStatus(rowData)
      .then(async (res) => {
        if (res.status === 200) {
          setSelectedUser(initialUser);
          setIsSubmitting(false);
          setIsAdding(false);
          setIsEditing(false);
          toast.success("User Updated!");

          retrieveUsers();
        } else {
          setIsSubmitting(false);
          toast.error("User Not Updated!");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error("Unexpected error occurs while updating a user!");
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {isAdding && (
          <Grid item xs={12}>
            <AddUser
              user={initialUser}
              submitting={isSubmitting}
              handleSubmit={handleUserCreate}
            />
          </Grid>
        )}
        {isEditing && (
          <Grid item xs={12}>
            <EditUser
              user={selectedUser}
              submitting={isSubmitting}
              handleSubmit={handleUserUpdate}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <UsersTable
            title="Users"
            loading={isLoading}
            items={users}
            handleAdd={handleUserAdd}
            handleEdit={handleUserEdit}
            handleDelete={handleUserDelete}
            handleActivate={handleUserActivate}
          />
        </Grid>
      </Grid>
    </div>
  );
}
