import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "fecha";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../context/AppContext";
import { getJobUnitMaterials } from "../../services/OrderService";
import {
  deleteCRLabor,
  deleteCRMaterial,
  getCRLabor,
  getCRMaterials,
  getJobSuppliers,
  getLastChangeRequestID,
  postCRLabor,
  postCRMaterial,
  postChangeRequest,
  putCRLabor,
  putCRMaterial,
} from "../../services/ChangeRequestService";

import Autocomplete from "../../components/Autocomplete";
import CRLaborTable from "../../components/CustomTable/CRLaborTable";
import CRMaterialsTable from "../../components/CustomTable/CRMaterialsTable";
import { getProjects } from "../../services/ProjectService";
import { getJobs } from "../../services/JobService";
import { getEstimators } from "../../services/UserService";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
    maxWidth: 1400,
  },
  container: {
    margin: "5rem auto",
  },
});

const RequestTypes = [
  "EWO",
  "Material Overage",
  "Field EPO Short",
  "Backcharge",
  "Service",
  "Time and Half",
  "Theft",
];
const steps = ["Change Request Form", "Labor and Materials"];

export default function ChangeRequest() {
  const classes = useStyles();
  const {
    estimators,
    jobs,
    projects,
    user,
    setEstimators,
    setJobs,
    setProjects,
  } = useContext(AppContext);
  const [activeStep, setActiveStep] = useState(0);
  const [CRLabor, setCRLabor] = useState([]);
  const [CRMaterials, setCRMaterials] = useState([]);
  const [availableSuppliers, setAvailableSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [units, setUnits] = useState([]);
  const [CRFormData, setCRFormData] = useState({
    requestType: "",
    project: null,
    job: null,
    estimator: null,
    comments: "",
  });
  const [CRID, setCRID] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [loadingEstimators, setLoadingEstimators] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);

  const retrieveProjects = async () => {
    try {
      setLoadingProjects(true);
      const newProjects = await getProjects();
      setProjects(newProjects);
      setLoadingProjects(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading projects!");
    }
  };

  const retrieveJobs = async () => {
    try {
      setLoadingJobs(true);
      const newJobs = await getJobs();
      setJobs(newJobs);
      setLoadingJobs(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading jobs!");
    }
  };

  const retrieveEstimators = async () => {
    try {
      setLoadingEstimators(true);
      const newJobs = await getEstimators();
      setEstimators(newJobs);
      setLoadingEstimators(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading estimators!");
    }
  };

  useEffect(() => {
    retrieveProjects();
    retrieveJobs();
    retrieveEstimators();
  }, []);

  useEffect(() => {
    if (!CRMaterials.length) setSelectedSupplier(null);
  }, [CRMaterials]);

  const handleRequestTypeChange = (e) => {
    const value = e.target.value;
    setFormErrors({
      ...formErrors,
      requestType: value ? "" : "Request type is required.",
    });
    setCRFormData({ ...CRFormData, requestType: value });
  };

  const handleProjectChange = (e, newValue) => {
    setFormErrors({
      ...formErrors,
      project: newValue ? "" : "Project is required.",
    });
    setCRFormData({ ...CRFormData, project: newValue });
  };

  const handleJobChange = async (e, newValue) => {
    setFormErrors({
      ...formErrors,
      job: newValue ? "" : "Job is required.",
    });
    setCRFormData({ ...CRFormData, job: newValue });

    if (newValue) {
      try {
        setLoadingUnits(true);
        getJobUnitMaterials(newValue.JOB_ID).then((res) => {
          setLoadingUnits(false);
          const newUnits = [
            ...new Set(res.map((umItem) => `${umItem.UNIT_ID}`)),
          ];

          setUnits(newUnits);
        });
      } catch (e) {
        setLoadingUnits(false);
        console.error(e);
      }
    }
  };

  const handleUnitChange = (e, newValue) => {
    setFormErrors({
      ...formErrors,
      unit: newValue ? "" : "Unit is required.",
    });
    setCRFormData({ ...CRFormData, unit: newValue });
  };

  const handleEstimatorChange = (e, newValue) => {
    setFormErrors({
      ...formErrors,
      estimator: newValue ? "" : "Estimator is required.",
    });
    setCRFormData({ ...CRFormData, estimator: newValue });
  };

  const isValid = () => {
    setFormErrors({
      ...CRFormData,
      requestType: CRFormData.requestType ? "" : "Request type is required.",
      project: CRFormData.project ? "" : "Project is required.",
      job: CRFormData.job ? "" : "Job is required.",
      unit: CRFormData.unit ? "" : "Unit is required.",
      estimator: CRFormData.estimator ? "" : "Estimator is required.",
    });

    if (
      !CRFormData.requestType ||
      !CRFormData.project ||
      !CRFormData.job ||
      !CRFormData.project ||
      !CRFormData.estimator
    )
      return false;
    return true;
  };

  const handleNext = () => {
    if (!isValid()) return;

    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const postData = {
      USERNAME: user.USERNAME,
      PRO_ID: CRFormData.project.PRO_ID,
      JOB_ID: CRFormData.job.JOB_ID,
      UNIT_ID: parseInt(CRFormData.unit),
      ESTIMATOR_USERNAME: CRFormData.estimator.USERNAME,
      REQUEST_TYPE: CRFormData.requestType,
      COMMENTS: CRFormData.comments,
      TIME_REQUESTED: timeRequested,
    };

    setSubmitting(true);

    postChangeRequest(postData)
      .then(async (res) => {
        setSubmitting(false);
        if (res.status === 200) {
          const resCRID = await getLastChangeRequestID();
          if (resCRID[0]?.Column1) {
            setLoadingSuppliers(true);
            const resSuppliers = await getJobSuppliers(postData.JOB_ID);
            setAvailableSuppliers(resSuppliers);
            setLoadingSuppliers(false);
            setCRID(resCRID[0].Column1);
            setActiveStep(activeStep + 1);
          }
        } else {
          toast.error("Change Request submission failed");
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        toast.error(err);
      });
  };

  const handleSubmit = () => {
    if (!CRID) return;

    setCRFormData({
      requestType: "",
      project: null,
      job: null,
      unit: null,
      estimator: null,
      comments: "",
    });
    setCRLabor([]);
    setCRMaterials([]);
    setActiveStep(0);

    toast.success("Change request submitted");
  };

  const handleCRLAdd = (newRowData) => {
    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const laborData = {
      CHANGE_REQUEST_ID: CRID,
      LDESCRIPTION: newRowData.LDESCRIPTION,
      LHOURS: newRowData.LHOURS,
      LCOST: newRowData?.LCOST || 0,
      LTIME_REQUESTED: timeRequested,
    };

    postCRLabor(laborData)
      .then(async (res) => {
        if (res.status === 200) {
          const resCRLabor = await getCRLabor(CRID);

          setCRLabor([...resCRLabor]);
          toast.success("Labor added");
        } else {
          toast.error("Labor not added");
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };
  const handleCRLEdit = (rowData) => {
    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const laborData = {
      ...rowData,
      LDESCRIPTION: rowData.LDESCRIPTION,
      LHOURS: rowData.LHOURS,
      LCOST: rowData?.LCOST || 0,
      LTIME_REQUESTED: timeRequested,
    };

    putCRLabor(laborData)
      .then(async (res) => {
        if (res.status === 200) {
          const resCRLabor = await getCRLabor(CRID);

          setCRLabor([...resCRLabor]);
          toast.success("Labor updated");
        } else {
          toast.error("Labor not updated");
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };
  const handleCRLDelete = (rowData) => {
    deleteCRLabor(rowData.CHANGE_REQUEST_LABOR_ID)
      .then(async (res) => {
        const resCRLabor = await getCRLabor(CRID);

        setCRLabor([...resCRLabor]);
        toast.success("Labor deleted");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };
  const handleCRMAdd = (newRowData) => {
    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const materialData = {
      CHANGE_REQUEST_ID: CRID,
      SUPPLIER_ID: newRowData.SUPPLIER_ID || 99,
      ITEM_ID: newRowData.SUPPLIER_ID ? newRowData.ITEM.ITEM_ID : null,
      DESCRIPTION: newRowData.SUPPLIER_ID
        ? newRowData.ITEM.ITEM_DESC
        : newRowData.DESCRIPTION,
      QUANTITY: parseInt(newRowData.QUANTITY),
      COST: newRowData?.COST || 0,
      TIME_REQUESTED: timeRequested,
    };

    postCRMaterial(materialData)
      .then(async (res) => {
        if (res.status === 200) {
          const resCRMaterials = await getCRMaterials(CRID);

          setCRMaterials([...resCRMaterials]);
          toast.success("Material added");
        } else {
          toast.error("Material not added");
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };
  const handleCRMEdit = (rowData) => {
    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const materialData = {
      ...rowData,
      ITEM_ID: rowData.ITEM_ID,
      DESCRIPTION: rowData.SUPPLIER_ID
        ? rowData.ITEM
          ? rowData.ITEM.ITEM_DESC
          : rowData.DESCRIPTION
        : rowData.DESCRIPTION,
      COST: rowData?.COST || 0,
      SUPPLIER_ID: rowData?.SUPPLIER_ID || 99,
      TIME_REQUESTED: timeRequested,
    };

    putCRMaterial(materialData)
      .then(async (res) => {
        if (res.status === 200) {
          const resCRMaterials = await getCRMaterials(CRID);

          setCRMaterials([...resCRMaterials]);
          toast.success("Material updated");
        } else {
          toast.error("Material not updated");
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };
  const handleCRMDelete = (rowData) => {
    deleteCRMaterial(rowData.CHANGE_REQUEST_MATERIAL_ID)
      .then(async (res) => {
        const resCRMaterials = await getCRMaterials(CRID);

        setCRMaterials([...resCRMaterials]);
        toast.success("Material deleted");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleSupplierChange = (e, newValue) => {
    setSelectedSupplier({ ...newValue });
  };

  const getJobsFromProject = (proId) => {
    return jobs.filter((job) => proId === "" || job.PRO_ID === proId);
  };

  const displayMaterialsTableTitle = () => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h5">Materials</Typography>
        </Grid>
        <Grid item>
          <FormControl sx={{ minWidth: "300px" }}>
            <Autocomplete
              size="small"
              label="Supplier"
              variant="outlined"
              loading={loadingSuppliers}
              disabled={Boolean(loadingSuppliers || CRMaterials.length)}
              options={availableSuppliers || []}
              getOptionLabel={(option) => option.NAME}
              isOptionEqualToValue={(option, value) =>
                option.SUPPLIER_ID === value?.SUPPLIER_ID
              }
              value={selectedSupplier}
              onChange={handleSupplierChange}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container justifyContent="center">
          <Grid item md={10} sm={12} xs={12}>
            <Card>
              <CardHeader
                title={
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Stepper
                        activeStep={
                          activeStep
                        } /* You can customize Stepper props */
                      >
                        {steps.map((item, index) => (
                          <Step key={index}>
                            <StepLabel>
                              <Typography variant="h6">{item}</Typography>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    {activeStep === 0 && (
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Grid container justifyContent="center">
                            <Grid item xs={8}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <FormControl fullWidth>
                                    <InputLabel>Request Type</InputLabel>
                                    <Select
                                      value={CRFormData.requestType}
                                      onChange={handleRequestTypeChange}
                                    >
                                      <MenuItem value="" key="">
                                        <em>None</em>
                                      </MenuItem>
                                      {RequestTypes.map((type, index) => (
                                        <MenuItem key={index} value={type}>
                                          {type}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {formErrors.requestType !== "" && (
                                      <FormHelperText error>
                                        {formErrors.requestType}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Project"
                                      variant="outlined"
                                      loading={loadingProjects}
                                      disabled={loadingProjects}
                                      options={projects || []}
                                      getOptionLabel={(option) =>
                                        option.DESCRIPTION
                                      }
                                      value={CRFormData.project}
                                      onChange={handleProjectChange}
                                    />
                                    {formErrors.project !== "" && (
                                      <FormHelperText error>
                                        {formErrors.project}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Job"
                                      variant="outlined"
                                      loading={loadingJobs}
                                      disabled={Boolean(
                                        loadingJobs || !CRFormData.project
                                      )}
                                      options={
                                        getJobsFromProject(
                                          CRFormData.project?.PRO_ID
                                        ) || []
                                      }
                                      getOptionLabel={(option) =>
                                        option.JOB_DESC
                                      }
                                      value={CRFormData.job}
                                      onChange={handleJobChange}
                                    />
                                    {formErrors.job !== "" && (
                                      <FormHelperText error>
                                        {formErrors.job}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Unit"
                                      variant="outlined"
                                      loading={CRFormData.job && loadingUnits}
                                      disabled={Boolean(!units.length)}
                                      options={units || []}
                                      value={CRFormData.unit}
                                      onChange={handleUnitChange}
                                    />
                                    {formErrors.unit !== "" && (
                                      <FormHelperText error>
                                        {formErrors.unit}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Estimator"
                                      variant="outlined"
                                      loading={loadingEstimators}
                                      options={estimators || []}
                                      disabled={loadingEstimators}
                                      getOptionLabel={(option) =>
                                        option.USERNAME
                                      }
                                      value={CRFormData.estimator}
                                      onChange={handleEstimatorChange}
                                    />
                                    {formErrors.estimator !== "" && (
                                      <FormHelperText error>
                                        {formErrors.estimator}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    label="Comments"
                                    value={CRFormData.comments}
                                    onChange={(e) =>
                                      setCRFormData({
                                        ...CRFormData,
                                        comments: e.target.value,
                                      })
                                    }
                                    minRows={3}
                                    maxRows={5}
                                    multiline
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {activeStep === 1 && (
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <CRLaborTable
                            title="Labor"
                            items={CRLabor}
                            hiddenColumns={["LCOST", "LINE_TOTAL"]}
                            handleAdd={handleCRLAdd}
                            handleEdit={handleCRLEdit}
                            handleDelete={handleCRLDelete}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <CRMaterialsTable
                            title={displayMaterialsTableTitle()}
                            job={CRFormData.job}
                            supplier={selectedSupplier}
                            items={CRMaterials}
                            hiddenColumns={["ITEM_ID", "COST", "LINE_TOTAL"]}
                            handleAdd={handleCRMAdd}
                            handleEdit={handleCRMEdit}
                            handleDelete={handleCRMDelete}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    {activeStep === 0 && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleNext}
                        disabled={Boolean(submitting)}
                      >
                        Next
                      </Button>
                    )}
                    {activeStep === 1 && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={Boolean(submitting)}
                      >
                        Submit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
