import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../../context/AppContext";

const useStyles = makeStyles({
  header: {
    backgroundColor: "lightGray",
    padding: 7,
  },
  addBtn: {
    backgroundColor: "orange",
    color: "white",
  },
});

export default function EditToolOrder({
  toolOrder,
  submitting = false,
  handleSubmit,
}) {
  const { allTools, jobs, loadingAll, projects, toolOrders, users } =
    useContext(AppContext);
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...toolOrder });
  const [availableTools, setAvailableTools] = useState(allTools);

  useEffect(() => {
    const _toolOrders = toolOrders.map((toolOrder) => toolOrder.ID_TAG);
    const _tools = allTools.filter(
      (tool) => !_toolOrders.includes(tool.ID_TAG)
    );

    setAvailableTools(_tools);
  }, [allTools, toolOrders]);

  useEffect(() => {
    setFormData({ ...toolOrder });
  }, [toolOrder]);

  const getSelectedEmployeeTools = (username) => {
    return availableTools.filter((item) => item.TOOL_STATUS === username);
  };

  const getJobsFromProject = (proId) => {
    return jobs.filter((job) => proId === "" || job.PRO_ID === proId);
  };

  const handleFormSubmit = () => {
    if (
      formData.toolStatus === "" ||
      formData.toolStatus === null ||
      formData.toolShortDescription === "" ||
      formData.toolShortDescription === null ||
      formData.toolDescription === "" ||
      formData.toolDescription === null ||
      formData.project === "" ||
      formData.project === null ||
      formData.job === "" ||
      formData.job === null
    ) {
      toast.error("All fields need to be entered!");
      return;
    }
    handleSubmit(formData);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={<Typography variant="h6">Edit Tool Order</Typography>}
      />
      <Divider />
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <RadioGroup
                  value={formData.toolStatusType}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      toolStatusType: e.target.value,
                    })
                  }
                  row
                >
                  <FormControlLabel
                    value="shop"
                    control={<Radio />}
                    label="Shop"
                  />
                  <FormControlLabel
                    value="employee"
                    control={<Radio />}
                    label="Employee"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={6}>
                {formData.toolStatusType === "employee" && (
                  <Autocomplete
                    value={formData.toolStatus}
                    options={[
                      ...new Set(
                        users.map((user) => user.USERNAME) // to handle the duplicated options
                      ),
                    ]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee"
                        variant="outlined"
                      />
                    )}
                    onChange={(e, newValue) =>
                      setFormData({
                        ...formData,
                        toolStatus: newValue,
                      })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={formData.toolShortDescription || "undefined"}
                  options={
                    availableTools
                      ? [
                          ...new Set(
                            getSelectedEmployeeTools(formData.toolStatus).map(
                              (tool) => tool.TOOL_SHORT_DESCRIPTION
                            ) // to handle the duplicated options
                          ),
                        ]
                      : []
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Tool" variant="outlined" />
                  )}
                  onChange={(e, newValue) =>
                    setFormData({
                      ...formData,
                      toolShortDescription: newValue,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={formData.tool}
                  options={getSelectedEmployeeTools(formData.toolStatus).filter(
                    (tool) =>
                      tool.TOOL_SHORT_DESCRIPTION ===
                        formData.toolShortDescription ||
                      formData.toolShortDescription === ""
                  )}
                  getOptionLabel={(option) => option.TOOL_DESCRIPTION}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Description"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, newValue) =>
                    setFormData({
                      ...formData,
                      idTag: newValue ? newValue.ID_TAG : "",
                      toolDescription: newValue
                        ? newValue.TOOL_DESCRIPTION
                        : "",
                      tool: newValue,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={formData.project}
                  options={projects || []}
                  getOptionLabel={(option) => option.DESCRIPTION}
                  renderInput={(params) => (
                    <TextField {...params} label="Project" variant="outlined" />
                  )}
                  onChange={(e, newValue) =>
                    setFormData({
                      ...formData,
                      proId: newValue ? newValue.PRO_ID : "",
                      project: newValue,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={formData.job}
                  options={getJobsFromProject(formData.proId) || []}
                  getOptionLabel={(option) => option.JOB_DESC}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Job Site"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, newValue) =>
                    setFormData({
                      ...formData,
                      jobId: newValue ? newValue.JOB_ID : "",
                      job: newValue,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={handleFormSubmit}
              variant="contained"
              className={classes.addBtn}
              disabled={submitting || loadingAll}
            >
              Edit Tool Order
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
