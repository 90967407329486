import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import CustomAccordion from "../../components/CustomAccordion";
import { info } from "./helpInfo";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
    maxWidth: 1400,
  },
  container: {
    margin: "5rem auto",
  },
});

export default function Help() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Card variant="outlined">
          <CardHeader title={<Typography variant="h5">Help</Typography>} />
          <Divider />
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                {info &&
                  info.map(({ title, info }, index) => (
                    <CustomAccordion
                      key={index}
                      summary={title}
                      details={info}
                    />
                  ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
