import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "fecha";
import { makeStyles } from "@mui/styles";
import { Button, Card, CardContent, FormControl, Grid } from "@mui/material";

import AppContext from "../../../context/AppContext";
import { getJobs } from "../../../services/JobService";
import { getJobOrders, putOrder } from "../../../services/OrderService";
import { getProjects } from "../../../services/ProjectService";
import { getSuppliers } from "../../../services/SupplierService";

import Autocomplete from "../../../components/Autocomplete";
import OrderSummaryTable from "../../../components/CustomTable/OrderSummaryTable";
import SetOrderDeliveryDateModal from "../../../components/Modal/SetOrderDeliveryDateModal";

const useStyles = makeStyles({
  root: {},
});

const OrderSummary = () => {
  const classes = useStyles();
  const { jobs, projects, user, setJobs, setProjects, setSuppliers } =
    useContext(AppContext);
  const [deliveryDateFormData, setDeliveryDateFormData] = useState({
    orders: [],
    deliveryDate: null,
  });
  const [loadingJobOrders, setLoadingJobOrders] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [summaryData, setSummaryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const retrieveSuppliers = async () => {
      try {
        setLoadingSuppliers(true);
        const resSuppliers = await getSuppliers();
        setSuppliers(resSuppliers);
        setLoadingSuppliers(false);
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading suppliers!");
      }
    };
    const retrieveProjects = async () => {
      try {
        if (isMounted) setLoadingProjects(true);
        const newProjects = await getProjects();
        if (isMounted) {
          setProjects(newProjects);
          setLoadingProjects(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading projects!");
      }
    };

    const retrieveJobs = async () => {
      try {
        if (isMounted) setLoadingJobs(true);
        const newJobs = await getJobs();
        if (isMounted) {
          setJobs(newJobs);
          setLoadingJobs(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading jobs!");
      }
    };
    retrieveSuppliers();
    retrieveProjects();
    retrieveJobs();

    return () => {
      isMounted = false;
    };
  }, []);

  const retrieveJobOrders = async (job) => {
    if (!job) {
      setSummaryData([]);
      return;
    }

    try {
      setLoadingJobOrders(true);
      const res = await getJobOrders(job.JOB_ID);
      setSummaryData(res);
      setLoadingJobOrders(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleProjectChange = (e, newValue) => {
    setSelectedProject(newValue);
  };

  const handleJobChange = (e, newValue) => {
    setSelectedJob(newValue);

    retrieveJobOrders(newValue);
  };

  const handleDeliveryDateSet = (formData) => {
    const promises = formData.orders.map((item) =>
      putOrder({
        ...item,
        PO_CREATE_DATE: format(
          new Date(formData.deliveryDate),
          "YYYY-MM-DD hh:mm:ss"
        ),
        ORDER_TIME: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
      })
    );

    Promise.all(promises)
      .then(async (results) => {
        setDeliveryDateFormData({ orders: [], deliveryDate: null });
        await retrieveJobOrders(selectedJob);
        toast.success("Delivery date updated.");
      })
      .catch((err) => {
        // Handle API call error
        console.error(err);
        toast.error(err);
      });
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const getJobsFromProject = (proId) => {
    return jobs.filter((job) => proId === "" || job.PRO_ID === proId);
  };

  const handleSubmit = (payload) => {};

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={4}
          >
            <Grid item>
              <Button
                variant="contained"
                onClick={handleModalOpen}
                disabled={!summaryData.length}
              >
                Set Delivery Date
              </Button>
            </Grid>
            <Grid item>
              <Grid container spacing={4}>
                <Grid item>
                  <FormControl sx={{ minWidth: 240 }}>
                    <Autocomplete
                      label="Project"
                      variant="outlined"
                      size="small"
                      loading={loadingProjects}
                      disabled={loadingProjects}
                      options={projects || []}
                      getOptionLabel={(option) => option.DESCRIPTION}
                      value={selectedProject}
                      onChange={handleProjectChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl sx={{ minWidth: 240 }}>
                    <Autocomplete
                      label="Job"
                      variant="outlined"
                      size="small"
                      loading={loadingJobs}
                      disabled={loadingJobs || !selectedProject}
                      options={
                        getJobsFromProject(selectedProject?.PRO_ID) || []
                      }
                      getOptionLabel={(option) => option.JOB_DESC}
                      value={selectedJob}
                      onChange={handleJobChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  {selectedJob ? (
                    <Link
                      className={classes.navLink}
                      to={`/ordersummary/preview?jid=${selectedJob.JOB_ID}`}
                      target="_blank"
                    >
                      <Button variant="contained" fullWidth>
                        Preivew
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      variant="outlined"
                      disabled={!selectedJob}
                      fullWidth
                    >
                      Preivew
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SetOrderDeliveryDateModal
            open={isModalOpen}
            loading={loadingJobOrders}
            orders={summaryData}
            formData={deliveryDateFormData}
            handleSave={handleDeliveryDateSet}
            handleClose={handleModalClose}
          />
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <OrderSummaryTable
                    title="Order Summary"
                    loading={loadingJobOrders}
                    items={summaryData}
                    handleSubmit={handleSubmit}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderSummary;
