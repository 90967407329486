import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { format } from "fecha";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../context/AppContext";
import { getJobs } from "../../services/JobService";
import {
  getAllOrders,
  getForemanOrders,
  getOrders,
  getJobUnitMaterials,
  postOrderUnitMaterials,
  getOrderUnitMaterials,
} from "../../services/OrderService";
import { getProjects } from "../../services/ProjectService";
import { getForemen } from "../../services/UserService";

import Autocomplete from "../../components/Autocomplete";
import UnitMaterialsTable from "../../components/CustomTable/UnitMaterialsTable";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
    maxWidth: 1400,
  },
  container: {
    margin: "5rem auto",
  },
});

const steps = ["Order Form", "Unit Materials"];

export default function Orders() {
  const classes = useStyles();
  const {
    foremen,
    jobs,
    orders,
    projects,
    user,
    setJobs,
    setOrders,
    setProjects,
    setForemen,
  } = useContext(AppContext);
  const [unitMaterials, setUnitMaterials] = useState([]);
  const [availableUnitMaterials, setAvailableUnitMaterials] = useState([]);
  const [orderedUnitMaterials, setOrderedUnitMaterials] = useState([]);
  const [units, setUnits] = useState([]);
  const [phases, setPhases] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [orderID, setOrderID] = useState(null);
  const [orderFormData, setOrderFormData] = useState({
    project: null,
    job: null,
    unit: null,
    phase: [],
    foreman: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingUnits, setLoadingUnits] = useState(false);
  const [loadingForemen, setLoadingForemen] = useState(false);

  const retrieveOrders = async () => {
    try {
      setLoadingOrders(true);
      const resOrders =
        user.USER_ROLE === "ADMIN"
          ? await getAllOrders()
          : user.USER_ROLE === "FOREMAN"
          ? await getForemanOrders(user)
          : await getOrders(user);

      setOrders(resOrders);
      setLoadingOrders(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading orders!");
    }
  };

  useEffect(() => {
    if (!user) return;

    let mounted = true;

    const retrieveProjects = async () => {
      try {
        if (mounted) setLoadingProjects(true);
        const newProjects = await getProjects();
        if (mounted) {
          setProjects(newProjects);
          setLoadingProjects(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading projects!");
      }
    };

    const retrieveJobs = async () => {
      try {
        if (mounted) setLoadingJobs(true);
        const newJobs = await getJobs();
        if (mounted) {
          setJobs(newJobs);
          setLoadingJobs(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading jobs!");
      }
    };

    const retrieveForemen = async () => {
      try {
        if (mounted) setLoadingForemen(true);
        const newJobs = await getForemen();
        if (mounted) {
          setForemen(newJobs);
          setLoadingForemen(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading foremen!");
      }
    };

    retrieveOrders();
    retrieveProjects();
    retrieveJobs();
    retrieveForemen();

    return () => {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    const newAvailableUnitMaterials = unitMaterials
      .filter((item) => {
        const unitMatches =
          item.UNIT_ID === parseInt(orderFormData.unit) || !orderFormData.unit;
        const phaseMatches =
          orderFormData.phase.includes(String(item.PHASE_ID)) ||
          !orderFormData.phase.length;

        return unitMatches && phaseMatches;
      })
      .filter(
        (item) =>
          !orderedUnitMaterials.map((mat) => mat.ITEM_ID).includes(item.ITEM_ID)
      );

    setAvailableUnitMaterials(newAvailableUnitMaterials);
  }, [orderFormData, orderedUnitMaterials, unitMaterials]);

  const handleProjectChange = (e, newValue) => {
    setFormErrors({
      ...formErrors,
      project: newValue ? "" : "Project is required.",
    });
    setOrderFormData({ ...orderFormData, project: newValue });
  };

  const handleJobChange = async (e, newValue) => {
    setFormErrors({
      ...formErrors,
      job: newValue ? "" : "Job is required.",
    });
    setOrderFormData({ ...orderFormData, job: newValue });

    if (newValue) {
      try {
        setLoadingUnits(true);
        getJobUnitMaterials(newValue.JOB_ID).then((res) => {
          setLoadingUnits(false);
          const newUnits = [
            ...new Set(res.map((umItem) => `${umItem.UNIT_ID}`)),
          ];

          setUnitMaterials(res);
          setUnits(newUnits);
        });
      } catch (e) {
        setLoadingUnits(false);
        console.error(e);
      }
    }
  };

  const handleUnitChange = async (e, newValue) => {
    setFormErrors({
      ...formErrors,
      unit: newValue ? "" : "Unit is required.",
    });
    setOrderFormData({ ...orderFormData, unit: newValue });

    if (newValue) {
      const orderedMaterials = await getOrderUnitMaterials(
        orderFormData.job.JOB_ID,
        newValue
      );
      const newUnitMaterials = unitMaterials.filter(
        (item) => parseInt(item.UNIT_ID) === parseInt(newValue)
      );
      const newPhases = [
        ...new Set(newUnitMaterials.map((item) => `${item.PHASE_ID}`)),
      ];

      const availablePhases = newPhases.filter((item) => {
        const cnt1 = newUnitMaterials.filter(
          (mat) => mat.PHASE_ID === parseInt(item)
        ).length;
        const cnt2 = orderedMaterials.filter(
          (mat) => mat.PHASE_ID === parseInt(item)
        ).length;
        const cnt3 = newUnitMaterials
          .filter((mat) => mat.PHASE_ID === parseInt(item))
          .filter(
            (mat) =>
              !orderedMaterials
                .map((umat) => umat.ITEM_ID)
                .includes(mat.ITEM_ID)
          ).length;

        return cnt1 !== cnt2 && cnt3;
      });

      setPhases([...availablePhases]);
      setOrderedUnitMaterials([...orderedMaterials]);
    }
  };

  const handlePhaseChange = (e, newValue) => {
    setFormErrors({
      ...formErrors,
      phase: newValue ? "" : "Phase is required.",
    });
    setOrderFormData({ ...orderFormData, phase: newValue });
  };

  const handleForemanChange = (e, newValue) => {
    setFormErrors({
      ...formErrors,
      foreman: newValue ? "" : "Foreman is required.",
    });
    setOrderFormData({ ...orderFormData, foreman: newValue });
  };

  const isValid = () => {
    setFormErrors({
      ...orderFormData,
      project: orderFormData.project ? "" : "Project is required.",
      job: orderFormData.job ? "" : "Job is required.",
      unit: orderFormData.unit ? "" : "Unit is required.",
      phase: orderFormData.phase ? "" : "Phase is required.",
      foreman: orderFormData.foreman ? "" : "Foreman is required.",
    });

    if (
      !orderFormData.project ||
      !orderFormData.job ||
      !orderFormData.unit ||
      !orderFormData.phase ||
      !orderFormData.foreman
    )
      return false;
    return true;
  };

  const handleUnitMaterialDelete = (rowData) => {
    const newAvailableUnitMaterials = availableUnitMaterials.filter(
      (item) => item.MATERIAL_INDEX !== rowData.MATERIAL_INDEX
    );

    setAvailableUnitMaterials(newAvailableUnitMaterials);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    if (!isValid()) return;
    setActiveStep(activeStep + 1);
  };

  const handleSubmit = () => {
    const timeOrdered = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    let postUnitMaterialsData = [];

    availableUnitMaterials.forEach((item) => {
      postUnitMaterialsData.push({
        MATERIAL_INDEX: item.MATERIAL_INDEX,
        ITEM_QTY: item.ITEM_QTY,
        ITEM_COST: item.ITEM_COST,
        ITEM_ID: item.ITEM_ID,
        ITEM_DESC: item.ITEM_DESC,
        PHASE_ID: item.PHASE_ID,
        ORDER_TIME: timeOrdered,
        SUPPLIER_ID: item.SUPPLIER_ID,
      });
    });

    const postData = {
      USERNAME: user.USERNAME,
      PRO_ID: orderFormData.project.PRO_ID,
      JOB_ID: orderFormData.job.JOB_ID,
      UNIT_ID: orderFormData.unit,
      PHASE_ID: orderFormData.phase.join(","),
      FOREMAN: orderFormData.foreman.USERNAME,
      ORDER_TIME: timeOrdered,
      ORDER_ITEMS: postUnitMaterialsData,
    };

    setSubmitting(true);

    postOrderUnitMaterials(postData)
      .then((res) => {
        setSubmitting(false);
        setOrderFormData({
          project: null,
          job: null,
          unit: null,
          phase: [],
          foreman: null,
        });
        setUnitMaterials([]);
        setActiveStep(0);
        retrieveOrders();
        toast.success("Order submitted");
      })
      .catch((err) => {
        console.error(err);
        setSubmitting(false);
        toast.error("Order submission failed");
      });
  };

  const getJobsFromProject = (proId) => {
    return jobs.filter((job) => proId === "" || job.PRO_ID === proId);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Stepper
                        activeStep={
                          activeStep
                        } /* You can customize Stepper props */
                      >
                        {steps.map((item, index) => (
                          <Step key={index}>
                            <StepLabel>
                              <Typography variant="h6">{item}</Typography>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                  </Grid>
                }
              />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    {activeStep === 0 && (
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Grid container justifyContent="center">
                            <Grid item xs={8}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Project"
                                      variant="outlined"
                                      loading={loadingProjects}
                                      disabled={loadingProjects}
                                      options={projects || []}
                                      getOptionLabel={(option) =>
                                        option.DESCRIPTION
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.PRO_ID === value.PRO_ID
                                      }
                                      value={orderFormData.project}
                                      onChange={handleProjectChange}
                                    />
                                    {formErrors.project !== "" && (
                                      <FormHelperText error>
                                        {formErrors.project}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Job"
                                      variant="outlined"
                                      loading={loadingJobs}
                                      disabled={
                                        loadingJobs || !orderFormData.project
                                      }
                                      options={
                                        getJobsFromProject(
                                          orderFormData.project?.PRO_ID
                                        ) || []
                                      }
                                      isOptionEqualToValue={(option, value) =>
                                        option.JOB_ID === value.JOB_ID
                                      }
                                      getOptionLabel={(option) =>
                                        option.JOB_DESC
                                      }
                                      value={orderFormData.job}
                                      onChange={handleJobChange}
                                    />
                                    {formErrors.job !== "" && (
                                      <FormHelperText error>
                                        {formErrors.job}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Unit"
                                      variant="outlined"
                                      loading={
                                        orderFormData.job && loadingUnits
                                      }
                                      disabled={Boolean(!units.length)}
                                      options={units || []}
                                      value={orderFormData.unit}
                                      onChange={handleUnitChange}
                                    />
                                    {formErrors.unit !== "" && (
                                      <FormHelperText error>
                                        {formErrors.unit}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Phase"
                                      variant="outlined"
                                      loading={
                                        orderFormData.job && loadingUnits
                                      }
                                      disabled={Boolean(!phases.length)}
                                      options={phases || []}
                                      value={orderFormData.phase}
                                      onChange={handlePhaseChange}
                                      multiple
                                    />
                                    {formErrors.phase !== "" && (
                                      <FormHelperText error>
                                        {formErrors.phase}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      label="Foreman"
                                      variant="outlined"
                                      loading={loadingForemen}
                                      options={foremen || []}
                                      disabled={loadingForemen}
                                      isOptionEqualToValue={(option, value) =>
                                        option.USERNAME === value.USERNAME
                                      }
                                      getOptionLabel={(option) =>
                                        option.USERNAME
                                      }
                                      value={orderFormData.foreman}
                                      onChange={handleForemanChange}
                                    />
                                    {formErrors.foreman !== "" && (
                                      <FormHelperText error>
                                        {formErrors.foreman}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {activeStep === 1 && (
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              <TextField
                                label="Project"
                                value={orderFormData.project?.DESCRIPTION || ""}
                                fullWidth
                                disabled
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                label="Job"
                                value={orderFormData.job?.JOB_DESC || ""}
                                fullWidth
                                disabled
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                label="Unit"
                                value={orderFormData.unit || ""}
                                fullWidth
                                disabled
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                label="Phase"
                                value={orderFormData.phase || ""}
                                fullWidth
                                disabled
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <UnitMaterialsTable
                            title="Unit Materials"
                            items={availableUnitMaterials}
                            hiddenColumns={[
                              "DESCRIPTION",
                              "JOB_DESC",
                              "UNIT_ID",
                              "PHASE_ID",
                              "ITEM_COST",
                              "LINE_TOTAL",
                            ]}
                            job={orderFormData.job}
                            handleDelete={handleUnitMaterialDelete}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleBack}
                      disabled={!Boolean(activeStep)}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item>
                    {activeStep !== steps.length - 1 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleNext}
                        disabled={submitting}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={submitting}
                      >
                        Submit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
