import React from "react";
import { Grid, Typography } from "@mui/material";

const LabelValuePairDisplay = ({ label, value }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Typography align="right" fontWeight="bold" variant="body2">
          {label}:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography align="left" variant="body2">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LabelValuePairDisplay;
