import { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Checkbox,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const useStyles = makeStyles({
  root: {},
});

export default function DrawerCheckboxes({
  buttonText,
  label,
  values,
  setValues,
  options,
  position = "left",
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (toggle) => {
    setOpen(toggle);
  };

  const handleClick = (value) => {
    if (value === "all") {
      if (values[label].length === options.length) {
        setValues({
          ...values,
          [label]: [],
        });
      } else {
        setValues({
          ...values,
          [label]: [...options],
        });
      }
      return;
    }

    const currentIndex = values[label].indexOf(value);
    const newChecked = [...values[label]];

    if (currentIndex === -1) {
      newChecked.push(value);
      newChecked.sort((a, b) => options.indexOf(a) - options.indexOf(b));
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setValues({
      ...values,
      [label]: newChecked,
    });
  };

  return (
    <>
      <Button variant="outlined" onClick={() => toggleDrawer(true)}>
        {buttonText}
      </Button>
      <Drawer anchor={position} open={open} onClose={() => toggleDrawer(false)}>
        <div>
          {options && (
            <List>
              <ListItem
                key="all"
                role={undefined}
                dense
                button
                onClick={() => handleClick("all")}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={values[label].length === options.length}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText
                    id={`checkbox-list-label-all}`}
                    primary={"All Items"}
                  />
                </ListItemIcon>
              </ListItem>
              {options.map((option) => (
                <ListItem
                  key={option}
                  role={undefined}
                  dense
                  button
                  onClick={() => handleClick(option)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={values[label].indexOf(option) > -1}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText
                      id={`checkbox-list-label-${option}`}
                      primary={option}
                    />
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </Drawer>
    </>
  );
}
