import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { format } from "fecha";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import {
  Build as BuildIcon,
  Message as MessageIcon,
  PostAdd as PostAddIcon,
  Work as WorkIcon,
} from "@mui/icons-material";

import AppContext from "../../context/AppContext";
import { getMessages, putMessage } from "../../services/MessageService";
import {
  getAllChangeRequests,
  getChangeRequests,
  getReviewerChangeRequests,
} from "../../services/ChangeRequestService";
import {
  getAllOrders,
  getForemanOrders,
  getOrders,
} from "../../services/OrderService";

import EmployeeToolOrders from "../Tools/EmployeeToolOrders";
import MessagesTable from "../../components/CustomTable/MessagesTable";
import CRTable from "../../components/CustomTable/CRTable";
import OrdersTable from "../../components/CustomTable/OrdersTable";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "5rem auto",
  },
});

const Dashboard = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    changeRequests,
    orders,
    user,
    setChangeRequests,
    setMessages,
    setOrders,
  } = useContext(AppContext);
  const [userMessages, setUserMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingChangeRequests, setLoadingChangeRequests] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);

  if (user && user.USER_ROLE === "SUPPLIER") history.push("/documents");

  const retrieveChangeRequests = async () => {
    try {
      setLoadingChangeRequests(true);
      const crs =
        user.USER_ROLE === "ADMIN"
          ? await getAllChangeRequests()
          : user.USER_ROLE === "ESTIMATOR"
          ? await getReviewerChangeRequests(user)
          : await getChangeRequests(user);

      crs.sort((a, b) => b.CHANGE_REQUEST_ID - a.CHANGE_REQUEST_ID);

      setChangeRequests([...crs]);
      setLoadingChangeRequests(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpect error occurs while loading change requests!");
    }
  };

  const retrieveOrders = async () => {
    try {
      setLoadingOrders(true);
      const resOrders =
        user.USER_ROLE === "ADMIN"
          ? await getAllOrders()
          : user.USER_ROLE === "FOREMAN"
          ? await getForemanOrders(user)
          : await getOrders(user);

      setOrders(resOrders);
      setLoadingOrders(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading orders!");
    }
  };

  const retrieveMessages = async () => {
    try {
      setLoadingMessages(true);
      const newMessages = await getMessages(user);

      setUserMessages([...newMessages]);
      setMessages([...newMessages]);
      setLoadingMessages(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading messages!");
    }
  };

  useEffect(() => {
    if (user) {
      retrieveChangeRequests();
      retrieveMessages();
      retrieveOrders();
    }
  }, [user]);

  const handleMessageDelete = (rowData) => {
    const payload = {
      ...rowData,
      ACTION: "DELETED",
      RECEIVED_DATE: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
    };

    putMessage(payload)
      .then(async (res) => {
        if (res.status === 200) {
          await retrieveMessages(user);
          toast.success("Message deleted");
        } else {
          toast.error("Message not deleted!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unexpected error occurs!");
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <h1 className={classes.title}>
            <MessageIcon /> Messages
          </h1>
          <MessagesTable
            title="Messages"
            loading={loadingMessages}
            items={userMessages}
            handleDelete={handleMessageDelete}
          />
        </Grid>
        <Grid item xs={12}>
          <h1 className={classes.title}>
            <WorkIcon /> Change Request Submissions
          </h1>
          <CRTable
            title="Change Requests"
            items={changeRequests}
            loading={loadingChangeRequests}
          />
        </Grid>
        <Grid item xs={12}>
          <h1 className={classes.title}>
            <PostAddIcon /> Order Submissions
          </h1>
          <OrdersTable title="Orders" items={orders} loading={loadingOrders} />
        </Grid>
        <Grid item xs={12}>
          <h1 className={classes.title}>
            <BuildIcon /> Tool Orders
          </h1>
          <EmployeeToolOrders />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
