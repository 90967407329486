import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Admin from "./containers/Admin";
import ChangeRequest from "./containers/ChangeRequest";
import ContactUs from "./containers/ContactUs";
import Dashboard from "./containers/Dashboard";
import Documents from "./containers/Documents";
import Header from "./containers/Header";
import Help from "./containers/Help";
import Inventory from "./containers/Inventory";
import Login from "./containers/Login";
import Orders from "./containers/Orders";
import OrderPreview from "./containers/Admin/Orders/OrderPreview";
import OrderSummaryPreview from "./containers/Admin/Orders/OrderSummaryPreview";
import POPreview from "./containers/POPreview";
import Receipts from "./containers/Receipts/index";
import Reports from "./containers/Reports";
import Tools from "./containers/Tools";

import AuthenticatedRoute from "./HoC/AuthenticatedRoute";
import Error404 from "./containers/Error404/Error404";

import SendMessage from "./components/SendMessage";
import AppContext from "./context/AppContext";

export default function AppRoutes() {
  return (
    <Router>
      <Switch>
        <AuthenticatedRoute exact path="/" component={MainLayout(Dashboard)} />
        <AuthenticatedRoute path="/admin" component={MainLayout(Admin)} />
        <AuthenticatedRoute
          path="/dashboard"
          component={MainLayout(Dashboard)}
        />
        <AuthenticatedRoute
          path="/changeRequest"
          component={MainLayout(ChangeRequest)}
        />
        <AuthenticatedRoute path="/orders" component={MainLayout(Orders)} />
        <AuthenticatedRoute path="/tools" component={MainLayout(Tools)} />
        <AuthenticatedRoute path="/reports" component={MainLayout(Reports)} />
        <AuthenticatedRoute path="/receipts" component={MainLayout(Receipts)} />
        <AuthenticatedRoute path="/help" component={MainLayout(Help)} />
        <AuthenticatedRoute
          path="/contactus"
          component={MainLayout(ContactUs)}
        />
        <AuthenticatedRoute
          path="/documents"
          component={MainLayout(Documents)}
        />

        <Route path="/login" component={MainLayout(Login)} />
        <Route path="/po/preview" component={POPreview} />
        <Route path="/order/preview" component={OrderPreview} />
        <Route path="/ordersummary/preview" component={OrderSummaryPreview} />
        <Route path="/404" component={MainLayout(Error404)} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  );
}

// Header component is defined outside AppRoutes
function MainLayout(Component) {
  return function (props) {
    return (
      <div style={{ position: "relative", minHeight: "100vh" }}>
        <Header />
        <Component {...props} />
        <SendMessage />
      </div>
    );
  };
}
