import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../context/AppContext";
import { postUser } from "../../services/UserService";

const useStyles = makeStyles({
  root: {},
});

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const { setUser, setUserAuthenticated } = useContext(AppContext);
  const [userInfo, setUserInfo] = useState({ username: "", password: "" });
  const [formErrors, setFormErrors] = useState({
    username: false,
    password: false,
  });
  const [submitting, setSubmitting] = useState(false);

  const handleUserInfoChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    setFormErrors({
      ...formErrors,
      [e.target.name]: !e.target.value ? "This field is required." : "",
    });
  };

  const handleSignIn = (event) => {
    event.preventDefault();

    setFormErrors({
      username: !userInfo.username ? "This field is required." : "",
      password: !userInfo.password ? "This field is required" : "",
    });

    if (!userInfo.username || !userInfo.password) return;

    setSubmitting(true);

    postUser(userInfo)
      .then((res) => res.json())
      .then((res) => {
        setSubmitting(false);
        if (res.length === 1) {
          setUser(res[0]); // TODO Only send the body I think (basically the user object)
          setUserAuthenticated(true);
          if (res[0].USER_ROLE === "SUPPLIER") history.push("/documents");
          else history.push("/");
        } else {
          toast.error("User doesn't exist. Please try again!");
        }
      })
      .catch((err) => {
        // TODO Specify error type FFR
        setSubmitting(false);
        console.log(err);
        toast.error("Login failed");
      });
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          margin: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Card>
          <CardContent>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSignIn}
              noValidate
              sx={{ mt: 3 }}
            >
              <FormControl fullWidth>
                <TextField
                  margin="normal"
                  label="Enter your username"
                  name="username"
                  variant="outlined"
                  value={userInfo.username}
                  onChange={handleUserInfoChange}
                  disabled={submitting}
                />
                {formErrors.username !== "" && (
                  <FormHelperText error>{formErrors.username}</FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  margin="normal"
                  label="Enter your password"
                  type="password"
                  name="password"
                  variant="outlined"
                  value={userInfo.password}
                  onChange={handleUserInfoChange}
                  disabled={submitting}
                />
                {formErrors.password !== "" && (
                  <FormHelperText error>{formErrors.password}</FormHelperText>
                )}
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
