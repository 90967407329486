import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import { getReceipts } from "../../../services/ReceiptService";

import ReceiptsTable from "../../../components/CustomTable/ReceiptsTable";

const useStyles = makeStyles({
  root: {},
});

export default function Orders() {
  const classes = useStyles();
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(false);

  const retrieveOrders = async () => {
    try {
      setLoading(true);
      const resReceipts = await getReceipts();

      setReceipts(resReceipts);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading receipts.");
    }
  };

  useEffect(() => {
    retrieveOrders();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ReceiptsTable title="Receipts" loading={loading} items={receipts} />
        </Grid>
      </Grid>
    </div>
  );
}
