import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const deleteSupplier = async (emailId) => {
  return await GetData(
    API_URL + `/SupplierEmail/GETDeleteSupplierEmail?idtag5= ${emailId}`
  );
};

const getSuppliers = async () => {
  return await GetData(API_URL + `/SupplierEmail/GET`);
};

const postSupplier = async (postData) => {
  return await PostData(API_URL + "/SupplierEmail/POST", postData);
};

const putSupplier = async (putData) => {
  return await PutData(API_URL + "/SupplierEmail/PUT", putData);
};

export { deleteSupplier, getSuppliers, postSupplier, putSupplier };
