import "@mui/lab/themeAugmentation";
import { createTheme as createMuiTheme } from "@mui/material/styles";

import breakpoints from "./breakpoints";
import components from "./components";
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";
import variants from "./variants";

const createTheme = () => {
  return createMuiTheme({
    spacing: 4,
    components: components,
    palette: palette,
  });
};

export default createTheme;
