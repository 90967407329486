import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

export default function PreviewJobMaterialsTable({
  title,
  loading,
  items,
  handleEdit,
  handleDelete,
}) {
  const [data, setData] = useState([]);
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const [editableRow, setEditableRow] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);

  const columns = [
    {
      name: "ITEM_ID",
      label: "ITEM_ID",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_ID}
                onChange={handleItemIdChange}
                error={editableRow.ITEM_ID === ""}
                helperText={editableRow.ITEM_ID === "" ? "Required." : ""}
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_DESC",
      label: "ITEM_DESC",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_DESC}
                onChange={handleItemDescChange}
                error={editableRow.ITEM_DESC === ""}
                helperText={editableRow.ITEM_DESC === "" ? "Required." : ""}
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_SUPPLIER",
      label: "ITEM_SUPPLIER",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_SUPPLIER}
                onChange={handleItemSupplierChange}
                error={editableRow.ITEM_SUPPLIER === ""}
                helperText={editableRow.ITEM_SUPPLIER === "" ? "Required." : ""}
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_COSTxUNIT",
      label: "ITEM_COSTxUNIT",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_COSTxUNIT}
                onChange={handleItemCostXUnitChange}
                error={editableRow.ITEM_COSTxUNIT === ""}
                helperText={
                  editableRow.ITEM_COSTxUNIT === "" ? "Required." : ""
                }
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_UNIT",
      label: "ITEM_UNIT",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_UNIT}
                onChange={handleItemUnitChange}
                error={editableRow.ITEM_UNIT === ""}
                helperText={editableRow.ITEM_UNIT === "" ? "Required." : ""}
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_DIV",
      label: "ITEM_DIV",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_DIV}
                onChange={handleItemDivChange}
                error={editableRow.ITEM_DIV === ""}
                helperText={editableRow.ITEM_DIV === "" ? "Required." : ""}
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_COST",
      label: "ITEM_COST",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_COST}
                onChange={handleItemCostChange}
                error={editableRow.ITEM_COST === ""}
                helperText={editableRow.ITEM_COST === "" ? "Required." : ""}
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_SHORT_DESC",
      label: "ITEM_SHORT_DESC",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_SHORT_DESC}
                onChange={handleItemShortDescChange}
                error={editableRow.ITEM_SHORT_DESC === ""}
                helperText={
                  editableRow.ITEM_SHORT_DESC === "" ? "Required." : ""
                }
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_CATEGORY",
      label: "ITEM_CATEGORY",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_CATEGORY}
                onChange={handleItemCategoryChange}
                error={editableRow.ITEM_CATEGORY === ""}
                helperText={editableRow.ITEM_CATEGORY === "" ? "Required." : ""}
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "ITEM_ORDER",
      label: "ITEM_ORDER",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.ITEM_ORDER}
                onChange={handleItemOrderChange}
                error={editableRow.ITEM_ORDER === ""}
                helperText={editableRow.ITEM_ORDER === "" ? "Required." : ""}
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleEdit && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      {editableRowIndex === rowIndex ? (
                        <IconButton
                          title="save"
                          color="primary"
                          onClick={handleRowSave}
                          disabled={isInvalid}
                        >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          title="edit"
                          color="primary"
                          onClick={(event) => handleRowEdit(event, rowIndex)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Grid>
                    {editableRowIndex === rowIndex && (
                      <Grid item>
                        <IconButton
                          title="cancel"
                          onClick={handleRowEditCancel}
                        >
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {handleDelete && editableRowIndex !== rowIndex && (
                <Grid item>
                  <IconButton
                    title="delete"
                    color="error"
                    onClick={(event) => handleRowDelete(event, rowData)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  };

  useEffect(() => {
    if (items !== null) {
      setData([...items]);
    }
  }, [items]);

  const handleItemIdChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_ID: e.target.value });
  };

  const handleItemDescChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_DESC: e.target.value });
  };

  const handleItemSupplierChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_SUPPLIER: e.target.value });
  };

  const handleItemCostXUnitChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_COSTxUNIT: e.target.value });
  };

  const handleItemUnitChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_UNIT: e.target.value });
  };

  const handleItemDivChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_DIV: e.target.value });
  };

  const handleItemCostChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_COST: e.target.value });
  };

  const handleItemShortDescChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_SHORT_DESC: e.target.value });
  };

  const handleItemCategoryChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_CATEGORY: e.target.value });
  };

  const handleItemOrderChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, ITEM_ORDER: e.target.value });
  };

  const handleRowEdit = (event, index) => {
    event.preventDefault();
    setEditableRowIndex(index);
    setEditableRow({ ...data[index], ITEM_ID_COPY: data[index].ITEM_ID });
  };

  const handleRowSave = (event) => {
    event.preventDefault();
    handleEdit(editableRow);
    setEditableRowIndex(-1);
    setEditableRow(null);
  };

  const handleRowEditCancel = () => {
    setEditableRow(null);
    setEditableRowIndex(-1);
  };

  const handleRowDelete = (event, rowData) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
