import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getForemanHours = async (payload) => {
  return await GetData(API_URL + `/WIP/GETForemanHours/${payload}`);
};

const getLaborHours = async () => {
  return await GetData(API_URL + `/WIP/GETCompleted`);
};

const getWIPs = async () => {
  return await GetData(API_URL + `/WIP/GETWip`);
};

export { getForemanHours, getLaborHours, getWIPs };
