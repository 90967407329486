import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

import { getSupplierMaterials } from "../../services/ChangeRequestService";

import Autocomplete from "../Autocomplete";

export default function CRMaterialsTable({
  title,
  job,
  supplier,
  markup = 0,
  items,
  hiddenColumns = [],
  loading,
  handleAdd,
  handleEdit,
  handleDelete,
}) {
  const [data, setData] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const [editableRow, setEditableRow] = useState(null);
  const [availableMaterials, setAvailableMaterials] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);

  const columns = [
    {
      name: "ITEM_ID",
      label: "Material ID",
      options: {
        display: !hiddenColumns.includes("ITEM_ID"),
      },
    },
    {
      name: "DESCRIPTION",
      label: "Description",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return supplier ? (
              <FormControl sx={{ minWidth: "300px" }}>
                <Autocomplete
                  size="small"
                  label="Material"
                  loading={loading}
                  options={availableMaterials || []}
                  getOptionLabel={(option) => option.ITEM_DESC}
                  getOptionSelected={(option, value) =>
                    option.ITEM_ID === value.ITEM_ID
                  }
                  value={availableMaterials.find(
                    (item) => item.ITEM_ID === editableRow.ITEM_ID
                  )}
                  onChange={handleMaterialChange}
                />
                {!editableRow.ITEM_ID && (
                  <FormHelperText error>Select a material.</FormHelperText>
                )}
              </FormControl>
            ) : (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.DESCRIPTION}
                onChange={handleDescriptionChange}
                error={editableRow.DESCRIPTION === ""}
                helperText={
                  editableRow.DESCRIPTION === ""
                    ? "Description is required."
                    : ""
                }
              />
            );
          }

          return supplier
            ? data[rowIndex]?.ITEM
              ? data[rowIndex]?.ITEM.ITEM_DESC
              : data[rowIndex]?.DESCRIPTION
            : data[rowIndex]?.DESCRIPTION;
        },
      },
    },
    {
      name: "QUANTITY",
      label: "Quantity",
      options: {
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseFloat(obj1.data);
            const val2 = parseFloat(obj2.data);
            data.sort((a, b) => {
              return (
                (parseFloat(a.QUANTITY) - parseFloat(b.QUANTITY)) *
                (order === "asc" ? 1 : -1)
              );
            });

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.QUANTITY}
                onChange={handleQuantityChange}
                error={editableRow.QUANTITY <= 0}
                helperText={
                  editableRow.QUANTITY <= 0
                    ? `Quantity should be more than 0.`
                    : ""
                }
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "COST",
      label: "Cost",
      options: {
        sort: true,
        display: !hiddenColumns.includes("COST"),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseFloat(obj1.data);
            const val2 = parseFloat(obj2.data);
            data.sort((a, b) => {
              return (
                (parseFloat(a.COST) - parseFloat(b.COST)) *
                (order === "asc" ? 1 : -1)
              );
            });

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return !supplier ? (
              <TextField
                variant="standard"
                size="small"
                type="number"
                value={editableRow.COST}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChange={handleCostChange}
                error={editableRow.COST <= 0}
                helperText={
                  editableRow.COST <= 0 ? `Cost should be more than 0.` : ""
                }
                sx={{ minWidth: "80px" }}
              />
            ) : (
              "$" +
                (
                  parseFloat(editableRow?.COST) +
                  parseFloat(editableRow?.COST * markup)
                ).toFixed(2)
            );
          }

          return (
            "$" +
            (
              parseFloat(data[rowIndex]?.COST) +
              parseFloat(data[rowIndex]?.COST * markup)
            ).toFixed(2)
          );
        },
      },
    },
    {
      name: "ITEM_DIV",
      label: "ITEM_DIV",
    },
    {
      name: "LINE_TOTAL",
      label: "Line Total",
      options: {
        sort: false,
        display: !hiddenColumns.includes("LINE_TOTAL"),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          return rowIndex === editableRowIndex
            ? "$" +
                (
                  editableRow?.QUANTITY *
                  (parseFloat(editableRow?.COST) +
                    parseFloat(editableRow?.COST * markup))
                ).toFixed(2)
            : "$" +
                (
                  data[rowIndex]?.QUANTITY *
                  (parseFloat(data[rowIndex]?.COST) +
                    parseFloat(data[rowIndex]?.COST * markup))
                ).toFixed(2);
        },
      },
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleEdit && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      {editableRowIndex === rowIndex ? (
                        <IconButton
                          title="save"
                          color="primary"
                          onClick={handleRowSave}
                          disabled={isInvalid}
                        >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          title="edit"
                          color="primary"
                          onClick={(event) => handleRowEdit(event, rowIndex)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Grid>
                    {editableRowIndex === rowIndex && (
                      <Grid item>
                        <IconButton
                          title="cancel"
                          onClick={handleRowEditCancel}
                        >
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {handleDelete && editableRowIndex !== rowIndex && (
                <Grid item>
                  <IconButton
                    title="delete"
                    color="error"
                    onClick={(event) => handleRowDelete(event, rowData)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleRowAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null) setData([...items]);
  }, [items]);

  useEffect(() => {
    let isMounted = true;

    if (supplier && job) {
      const retreiveSupplierMaterials = async () => {
        const newMaterials = await getSupplierMaterials(
          job.JOB_ID,
          supplier.SUPPLIER_ID
        );
        if (isMounted) setAvailableMaterials([...newMaterials]);
      };

      retreiveSupplierMaterials();
    }

    return () => {
      isMounted = false;
    };
  }, [job, supplier]);

  const handleMaterialChange = (e, newValue) => {
    console.log(newValue);
    setIsInvalid(newValue === null);
    setEditableRow({
      ...editableRow,
      ITEM: newValue,
      ITEM_ID: newValue ? newValue.ITEM_ID : undefined,
      ITEM_DESC: newValue ? newValue.ITEM_DESC : "",
      COST: newValue ? newValue.ITEM_COST : 0,
      DESCRIPTION: "",
    });
  };

  const handleDescriptionChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({
      ...editableRow,
      ITEM: null,
      ITEM_ID: undefined,
      ITEM_DESC: "",
      DESCRIPTION: e.target.value,
    });
  };

  const handleQuantityChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, QUANTITY: e.target.value });
  };

  const handleCostChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, COST: e.target.value });
  };

  const handleRowAdd = (event) => {
    event.preventDefault();

    let newItems = [];
    const newRow = {
      ID: items.length,
      ITEM_ID: null,
      ITEM_DESC: "",
      DESCRIPTION: "",
      QUANTITY: 0,
      COST: 0,
    };

    newItems.push(newRow);
    newItems = [...newItems, ...items];

    setIsAdding(true);
    setData(newItems);
    setEditableRowIndex(0);
    setEditableRow(newRow);
  };

  const handleRowEdit = (event, index) => {
    event.preventDefault();
    if (isAdding) {
      setIsAdding(false);
      setData(items);
      setEditableRowIndex(index - 1);
    } else {
      setEditableRowIndex(index);
    }
    setEditableRow(data[index]);
  };

  const handleRowSave = (event) => {
    event.preventDefault();
    const rowData = {
      ...editableRow,
      SUPPLIER_ID: supplier ? supplier.SUPPLIER_ID : null,
    };

    if (isAdding) {
      setIsAdding(false);
      handleAdd(rowData);
    } else handleEdit(rowData);
    setEditableRowIndex(-1);
    setEditableRow(null);
  };

  const handleRowEditCancel = () => {
    if (isAdding) {
      setIsAdding(false);
      setData(items);
    }
    setEditableRow(null);
    setEditableRowIndex(-1);
  };

  const handleRowDelete = (event, rowData) => {
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            title ? (
              title
            ) : (
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="h5">Materials</Typography>
                </Grid>
                {loading && (
                  <Grid item>
                    <CircularProgress size="1rem" />
                  </Grid>
                )}
              </Grid>
            )
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
