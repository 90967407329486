import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getAllDocuments = async () => {
  return await GetData(API_URL + `/Document/GETAll`);
};

const getDocuments = async (user) => {
  return await GetData(API_URL + `/Document/GET/${user.USERNAME}`);
};

const getSuppliers = async () => {
  return await GetData(API_URL + `/Document/GETSupplier`);
};

const postDocument = async (postData) => {
  return await PostData(API_URL + "/Document/POST", postData);
};

const putDocument = async (postData) => {
  return await PutData(API_URL + "/Document/PUT", postData);
};

const getAllJobMaterials = async () => {
  return await GetData(API_URL + "/Document/GETAllJobMaterials");
};

const postJobMaterials = async (payload) => {
  return await PostData(API_URL + "/Document/POSTJobMaterials", payload);
};

const putJobMaterials = async (payload) => {
  return await PutData(API_URL + "/Document/PUTJobMaterials", payload);
};

export {
  getAllDocuments,
  getDocuments,
  getSuppliers,
  getAllJobMaterials,
  postJobMaterials,
  putJobMaterials,
  postDocument,
  putDocument,
};
