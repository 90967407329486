export const SET_ADMIN_CHANGE_REQUESTS = "SET_ADMIN_CHANGE_REQUESTS";
export const SET_ADMIN_TOOL_ORDERS = "SET_ADMIN_TOOL_ORDERS";
export const SET_ALL_TOOLS = "SET_ALL_TOOLS";
export const SET_CHANGE_REQUESTS = "SET_CHANGE_REQUEST";
export const SET_ESTIMATORS = "SET_ESTIMATORS";
export const SET_FOREMEN = "SET_FOREMEN";
export const SET_JOBS = "SET_JOBS";
export const SET_LABOR_HOURS = "SET_LABOR_HOURS";
export const SET_LOADING_ALL = "SET_LOADING_ALL";
export const SET_MATERIALS = "SET_MATERIALS";
export const SET_MAKE_CHANGE = "SET_MAKE_CHANGE";
export const SET_MAKE_CHANGE_TYPE = "SET_MAKE_CHANGE_TYPE";
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_ORDERS = "SET_ORDERS";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_PHASES = "SET_PHASES";
export const SET_REPORTS = "SET_REPORTS";
export const SET_REVIEWER_TOOL_ORDERS = "SET_REVIEWER_TOOL_ORDERS";
export const SET_SUPPLIERS = "SET_SUPPLIERS";
export const SET_TOOLS = "SET_TOOLS";
export const SET_TOOL_ORDERS = "SET_TOOL_ORDERS";
export const SET_UNITS = "SET_UNITS";
export const SET_USER = "SET_USER";
export const SET_USER_AUTHENTICATED = "SET_USER_AUTHENTICATED";
export const SET_USERS = "SET_USERS";
export const SET_WIPS = "SET_WIPS";
export const SET_WORK = "SET_WORK";
