import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getContactUsData = async (emailId) => {
  return await GetData(API_URL + `/ContactUs/GETContactUs`);
};

const postContactUs = async (postData) => {
  return await PostData(API_URL + "/ContactUs/POST", postData);
};

const putContactUs = async (data) => {
  return await PutData(API_URL + `/ContactUs/PUT`, data);
};

export { getContactUsData, postContactUs, putContactUs };
