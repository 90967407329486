import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getJobs = async () => {
  return await GetData(API_URL + `/Job/GET`);
};

const getProjectJobs = async (payload) => {
  //PRO_ID
  return await GetData(API_URL + `/Job/GETJob/${payload}`);
};

export { getJobs, getProjectJobs };
