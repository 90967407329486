import {
  SET_ADMIN_CHANGE_REQUESTS,
  SET_ADMIN_TOOL_ORDERS,
  SET_ALL_TOOLS,
  SET_CHANGE_REQUESTS,
  SET_ESTIMATORS,
  SET_FOREMEN,
  SET_JOBS,
  SET_LABOR_HOURS,
  SET_LOADING_ALL,
  SET_MESSAGES,
  SET_MATERIALS,
  SET_ORDERS,
  SET_PROJECTS,
  SET_PHASES,
  SET_REPORTS,
  SET_REVIEWER_TOOL_ORDERS,
  SET_SUPPLIERS,
  SET_TOOL_INVENTORY,
  SET_TOOL_ORDERS,
  SET_TOOLS,
  SET_UNITS,
  SET_USER,
  SET_USERS,
  SET_WIPS,
  SET_WORK,
  SET_USER_AUTHENTICATED,
} from "./types";
// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_ADMIN_CHANGE_REQUESTS:
      return {
        ...state,
        adminChangeRequests: payload,
      };
    case SET_ADMIN_TOOL_ORDERS:
      return {
        ...state,
        adminToolOrders: payload,
      };
    case SET_ALL_TOOLS:
      return {
        ...state,
        allTools: payload,
      };
    case SET_CHANGE_REQUESTS:
      return {
        ...state,
        changeRequests: payload,
      };
    case SET_ESTIMATORS:
      return {
        ...state,
        estimators: payload,
      };
    case SET_FOREMEN:
      return {
        ...state,
        foremen: payload,
      };
    case SET_JOBS:
      return {
        ...state,
        jobs: payload,
      };
    case SET_LABOR_HOURS:
      return {
        ...state,
        laborHours: payload,
      };
    case SET_LOADING_ALL:
      return {
        ...state,
        loadingAll: payload,
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: payload,
      };
    case SET_MATERIALS:
      return {
        ...state,
        materials: payload,
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: payload,
      };
    case SET_PROJECTS:
      return {
        ...state,
        projects: payload,
      };
    case SET_PHASES:
      return {
        ...state,
        phases: payload,
      };
    case SET_REPORTS:
      return {
        ...state,
        reports: payload,
      };
    case SET_REVIEWER_TOOL_ORDERS:
      return {
        ...state,
        reviewerToolOrders: payload,
      };
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: payload,
      };
    case SET_TOOL_ORDERS:
      return {
        ...state,
        toolOrders: payload,
      };
    case SET_TOOLS:
      return {
        ...state,
        tools: payload,
      };
    case SET_UNITS:
      return {
        ...state,
        units: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case SET_USER_AUTHENTICATED:
      return {
        ...state,
        userAuthenticated: payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: payload,
      };
    case SET_WIPS:
      return {
        ...state,
        wips: payload,
      };
    case SET_WORK:
      return {
        ...state,
        work: payload,
      };
    default:
      return state;
  }
};
