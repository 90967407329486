import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getJobUnitMaterials = async (jobId) => {
  return await GetData(
    API_URL + `/UnitMaterial/GETUnitMaterialsByJob/${jobId}`
  );
};

const getLastOrderID = async () => {
  return await GetData(API_URL + "/Order/GETOrderID");
};

const getJobHours = async (jobId) => {
  return await GetData(API_URL + `/Order/GETJobHours/${jobId}`);
};

const getJobOptions = async (jobId) => {
  return await GetData(API_URL + `/Order/GETJobOptions/${jobId}`);
};

const getJobOptionsTotal = async (jobId) => {
  return await GetData(API_URL + `/Order/GETJobOptionTotals/${jobId}`);
};

const getJobOrders = async (jobId) => {
  return await GetData(API_URL + `/Order/GETJobOrders/${jobId}`);
};

const getJobTotals = async (jobId) => {
  return await GetData(API_URL + `/Order/GETJobTotals/${jobId}`);
};

const getAllOrders = async () => {
  return await GetData(API_URL + "/Order/GETAll");
};

const getForemanOrders = async (user) => {
  return await GetData(API_URL + `/Order/GETForeman/${user.USERNAME}`);
};

const getOrders = async (user) => {
  return await GetData(API_URL + `/Order/GET/${user.USERNAME}`);
};

const getOrder = async (orderId) => {
  return await GetData(API_URL + `/Order/GETOrder/${orderId}`);
};

const getOrderUnitMaterials = async (jobId, unitId) => {
  return await GetData(API_URL + `/Order/GETOrderMaterials/${jobId}/${unitId}`);
};

const getUnitMaterials = async (orderId) => {
  return await GetData(API_URL + `/Order/GETUnitMaterial/${orderId}`);
};

const postOrder = async (postData) => {
  return await PostData(API_URL + "/Order/POST", postData);
};

const postOrderUnitMaterials = async (postData) => {
  return await PostData(API_URL + "/Order/POSTOrderPlusMaterial", postData);
};

const postUnitMaterial = async (postData) => {
  return await PostData(API_URL + "/Order/POSTMaterial", postData);
};

const putOrder = async (data) => {
  return await PutData(API_URL + "/Order/PUT", data);
};

export {
  getJobUnitMaterials,
  getLastOrderID,
  getJobHours,
  getJobOptions,
  getJobOrders,
  getJobTotals,
  getJobOptionsTotal,
  getAllOrders,
  getForemanOrders,
  getOrders,
  getOrder,
  getOrderUnitMaterials,
  getUnitMaterials,
  postOrder,
  postOrderUnitMaterials,
  postUnitMaterial,
  putOrder,
};
