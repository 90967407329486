import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getMessages = async (user) => {
  return await GetData(API_URL + `/Message/get/${user.USERNAME}`);
};

const postMessage = async (user, postData) => {
  return await PostData(API_URL + `/Message/POST/${user.USERNAME}`, postData);
};
const putMessage = async (payload) => {
  return await PutData(
    API_URL + `/Message/PUT/${payload.USERNAME_TO}`,
    payload
  );
};

export { getMessages, postMessage, putMessage };
