import { useCallback, useContext, useEffect, useState } from "react";
import { format } from "fecha";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";

import AppContext from "../../../context/AppContext";

import {
  addToolOrder,
  approveToolOrder,
  deleteToolOrder,
  editToolOrder,
  getReviewerToolOrders,
  getToolOrders,
} from "../../../services/ToolOrderService";

import AddToolOrder from "./AddToolOrder";
import EditToolOrder from "./EditToolOrder";
import ToolOrdersTable from "../../../components/CustomTable/ToolOrdersTable";

export default function EmployeeToolOrders() {
  const {
    allTools,
    jobs,
    projects,
    reviewerToolOrders,
    toolOrders,
    user,
    setToolOrders,
    setReviewerToolOrders,
  } = useContext(AppContext);
  const initialToolOrderData = {
    requestId: "",
    idTag: "",
    toolStatusType: "shop",
    toolStatus: "Coombs Rd",
    toolShortDescription: "",
    toolDescription: "",
    proId: "",
    project: "",
    jobId: "",
    job: "",
  };
  const [toolOrderData, setToolOrderData] = useState(initialToolOrderData);
  const [loadingToolOrders, setLoadingToolOrders] = useState(false);
  const [loadingReviewerToolOrders, setLoadingReviewerToolOrders] =
    useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const retrieveToolOrders = useCallback(async () => {
    try {
      setLoadingToolOrders(true);
      const newTooolOrders = await getToolOrders(user);
      setToolOrders(newTooolOrders);
      setLoadingToolOrders(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading tool orders!");
    }
  }, [user, setToolOrders]);

  const retrieveReviewerToolOrders = useCallback(async () => {
    try {
      setLoadingReviewerToolOrders(true);
      const _reviewerToolOrders = await getReviewerToolOrders(user);
      setReviewerToolOrders(_reviewerToolOrders);
      setLoadingReviewerToolOrders(false);
    } catch (e) {
      console.error(e);
      toast.error(
        "Unexpected error occurs while loading reviewer's tool orders!"
      );
    }
  }, [user, setReviewerToolOrders]);

  useEffect(() => {
    if (user) {
      retrieveToolOrders();
      retrieveReviewerToolOrders();
    }

    return () => {};
  }, [user]);

  const handleToolOrderAdd = () => {
    setToolOrderData(initialToolOrderData);
    setIsAdding(true);
    setIsEditing(false);
  };

  const handleToolOrderEdit = (rowData) => {
    const tool = allTools.find((tool) => tool.ID_TAG === rowData.ID_TAG);
    const job = jobs.find((job) => job.JOB_DESC === rowData.JOB_DESC);
    const project = projects.find(
      (project) => project.DESCRIPTION === rowData.DESCRIPTION
    );
    const toolOrder = {
      requestId: rowData.TOOL_REQUEST_ID,
      idTag: rowData.ID_TAG,
      toolStatusType: tool.TOOL_STATUS === "Coombs Rd" ? "shop" : "employee",
      toolStatus: tool.TOOL_STATUS,
      toolShortDescription: tool.TOOL_SHORT_DESCRIPTION,
      toolDescription: tool.TOOL_DESCRIPTION,
      tool: tool,
      proId: project.PRO_ID,
      project: project,
      jobId: job.JOB_ID,
      job: job,
    };
    console.log(toolOrder);
    setToolOrderData(toolOrder);
    setIsAdding(false);
    setIsEditing(true);
  };

  const handleToolOrderCreate = (newToolOrder) => {
    setIsSubmitting(true);
    const postData = {
      USERNAME: user.USERNAME,
      REQUEST_SENT_TO:
        newToolOrder.toolStatusType === "employee"
          ? newToolOrder.toolStatus
          : "Coombs Rd",
      ID_TAG: newToolOrder.tool.ID_TAG,
      PRO_ID: newToolOrder.project.PRO_ID,
      JOB_ID: newToolOrder.job.JOB_ID,
      REQUEST_DATE: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
    };

    addToolOrder(postData)
      .then(async (res) => {
        setIsSubmitting(false);
        if (res.status === 200) {
          toast.success("Submission Successful!");
          await retrieveToolOrders();

          setToolOrderData(initialToolOrderData);
          setIsAdding(false);
          setIsEditing(false);
        } else {
          toast.error("Submission Failed!");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSubmitting(false);
        toast.error("Submission Failed!");
      });
  };

  // const handleToolOrderUpdate = (newToolOrder) => {
  //   setIsSubmitting(true);
  //   const postData = {
  //     TOOL_REQUEST_ID: newToolOrder.requestId,
  //     USERNAME: user.USERNAME,
  //     REQUEST_SENT_TO:
  //       newToolOrder.toolStatusType === "employee"
  //         ? newToolOrder.toolStatus
  //         : "Coombs Rd",
  //     ID_TAG: newToolOrder.tool.ID_TAG,
  //     PRO_ID: newToolOrder.project.PRO_ID,
  //     JOB_ID: newToolOrder.job.JOB_ID,
  //     REQUEST_DATE: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
  //   };

  //   editToolOrder(postData)
  //     .then(async (res) => {
  //       setIsSubmitting(false);
  //       if (res.status === 200) {
  //         toast.success("Tool Order Updated!");
  //         await retrieveToolOrders();

  //         setToolOrderData(initialToolOrderData);
  //         setIsAdding(false);
  //         setIsEditing(false);
  //       } else {
  //         toast.error("Tool Order Not Updated!");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsSubmitting(false);
  //       toast.error("Tool Order Not Updated!");
  //     });
  // };

  const handleToolOrderDelete = (rowData) => {
    setIsDeleting(true);
    deleteToolOrder(rowData.TOOL_REQUEST_ID)
      .then(async (res) => {
        toast.success("Tool Order Deleted!");
        await retrieveToolOrders();

        setToolOrderData(initialToolOrderData);
        setIsAdding(false);
        setIsEditing(false);
        setIsDeleting(false);
      })
      .catch((err) => {
        console.log(err);
        setIsDeleting(false);
        toast.error("Tool Order Not Deleted!");
      });
  };

  const handleToolOrderApprove = (toolOrder) => {
    setIsApproving(true);
    approveToolOrder(toolOrder)
      .then((res) => {
        setIsApproving(false);
        if (res.status === 200) {
          const newToolOrders = reviewerToolOrders.filter(
            (item) => item.TOOL_REQUEST_ID !== toolOrder.TOOL_REQUEST_ID
          );
          setReviewerToolOrders(newToolOrders);
          if (toolOrder.APPROVED) toast.success("Tool order approved!");
          else toast.success("Tool order declined!");
        } else {
          toast.error("Tool order not updated");
        }
      })
      .catch((err) => {
        console.log(err);
        setIsApproving(false);
        toast.error("Something went wrong");
      });
  };

  return (
    <Grid container spacing={4}>
      {isAdding && (
        <Grid item xs={12}>
          <AddToolOrder
            submitting={isSubmitting}
            handleSubmit={handleToolOrderCreate}
          />
        </Grid>
      )}
      {/* {isEditing && (
        <Grid item xs={12}>
          <EditToolOrder
            toolOrder={toolOrderData}
            submitting={isSubmitting}
            handleSubmit={handleToolOrderUpdate}
          />
        </Grid>
      )} */}
      <Grid item xs={12}>
        <ToolOrdersTable
          title="Tool Orders (Requested By Me)"
          loading={loadingToolOrders || isDeleting}
          items={toolOrders?.filter((item) => item.APPROVED === null) || []}
          hiddenColumns={["USERNAME"]}
          handleAdd={handleToolOrderAdd}
          // handleEdit={handleToolOrderEdit}
          handleDelete={handleToolOrderDelete}
        />
      </Grid>
      <Grid item xs={12}>
        <ToolOrdersTable
          title="Tool Orders (Others Requested My Tools)"
          loading={loadingReviewerToolOrders || isApproving}
          items={reviewerToolOrders || []}
          handleApprove={handleToolOrderApprove}
        />
      </Grid>
    </Grid>
  );
}
