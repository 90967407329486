import {
  Autocomplete as MuiAutocomplete,
  CircularProgress,
  TextField,
} from "@mui/material";

const Autocomplete = ({
  label,
  loading,
  size = "normal",
  variant = "outlined",
  ...params
}) => {
  return (
    <MuiAutocomplete
      {...params}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          label={label}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default Autocomplete;
