import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

const useStyles = makeStyles({
  root: {},
});

export default function WIPTable({
  columnTitles,
  items,
  accessOrder,
  buttonFunc,
  buttonText,
  itemID = "id",
  downloadable = false,
  summate = null,
}) {
  const [order, setOrder] = useState("asc");
  const [orderedBy, setOrderedBy] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [summations, setSummations] = useState(null);

  useEffect(() => {
    if (columnTitles === null) {
      return;
    }
    setOrderedBy(columnTitles[0]);
  }, [columnTitles]);

  useEffect(() => {
    if (
      items === null ||
      summate === null ||
      summate === undefined ||
      summate.length === 0
    ) {
      return;
    }

    let itemsCopy = items.map((item) => {
      return { ...item };
    });

    let summations = stableSort(
      itemsCopy,
      getComparator(order, orderedBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    let regex = new RegExp("[^-?0-9,?0-9.?0-9]", "g");

    let sumArr = summate.map(({ name }) => name);

    summations = summations.reduce((accumulator, item) => {
      accessOrder.forEach((accessor) => {
        if (sumArr.indexOf(accessor) > -1) {
          // need to account for negative values using both negative sign and S&P of parentheses to indicate loss in accounting
          let value = 0;
          if (typeof item[accessor] === "number") {
            value = item[accessor];
          } else if (typeof item[accessor] !== "undefined") {
            value = Number(
              item[accessor].replaceAll(regex, "").split(",").join("")
            );
            if (
              item[accessor].indexOf("(") > -1 &&
              item[accessor].indexOf(")") > -1
            ) {
              value = -Math.abs(value);
            }
          }
          accumulator[accessor] = (Number(accumulator[accessor]) || 0) + value;
        } else {
          accumulator[accessor] = "";
        }
      });
      return accumulator;
    }, {});

    summate.forEach(({ name, format }) => {
      if (format === "currency") {
        summations[name] = Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(summations[name]);
      }
    });

    setSummations(summations);
  }, [items, order, orderedBy, page, rowsPerPage, summate]);

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderedBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderedBy(property);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (items === null) {
    return (
      <>
        <TableContainer component={Paper}></TableContainer>
      </>
    );
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, items.length - page * rowsPerPage);

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columnTitles.map((title) => (
                <TableCell sortDirection={orderedBy === title ? order : false}>
                  <TableSortLabel
                    active={orderedBy === title}
                    direction={orderedBy === title ? order : "asc"}
                    onClick={createSortHandler(title)}
                  >
                    <b>{title}</b>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {columnTitles &&
              items &&
              stableSort(items, getComparator(order, orderedBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={row[itemID]}>
                    {accessOrder.map((accessor) => (
                      <TableCell>{row[accessor] || ""}</TableCell>
                    ))}
                    {buttonFunc && (
                      <TableCell align="right">
                        <Button
                          onClick={() => buttonFunc(row[itemID])}
                          variant="outlined"
                        >
                          {buttonText}
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {downloadable && items && (
        <CSVLink data={items} filename={"WIP.csv"}>
          Download as CSV
        </CSVLink>
      )}

      <TableContainer component={Paper}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columnTitles.map((title) => (
                <TableCell sortDirection={orderedBy === title ? order : false}>
                  <TableSortLabel
                    active={orderedBy === title}
                    direction={orderedBy === title ? order : "asc"}
                    onClick={createSortHandler(title)}
                  >
                    <b>{title}</b>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {summate && summations && (
              <TableRow>
                {accessOrder.map((accessor) => (
                  <TableCell>{summations[accessor] || ""}</TableCell>
                ))}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
