import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import ForemenTable from "../../../components/CustomTable/ForemenTable";

import {
  getForemenProjects,
  postForeman,
  putForeman,
  deleteForeman,
} from "../../../services/ForemanService";

const useStyles = makeStyles({
  root: {},
});

export default function Foremen() {
  const classes = useStyles();
  const [foremenData, setForemenData] = useState([]);
  const [loading, setLoading] = useState(false);

  const retreiveForemenData = async () => {
    try {
      setLoading(true);
      const resForemen = await getForemenProjects();
      setForemenData(resForemen);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading foremen table data!");
    }
  };

  useEffect(() => {
    retreiveForemenData();
  }, []);

  const handleForemanAdd = (newRowData) => {
    const postData = {
      USER_ID: newRowData.USER_ID,
      PRO_ID: newRowData.PRO_ID,
    };

    setLoading(true);
    postForeman(postData)
      .then(async (res) => {
        setLoading(false);
        if (res.status === 200) {
          await retreiveForemenData();
          toast.success("Foreman info added.");
        } else toast.error("Foreman info not added!");
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleForemanEdit = (rowData) => {
    const payload = {
      USER_ID: rowData.USER_ID,
      PRO_ID: rowData.PRO_ID,
    };

    setLoading(true);
    putForeman(payload)
      .then(async (res) => {
        console.log(res);
        setLoading(false);
        if (res.status === 200) {
          await retreiveForemenData();
          toast.success("Foreman info updated.");
        } else toast.error("Foreman info not updated!");
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleForemanDelete = (rowData) => {
    const userId = rowData.USER_ID;
    setLoading(true);
    deleteForeman(userId)
      .then(async (res) => {
        setLoading(false);
        await retreiveForemenData();
        toast.success("Foreman info deleted.");
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ForemenTable
            title="Foremen"
            loading={loading}
            items={foremenData}
            handleAdd={handleForemanAdd}
            handleEdit={handleForemanEdit}
            handleDelete={handleForemanDelete}
          />
        </Grid>
      </Grid>
    </div>
  );
}
