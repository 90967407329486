import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const addUser = async (user) => {
  return await PostData(API_URL + "/User/POSTNew", user);
};

const editUserInfo = async (user) => {
  return await PutData(API_URL + "/User/PUTMain", user);
};

const editUserStatus = async (user) => {
  return await PutData(API_URL + "/User/PUT", user);
};

const getEstimators = async () => {
  return await GetData(API_URL + "/User/GETEstimator");
};

const getForemen = async () => {
  return await GetData(API_URL + "/User/GETForemen");
};

const getUsers = async () => {
  return await GetData(API_URL + "/User/GETAll");
};
const postUser = (userInfo) => {
  return PostData(API_URL + "/User/POST", userInfo);
};

export {
  addUser,
  editUserInfo,
  editUserStatus,
  getEstimators,
  getForemen,
  getUsers,
  postUser,
};
