import { useState } from "react";
import { makeStyles } from "@mui/styles";

import SubHeader from "../../components/SubHeader";
import EmployeeToolInventory from "./EmployeeToolInventory";
import EmployeeToolOrders from "./EmployeeToolOrders";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
  },
  dashboardDiv: {
    margin: "5rem auto",
  },
});

const subHeaderItems = [
  { value: "toolOrders", text: "Tool Orders" },
  { value: "toolInventory", text: "Tool Inventory" },
];

export default function Tool() {
  const classes = useStyles();
  const [selectedSubHeaderItem, setSelectedSubHeaderItem] = useState(
    subHeaderItems[0].value
  );

  const handleSubHeaderItemSelect = (selected) => {
    setSelectedSubHeaderItem(selected);
  };

  return (
    <div>
      <SubHeader
        items={subHeaderItems}
        handleSelect={handleSubHeaderItemSelect}
        select={selectedSubHeaderItem}
      />
      <div className={classes.root}>
        <div className={classes.dashboardDiv}>
          {selectedSubHeaderItem === "toolOrders" ? (
            <EmployeeToolOrders />
          ) : selectedSubHeaderItem === "toolInventory" ? (
            <EmployeeToolInventory />
          ) : (
            <EmployeeToolOrders />
          )}
        </div>
      </div>
    </div>
  );
}
