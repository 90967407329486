import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { format } from "fecha";
import { read, utils } from "xlsx";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../../context/AppContext";
import { getJobSuppliers } from "../../../services/ChangeRequestService";
import { getProjectJobs } from "../../../services/JobService";
import { getProjects } from "../../../services/ProjectService";

import Autocomplete from "../../../components/Autocomplete";
import PreviewJobMaterialsTable from "../../../components/CustomTable/PreviewJobMaterialsTable";

const useStyles = makeStyles({});

export default function AddJobMaterials({
  submitting = false,
  handleSubmit,
  handleCancel,
}) {
  const { jobs, projects, suppliers, setJobs, setProjects, setSuppliers } =
    useContext(AppContext);
  const classes = useStyles();
  const fileUploadRef = useRef(null);
  const [jobMaterials, setJobMaterials] = useState([]);
  const [errors, setErrors] = useState({
    project: "",
    job: "",
    supplier: "",
    materials: "",
  });
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);

  const retrieveJobs = async (proId) => {
    try {
      setLoadingJobs(true);
      const newJobs = await getProjectJobs(proId);
      setJobs(newJobs);
      setLoadingJobs(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading jobs!");
    }
  };

  const retrieveSuppliers = async (jobId) => {
    try {
      setLoadingSuppliers(true);
      const newSuppliers = await getJobSuppliers(jobId);
      setSuppliers(newSuppliers);
      setLoadingSuppliers(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading suppliers");
    }
  };

  useEffect(() => {
    let mounted = true;

    const retrieveProjects = async () => {
      try {
        if (mounted) setLoadingProjects(true);
        const newProjects = await getProjects();
        if (mounted) {
          setProjects(newProjects);
          setLoadingProjects(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading projects!");
      }
    };

    retrieveProjects();

    return () => {
      mounted = false;
    };
  }, []);

  const formValidate = () => {
    const valid =
      selectedProject && selectedJob && selectedSupplier && jobMaterials.length;

    setErrors({
      project: selectedProject ? "" : "Project is required.",
      job: selectedJob ? "" : "Job is required.",
      supplier: selectedSupplier ? "" : "Supplier is required.",
      materials: jobMaterials.length ? "" : "Job materials are required.",
    });

    return valid;
  };

  const handleFormSubmit = () => {
    if (!formValidate()) return;

    const data = {
      project: selectedProject,
      job: selectedJob,
      supplier: selectedSupplier,
      materials: jobMaterials,
    };

    handleSubmit(data);
  };

  const handleProjectChange = async (event, newValue) => {
    setErrors({
      ...errors,
      project: newValue ? "" : "Project is required.",
    });
    setSelectedProject(newValue);
    if (newValue) retrieveJobs(newValue?.PRO_ID);
    else setSelectedJob(null);
  };

  const handleJobChange = (event, newValue) => {
    setErrors({
      ...errors,
      job: newValue ? "" : "Job is required.",
    });
    setSelectedJob(newValue);
    if (newValue) retrieveSuppliers(newValue?.JOB_ID);
    else setSelectedSupplier(null);
  };

  const handleSupplierChange = (event, newValue) => {
    setErrors({
      ...errors,
      supplier: newValue ? "" : "Supplier is required.",
    });
    setSelectedSupplier(newValue);
  };

  const getJobsFromProject = (proId) => {
    return jobs.filter((job) => proId === "" || job.PRO_ID === proId);
  };

  const generateTableData = (data) => {
    const keys = data[0];
    const elements = data.slice(1);
    let result = [];

    elements.map((element) => {
      let json = {};
      for (let i = 0; i < keys.length; i++) json[keys[i]] = element[i];
      result.push(json);
    });
    return result;
  };

  const handleUploadClick = () => {
    setJobMaterials([]);
    fileUploadRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    try {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

        if (jsonData) {
          const tableItems = generateTableData(jsonData);
          console.log(tableItems);
          setJobMaterials([...tableItems]);
        }
      };

      reader.readAsArrayBuffer(file);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while reading spreadsheet file!");
    }
  };

  const handleTableRowEdit = (rowData) => {
    const newJobMaterials = jobMaterials.map((item) =>
      item.ITEM_ID === rowData.ITEM_ID_COPY ? rowData : item
    );

    setJobMaterials([...newJobMaterials]);
  };

  const handleTableRowDelete = (rowData) => {
    const newJobMaterials = jobMaterials.filter(
      (item) => item.ITEM_ID !== rowData.ITEM_ID
    );

    setJobMaterials([...newJobMaterials]);
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={<Typography variant="h6">Add Job Materials</Typography>}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Project"
                    variant="outlined"
                    size="small"
                    loading={loadingProjects}
                    disabled={loadingProjects}
                    options={projects || []}
                    getOptionLabel={(option) => option.DESCRIPTION}
                    value={selectedProject}
                    onChange={handleProjectChange}
                  />
                  {errors.project !== "" && (
                    <FormHelperText error>{errors.project}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Job"
                    variant="outlined"
                    size="small"
                    loading={loadingJobs}
                    disabled={loadingJobs || !selectedProject}
                    options={
                      selectedProject
                        ? getJobsFromProject(selectedProject.PRO_ID)
                        : []
                    }
                    getOptionLabel={(option) => option.JOB_DESC}
                    value={selectedJob}
                    onChange={handleJobChange}
                  />
                  {errors.job !== "" && (
                    <FormHelperText error>{errors.job}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Supplier"
                    variant="outlined"
                    size="small"
                    loading={loadingSuppliers}
                    disabled={loadingSuppliers || !selectedJob}
                    options={suppliers || []}
                    getOptionLabel={(option) => option.NAME}
                    value={selectedSupplier}
                    onChange={handleSupplierChange}
                  />

                  {errors.supplier !== "" && (
                    <FormHelperText error>{errors.supplier}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleUploadClick}
                  fullWidth
                >
                  Upload Materials
                </Button>
                <input
                  ref={fileUploadRef}
                  type="file"
                  accept="*"
                  hidden
                  onChange={handleFileUpload}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <PreviewJobMaterialsTable
                title="Job Materials"
                items={jobMaterials}
                handleEdit={handleTableRowEdit}
                handleDelete={handleTableRowDelete}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              disabled={submitting}
            >
              Add
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleCancel}
              disabled={submitting}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
