import { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import Autocomplete from "../../Autocomplete";

const SetOrderDeliveryDateForm = ({
  loading,
  values,
  orders,
  handleSubmit,
  handleCancel,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [formData, setFormData] = useState(values);
  const [errors, setErrors] = useState({ orders: "", deliveryDate: "" });

  const handleOrdersChange = (e, newValue) => {
    setErrors({ ...errors, orders: newValue ? "" : "Select the orders" });
    setFormData({ ...formData, orders: newValue });
  };

  const handleDeliveryDateChange = (value) => {
    setErrors({
      ...errors,
      deliveryDate: value ? "" : "Select a delivery date",
    });
    setFormData({ ...formData, deliveryDate: value });
  };

  const handleSave = () => {
    const isValid = formData.orders.length && formData.deliveryDate;

    setErrors({
      orders: formData.orders.length ? "" : "Select the orders.",
      deliveryDate: formData.deliveryDate ? "" : "Selete a delivery date.",
    });

    if (!isValid) return;

    handleSubmit(formData);
  };

  return (
    <Card variant="outlined">
      <CardHeader title="Set Delivery Date" />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                label="Orders"
                loading={loading}
                options={orders}
                getOptionLabel={(option) => String(option.ORDER_ID)}
                value={formData.orders}
                onChange={handleOrdersChange}
                multiple
              />
              {errors.orders && (
                <FormHelperText error>{errors.orders}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <DatePicker
                label="Delivery Date"
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={
                  formData.deliveryDate ? new Date(formData.deliveryDate) : null
                }
                onChange={handleDeliveryDateChange}
              />
              {errors.deliveryDate && (
                <FormHelperText error>{errors.deliveryDate}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  disabled={submitting}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  disabled={submitting}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default SetOrderDeliveryDateForm;
