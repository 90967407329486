import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../context/AppContext";
import { postReceipt } from "../../services/ReceiptService";
import ImageUploadCard from "../../components/ImageUploadCard";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
    maxWidth: 1400,
  },
  container: {
    margin: "5rem auto",
  },
});

export default function Orders() {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    cost: 0,
    image: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const isValid = () => {
    setFormErrors({
      ...formData,
      title: formData.title ? "" : "Title is required.",
      description: formData.description ? "" : "Description is required.",
      cost: formData.cost ? "" : "Cost is required.",
    });

    if (!formData.title || !formData.description || !formData.cost)
      return false;
    return true;
  };

  const handleTitleChange = (e) => {
    setFormErrors({
      ...formErrors,
      title: formData.title ? "" : "Title is required.",
    });
    setFormData({
      ...formData,
      title: e.target.value,
    });
  };

  const handleCostChange = (e) => {
    setFormErrors({
      ...formErrors,
      cost: formData.cost ? "" : "Cost is required.",
    });
    setFormData({
      ...formData,
      cost: e.target.value,
    });
  };

  const handleDescriptionChange = (e) => {
    setFormErrors({
      ...formErrors,
      description: formData.description ? "" : "Description is required.",
    });
    setFormData({
      ...formData,
      description: e.target.value,
    });
  };

  const handleImageChange = (fileData) => {
    setFormData({
      ...formData,
      image: fileData,
    });
  };

  const handleSubmit = () => {
    if (!isValid()) return;

    let postData = {
      DESCRIPTION: formData.title,
      COMMENTS: formData.description,
      COST: formData.cost,
      USERNAME: user.USERNAME,
      NAME: `recepit_${new Date().getTime()}_${user.USERNAME}_${
        formData.image.name
      }`,
      FILE_STREAM: formData.image.file,
    };

    setSubmitting(true);

    postReceipt(postData)
      .then((res) => {
        setSubmitting(false);
        if (res.status === 200) {
          setFormData({
            title: "",
            description: "",
            cost: 0,
            image: null,
          });
          toast.success("Receipt submitted!");
        } else {
          toast.success("Receipt not submitted!");
        }
      })
      .catch((e) => {
        setSubmitting(false);
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={<Typography variant="h5">Receipt Form</Typography>}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="Title"
                          type="text"
                          variant="outlined"
                          value={formData.title}
                          onChange={handleTitleChange}
                          error={Boolean(formErrors.title)}
                          helperText={formErrors.title}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Cost"
                          type="number"
                          variant="outlined"
                          value={formData.cost}
                          onChange={handleCostChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(formErrors.cost)}
                          helperText={formErrors.cost}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Description"
                          type="text"
                          variant="outlined"
                          value={formData.description}
                          onChange={handleDescriptionChange}
                          error={Boolean(formErrors.description)}
                          helperText={formErrors.description}
                          multiline
                          minRows={5}
                          maxRows={5}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} md={8}>
                        <ImageUploadCard
                          image={formData.image?.file || null}
                          handleChange={handleImageChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      disabled={Boolean(submitting)}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
