import { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

const useStyles = makeStyles({
  root: {},
});

export default function MessagesTable({ title, loading, items, handleDelete }) {
  const classes = useStyles();
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "SUBJECT",
      label: "SUBJECT",
    },
    {
      name: "MESSAGE",
      label: "MESSAGE",
    },
    {
      name: "USERNAME_FROM",
      label: "FROM",
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleDelete && (
                <Grid item>
                  <IconButton
                    color="error"
                    onClick={(event) => handleRowDelete(event, rowData)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  };

  useEffect(() => {
    if (items !== null) {
      setData([...items]);
    }
  }, [items]);

  const handleRowDelete = (event, rowData) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
