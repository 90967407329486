import { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { FormControl } from "@mui/material";

import FuzzySearchSelect from "../../components/FuzzySearchSelect";

import AppContext from "../../context/AppContext";
import SortableTable from "../../components/CustomTable/SortableTable";
import WIPTable from "../../components/CustomTable/WIPTable";

import { GetData } from "../../helpers/httpRequests";
import DrawerCheckboxes from "../../components/DrawerCheckboxes";

const useStyles = makeStyles({
  root: {},
  header: {
    backgroundColor: "lightGray",
    padding: 7,
  },
  selector: {
    width: "20%",
    margin: 10,
  },
});

export default function WIP({ values, setValues }) {
  const classes = useStyles();
  let appContext = useContext(AppContext);

  const [pageValues, setPageValues] = useState({
    lot: "",
    project: "",
  });

  const [tableInfo, setTableInfo] = useState({
    originalAccessOrder: [],
    columnTitles: [], // These might be the same...
  });

  const [fetchedData, setFetchedData] = useState({
    projects: null,
    lots: null,
    selectedWips: null,
  });

  useEffect(() => {
    if (appContext.projects === null || appContext.wips === null) {
      return;
    }
    let wips = appContext.wips;
    let lotWips = wips.map((lwip) => {
      return { ...lwip };
    });
    setFetchedData({
      projects: appContext.projects,
      lots: lotWips.map((wip) => {
        return { LOT: wip.LOT };
      }),
      selectedWips: wips,
    });

    let colTitles = [];
    Object.keys(wips[0] || []).forEach((key) => colTitles.push(key));
    setTableInfo({
      originalAccessOrder: colTitles,
      columnTitles: colTitles,
    });
  }, [appContext]);

  useEffect(() => {
    if (
      (pageValues.project === "" ||
        pageValues.project === null ||
        pageValues.project === undefined) &&
      (pageValues.lot === "" ||
        pageValues.lot === null ||
        pageValues.lot === undefined)
    ) {
      return;
    }

    let selectedWIP = appContext.wips;
    if (
      !(
        pageValues.project === "" ||
        pageValues.project === null ||
        pageValues.project === undefined
      )
    ) {
      selectedWIP = selectedWIP.filter(
        (wip) => wip.JOB === pageValues.project.DESCRIPTION
      );
    }

    if (
      !(
        pageValues.lot === "" ||
        pageValues.lot === null ||
        pageValues.lot === undefined
      )
    ) {
      selectedWIP = selectedWIP.filter((wip) => wip.LOT === pageValues.lot.LOT);
    }

    setFetchedData({
      ...fetchedData,
      selectedWips: selectedWIP,
    });
  }, [pageValues.project, pageValues.lot]);

  return (
    <div className={classes.root}>
      <h2 className={classes.header}>WIP</h2>
      <FormControl className={classes.selector}>
        <FuzzySearchSelect
          label="Job"
          valueLabel="project"
          values={pageValues}
          setValues={setPageValues}
          optionLabel="DESCRIPTION"
          options={fetchedData.projects}
        />
      </FormControl>
      <FormControl className={classes.selector}>
        <FuzzySearchSelect
          label="Lot"
          valueLabel="lot"
          values={pageValues}
          setValues={setPageValues}
          optionLabel="LOT"
          options={fetchedData.lots}
        />
      </FormControl>
      <FormControl className={classes.selector}>
        <DrawerCheckboxes
          buttonText="WIP Items"
          label="columnTitles"
          values={tableInfo}
          setValues={setTableInfo}
          options={tableInfo.originalAccessOrder}
          position="right"
        />
      </FormControl>
      <div>
        <WIPTable
          columnTitles={tableInfo.columnTitles}
          items={fetchedData.selectedWips}
          itemID="JOB_ID"
          accessOrder={tableInfo.columnTitles}
          downloadable={true}
          summate={[
            { name: "ACCRUED", format: "currency" },
            { name: "CONTRACT", format: "currency" },
            { name: "CRANE_ACT", format: "currency" },
            { name: "CRANE_EST", format: "currency" },
            { name: "EARNED_REVENUE", format: "currency" },
            { name: "EQUIPMENT_EST", format: "currency" },
            { name: "EQUIPMENT__ACT", format: "currency" },
            { name: "EST_VS_ACT_HRS", format: "number" },
            { name: "EST_VS_ADJ_HRS", format: "number" },
            { name: "FUEL_ACT", format: "currency" },
            { name: "FUEL_EST", format: "currency" },
            { name: "GROSS_PROFIT_ACT", format: "currency" },
            { name: "GROSS_PROFIT_EST", format: "currency" },
            { name: "INVOICED", format: "currency" },
            { name: "INVOICED_PAID", format: "currency" },
            { name: "INVOICED_UNPAID", format: "currency" },
            { name: "LABOR_COST_ACT", format: "currency" },
            { name: "LABOR_COST EST", format: "currency" },
            { name: "LABOR_COST_HR_EST", format: "number" },
            { name: "LABOR_HOURS_ACT", format: "number" },
            { name: "LABOR_HOURS_EST", format: "number" },
            { name: "LABOR_HRS_ADJ", format: "number" },
            { name: "MATERIALS_ACT", format: "currency" },
            { name: "MATERIAL_EST", format: "currency" },
            { name: "OVERBILLING", format: "currency" },
            { name: "OVER_UNDER_TOTAL", format: "currency" },
            { name: "PHASE1_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE1_LABOR_COST_EST", format: "currency" },
            { name: "PHASE1_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE1_LABOR_HRS_EST", format: "number" },
            { name: "PHASE1_MATERIALS_ACT", format: "currency" },
            { name: "PHASE1_MATERIALS_EST", format: "currency" },
            { name: "PHASE2_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE2_LABOR_COST_EST", format: "currency" },
            { name: "PHASE2_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE2_LABOR_HRS_EST", format: "number" },
            { name: "PHASE2_MATERIALS_ACT", format: "currency" },
            { name: "PHASE2_MATERIALS_EST", format: "currency" },
            { name: "PHASE3_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE3_LABOR_COST_EST", format: "currency" },
            { name: "PHASE3_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE3_LABOR_HRS_EST", format: "number" },
            { name: "PHASE3_MATERIALS_ACT", format: "currency" },
            { name: "PHASE3_MATERIALS_EST", format: "currency" },
            { name: "PHASE4_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE4_LABOR_COST_EST", format: "currency" },
            { name: "PHASE4_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE4_LABOR_HRS_EST", format: "number" },
            { name: "PHASE4_MATERIALS_ACT", format: "currency" },
            { name: "PHASE4_MATERIALS_EST", format: "currency" },
            { name: "PHASE5_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE5_LABOR_COST_EST", format: "currency" },
            { name: "PHASE5_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE5_LABOR_HRS_EST", format: "number" },
            { name: "PHASE5_MATERIALS_ACT", format: "currency" },
            { name: "PHASE5_MATERIALS_EST", format: "currency" },
            { name: "PHASE6_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE6_LABOR_COST_EST", format: "currency" },
            { name: "PHASE6_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE6_LABOR_HRS_EST", format: "number" },
            { name: "PHASE6_MATERIALS_ACT", format: "currency" },
            { name: "PHASE6_MATERIALS_EST", format: "currency" },
            { name: "PHASE7_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE7_LABOR_COST_EST", format: "currency" },
            { name: "PHASE7_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE7_LABOR_HRS_EST", format: "number" },
            { name: "PHASE7_MATERIALS_ACT", format: "currency" },
            { name: "PHASE7_MATERIALS_EST", format: "currency" },
            { name: "PHASE8_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE8_LABOR_COST_EST", format: "currency" },
            { name: "PHASE8_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE8_LABOR_HRS_EST", format: "number" },
            { name: "PHASE8_MATERIALS_ACT", format: "currency" },
            { name: "PHASE8_MATERIALS_EST", format: "currency" },
            { name: "PHASE9_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE9_LABOR_COST_EST", format: "currency" },
            { name: "PHASE9_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE9_LABOR_HRS_EST", format: "number" },
            { name: "PHASE9_MATERIALS_ACT", format: "currency" },
            { name: "PHASE9_MATERIALS_EST", format: "currency" },
            { name: "PHASE10_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE10_LABOR_COST_EST", format: "currency" },
            { name: "PHASE10_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE10_LABOR_HRS_EST", format: "number" },
            { name: "PHASE10_MATERIALS_ACT", format: "currency" },
            { name: "PHASE10_MATERIALS_EST", format: "currency" },
            { name: "PHASE11_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE11_LABOR_COST_EST", format: "currency" },
            { name: "PHASE11_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE11_LABOR_HRS_EST", format: "number" },
            { name: "PHASE11_MATERIALS_ACT", format: "currency" },
            { name: "PHASE11_MATERIALS_EST", format: "currency" },
            { name: "PHASE12_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE12_LABOR_COST_EST", format: "currency" },
            { name: "PHASE12_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE12_LABOR_HRS_EST", format: "number" },
            { name: "PHASE12_MATERIALS_ACT", format: "currency" },
            { name: "PHASE12_MATERIALS_EST", format: "currency" },
            { name: "PHASE13_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE13_LABOR_COST_EST", format: "currency" },
            { name: "PHASE13_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE13_LABOR_HRS_EST", format: "number" },
            { name: "PHASE13_MATERIALS_ACT", format: "currency" },
            { name: "PHASE13_MATERIALS_EST", format: "currency" },
            { name: "PHASE14_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE14_LABOR_COST_EST", format: "currency" },
            { name: "PHASE14_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE14_LABOR_HRS_EST", format: "number" },
            { name: "PHASE14_MATERIALS_ACT", format: "currency" },
            { name: "PHASE14_MATERIALS_EST", format: "currency" },
            { name: "PHASE15_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE15_LABOR_COST_EST", format: "currency" },
            { name: "PHASE15_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE15_LABOR_HRS_EST", format: "number" },
            { name: "PHASE15_MATERIALS_ACT", format: "currency" },
            { name: "PHASE15_MATERIALS_EST", format: "currency" },
            { name: "PHASE16_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE16_LABOR_COST_EST", format: "currency" },
            { name: "PHASE16_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE16_LABOR_HRS_EST", format: "number" },
            { name: "PHASE16_MATERIALS_ACT", format: "currency" },
            { name: "PHASE16_MATERIALS_EST", format: "currency" },
            { name: "PHASE17_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE17_LABOR_COST_EST", format: "currency" },
            { name: "PHASE17_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE17_LABOR_HRS_EST", format: "number" },
            { name: "PHASE17_MATERIALS_ACT", format: "currency" },
            { name: "PHASE17_MATERIALS_EST", format: "currency" },
            { name: "PHASE18_LABOR_COST_ACT", format: "currency" },
            { name: "PHASE18_LABOR_COST_EST", format: "currency" },
            { name: "PHASE18_LABOR_HRS_ACT", format: "number" },
            { name: "PHASE18_LABOR_HRS_EST", format: "number" },
            { name: "PHASE18_MATERIALS_ACT", format: "currency" },
            { name: "PHASE18_MATERIALS_EST", format: "currency" },
            { name: "PROFIT_PER_HOUR", format: "currency" },
            { name: "RH_ACT", format: "currency" },
            { name: "RH_EST", format: "currency" },
            { name: "TOTAL_ACT_COST", format: "currency" },
            { name: "TOTAL_EST_COST", format: "currency" },
            { name: "UNDERBILLING", format: "currency" },
          ]}
        />
      </div>
    </div>
  );
}
