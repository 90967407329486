import { useContext, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import {
  Chip,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import AppContext from "../../context/AppContext";
import { convertDate } from "../../utils/function";

import ChangeRequestDetails from "../../containers/Admin/ChangeRequests/CRDetails";

const useStyles = makeStyles({});

export default function CRTable({
  //Change Request Table
  title,
  loading,
  items,
}) {
  const classes = useStyles();
  const { changeRequests } = useContext(AppContext);
  const [rowsExpanded, setRowsExpanded] = useState([]);

  const columns = [
    { name: "CHANGE_REQUEST_ID", label: "ID" },
    {
      name: "REQUEST_TYPE",
      label: "Request Type",
    },
    {
      name: "DESCRIPTION",
      label: "Project",
    },
    {
      name: "COMMENTS",
      label: "Comment",
      options: {
        display: false,
      },
    },
    {
      name: "JOB_DESC",
      label: "Job",
    },
    {
      name: "UNIT_ID",
      label: "Unit",
    },
    {
      name: "USERNAME",
      label: "Employee",
    },
    {
      name: "TIME_REQUESTED",
      label: "Date",
      options: {
        display: false,
      },
    },
    {
      name: "APPROVED",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          return value === "0" ||
            (value === "1" && items[rowIndex].PO_CREATE_DATE) ? (
            <Chip label="Closed" variant="outlined" color="error" />
          ) : (
            <Chip label="In progress" variant="outlined" color="primary" />
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    expandableRows: true,
    rowsExpanded: rowsExpanded,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const selectedCRID = rowData[0];
      const comment = rowData[3]; //COMMENTS
      const requestedDate = rowData[7]; //TIME_REQUESTED
      const selectedCR = changeRequests.find(
        (cr) => cr.CHANGE_REQUEST_ID === selectedCRID
      );

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={4}>
                            <Typography
                              variant="span"
                              component="p"
                              align="right"
                            >
                              <strong>Comment:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="span">
                              {comment ? comment : "undefined"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={4}>
                            <Typography
                              variant="span"
                              component="p"
                              align="right"
                            >
                              <strong>Date:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="span">
                              {requestedDate ? convertDate(requestedDate) : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ChangeRequestDetails data={selectedCR} />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    },
  };

  const handleRowExpandToggle = (rowData) => {
    const expandedRowIndex = rowsExpanded.indexOf(rowData.index);

    if (expandedRowIndex === -1) {
      // Row is currently not expanded, so add it to the rowsExpanded array
      setRowsExpanded([...rowsExpanded, rowData.index]);
    } else {
      // Row is currently expanded, so remove it from the rowsExpanded array
      setRowsExpanded(rowsExpanded.filter((index) => index !== rowData.index));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={items}
          columns={columns}
          options={options}
          onRowExpansionChange={handleRowExpandToggle}
        />
      </Grid>
    </Grid>
  );
}
