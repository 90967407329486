import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../../context/AppContext";
import { getToolOrders } from "../../../services/ToolOrderService";
import { getProjects } from "../../../services/ProjectService";
import { getJobs } from "../../../services/JobService";
import { getUsers } from "../../../services/UserService";

import Autocomplete from "../../../components/Autocomplete";
import { getAllTools } from "../../../services/ToolService";

const useStyles = makeStyles({
  header: {
    backgroundColor: "lightGray",
    padding: 7,
  },
});

export default function AddToolOrder({ submitting = false, handleSubmit }) {
  const {
    allTools,
    jobs,
    projects,
    toolOrders,
    users,
    setAllTools,
    setJobs,
    setProjects,
    setUsers,
  } = useContext(AppContext);
  const classes = useStyles();
  const initialToolOrderData = {
    toolStatusType: "shop",
    toolStatus: null,
    toolName: null,
    tool: null,
    project: null,
    job: null,
  };
  const [formData, setFormData] = useState(initialToolOrderData);
  const [availableTools, setAvailableTools] = useState(allTools);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingTools, setLoadingTools] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [errors, setErrors] = useState({
    toolStatus: "",
    toolName: "",
    tool: "",
    project: "",
    job: "",
  });

  const retrieveUsers = async () => {
    try {
      setLoadingUsers(true);
      const newUsers = await getUsers();
      setUsers(newUsers);
      setLoadingUsers(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading users!");
    }
  };

  const retrieveAllTools = async () => {
    try {
      setLoadingTools(true);
      const newTools = await getAllTools();
      setAllTools(newTools);
      setLoadingTools(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading tools!");
    }
  };

  const retrieveProjects = async () => {
    try {
      setLoadingProjects(true);
      const newProjects = await getProjects();
      setProjects(newProjects);
      setLoadingProjects(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading projects!");
    }
  };

  const retrieveJobs = async () => {
    try {
      setLoadingJobs(true);
      const newJobs = await getJobs();
      setJobs(newJobs);
      setLoadingJobs(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading jobs!");
    }
  };

  useEffect(() => {
    retrieveAllTools();
    retrieveUsers();
    retrieveProjects();
    retrieveJobs();
  }, []);

  useEffect(() => {
    const _toolOrders = toolOrders.map((toolOrder) => toolOrder.ID_TAG);
    const _tools = allTools.filter(
      (tool) => !_toolOrders.includes(tool.ID_TAG)
    );

    setAvailableTools(_tools);
  }, [allTools, toolOrders]);

  const getJobsFromProject = (selectedProject) => {
    return jobs.filter(
      (job) => !selectedProject || job.PRO_ID === selectedProject.PRO_ID
    );
  };

  const getSelectedEmployeeTools = (username) => {
    return availableTools.filter((item) => item.TOOL_STATUS === username);
  };

  const getToolStatusSelectOptions = () => {
    return [
      ...new Set(
        users
          .filter((user) => user.USERNAME !== "Coombs Rd")
          .map((user) => user.USERNAME)
      ),
    ];
  };

  const getToolNameSelectOptions = () => {
    const status =
      formData.toolStatusType === "employee"
        ? formData.toolStatus
        : "Coombs Rd";

    return [
      ...new Set(
        getSelectedEmployeeTools(status).map(
          (tool) => tool.TOOL_SHORT_DESCRIPTION
        )
      ),
    ];
  };

  const getToolSelectOptions = () => {
    const status =
      formData.toolStatusType === "employee"
        ? formData.toolStatus
        : "Coombs Rd";

    return getSelectedEmployeeTools(status).filter(
      (tool) =>
        formData.toolName === "" ||
        tool.TOOL_SHORT_DESCRIPTION === formData.toolName
    );
  };

  const validateForm = () => {
    const valid = formData.tool && formData.project && formData.job;
    const errors = {
      toolStatus: formData.toolStatus ? "" : "Employee is required.",
      toolName: formData.toolName ? "" : "Tool is required.",
      tool: formData.tool ? "" : "Description is required.",
      project: formData.project ? "" : "Project is required.",
      job: formData.job ? "" : "Job is required.",
    };

    setErrors(errors);

    return valid;
  };

  const handleFormSubmit = () => {
    if (!validateForm()) return;

    handleSubmit(formData);
  };

  const handleToolStatusTypeChange = (e) => {
    setFormData({
      ...initialToolOrderData,
      toolStatusType: e.target.value,
    });
  };

  const handleToolStatusChange = (e, newValue) => {
    setErrors({
      ...errors,
      toolStatus: newValue ? "" : "Tool status is required.",
    });
    setFormData({
      ...formData,
      toolStatus: newValue,
      toolName: null,
      tool: null,
      job: null,
      project: null,
    });
  };

  const handleToolNameChange = (e, newValue) => {
    setErrors({
      ...errors,
      toolName: newValue ? "" : "Tool is required.",
    });
    setFormData({
      ...formData,
      toolName: newValue,
    });
  };

  const handleToolChange = (e, newValue) => {
    setErrors({
      ...errors,
      tool: newValue ? "" : "Description is required.",
    });
    setFormData({
      ...formData,
      tool: newValue,
    });
  };

  const handleProjectChange = (e, newValue) => {
    setErrors({
      ...errors,
      project: newValue ? "" : "Project is required.",
    });
    setFormData({
      ...formData,
      project: newValue,
    });
  };

  const handleJobChange = (e, newValue) => {
    setErrors({
      ...errors,
      job: newValue ? "" : "Job is required.",
    });
    setFormData({
      ...formData,
      job: newValue,
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={<Typography variant="h6">Add New Tool Order</Typography>}
      />
      <Divider />
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                <RadioGroup
                  row
                  value={formData.toolStatusType}
                  onChange={handleToolStatusTypeChange}
                >
                  <FormControlLabel
                    value="shop"
                    control={<Radio />}
                    label="Shop"
                  />
                  <FormControlLabel
                    value="employee"
                    control={<Radio />}
                    label="Employee"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={6}>
                {formData.toolStatusType === "employee" && (
                  <FormControl fullWidth>
                    <Autocomplete
                      label="Employee"
                      variant="outlined"
                      loading={loadingUsers}
                      disabled={loadingUsers}
                      options={getToolStatusSelectOptions()}
                      value={formData.toolStatus}
                      onChange={handleToolStatusChange}
                    />
                    {errors.toolStatus !== "" && (
                      <FormHelperText error>{errors.toolStatus}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Tool"
                    variant="outlined"
                    loading={loadingTools}
                    disabled={loadingTools}
                    options={getToolNameSelectOptions()}
                    value={formData.toolName}
                    onChange={handleToolNameChange}
                  />
                  {errors.toolName !== "" && (
                    <FormHelperText error>{errors.toolName}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Description"
                    variant="outlined"
                    loading={loadingTools}
                    disabled={loadingTools}
                    options={getToolSelectOptions()}
                    getOptionLabel={(option) => option.TOOL_DESCRIPTION}
                    value={formData.tool}
                    onChange={handleToolChange}
                  />
                  {errors.tool !== "" && (
                    <FormHelperText error>{errors.tool}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Project"
                    variant="outlined"
                    loading={loadingProjects}
                    disabled={loadingProjects}
                    options={projects || []}
                    getOptionLabel={(option) => option.DESCRIPTION}
                    value={formData.project}
                    onChange={handleProjectChange}
                  />
                  {errors.project !== "" && (
                    <FormHelperText error>{errors.project}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Job"
                    variant="outlined"
                    loading={loadingJobs}
                    disabled={loadingJobs}
                    options={getJobsFromProject(formData.project)}
                    getOptionLabel={(option) => option.JOB_DESC}
                    value={formData.job}
                    onChange={handleJobChange}
                  />
                  {errors.job !== "" && (
                    <FormHelperText error>{errors.job}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              disabled={
                submitting ||
                loadingUsers ||
                loadingTools ||
                loadingProjects ||
                loadingJobs
              }
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
