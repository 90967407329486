import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Assessment as AssessmentIcon,
  Construction as ConstructionIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Security as SecurityIcon,
  Work as WorkIcon,
} from "@mui/icons-material";

const useStyles = makeStyles({});

export default function ToolInventoryTable({
  title,
  items,
  hiddenColumns = [],
  loading,
  handleAdd,
  handleEdit,
  handleDelete,
  handleActivate,
}) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rowsChecked, setRowsChecked] = useState([]);

  const columns = [
    {
      name: "USERNAME",
      label: "USERNAME",
    },
    {
      name: "USER_ROLE",
      label: "USER ROLE",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                {value === "ADMIN" ? (
                  <SecurityIcon fontSize="small" />
                ) : value === "FOREMAN" ? (
                  <ConstructionIcon fontSize="small" />
                ) : value === "ESTIMATOR" ? (
                  <AssessmentIcon fontSize="small" />
                ) : (
                  <WorkIcon fontSize="small" />
                )}
              </Grid>
              <Grid item>
                <Typography variant="span">{value}</Typography>
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: "IS_ACTIVE",
      label: "ACTIVE STATUS",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return handleActivate ? (
            <Switch
              checked={Boolean(rowsChecked[rowIndex])}
              onChange={(e) => handleUserActivate(e, rowIndex, rowData)}
              color="primary"
            />
          ) : (
            <Chip
              size="small"
              variant="outlined"
              label={value && parseInt(value) ? "Active" : "Inactive"}
              color={value && parseInt(value) ? "primary" : "error"}
            />
          );
        },
      },
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            rowData.USER_ROLE !== "ADMIN" && (
              <Grid container alignItems="center" spacing={1}>
                {handleEdit && (
                  <Grid item>
                    <IconButton
                      color="primary"
                      onClick={(event) => handleUserEdit(event, rowData)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                )}
                {handleDelete && (
                  <Grid item>
                    <IconButton
                      color="error"
                      onClick={(event) => handleUserDelete(event, rowData)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null && items.length) {
      const _data = items.map((item) => ({ ...item, ACTIONS: null }));
      const _rowsChecked = items.map((item) => item.IS_ACTIVE);
      setData(_data);
      setRowsChecked(_rowsChecked);
    }
  }, [items]);

  const handleUserEdit = (event, rowData) => {
    event.stopPropagation();
    handleEdit(rowData);
  };

  const handleUserDelete = (event, rowData) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  const handleUserActivate = (event, rowIndex, rowData) => {
    event.stopPropagation();

    const newRowsChecked = rowsChecked.map((item, index) =>
      index === rowIndex ? !item : item
    );
    setRowsChecked(newRowsChecked);

    const newRowData = {
      ...rowData,
      IS_ACTIVE: rowsChecked[rowIndex] ? 0 : 1,
    };

    handleActivate(newRowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
