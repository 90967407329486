import {
  DeleteData,
  GetData,
  PostData,
  PutData,
} from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const checkTool = async (tool) => {
  return await PutData(API_URL + "/Tool/PUTDate", tool);
};

const checkToolUnique = async (payload) => {
  return await GetData(
    API_URL + `/Tool/GETToolIdTagUsedList?idtag2=${payload}`
  );
};

const deleteTool = async (payload) => {
  return await GetData(API_URL + `/Tool/GETDeleteTool?idtag4=${payload}`);
};

const postToolImage = async (payload) => {
  return await PostData(API_URL + `/Tool/POSTNewToolImage`, payload);
};

const deleteToolImage = async (payload) => {
  return await GetData(API_URL + `/Tool/GETDeleteToolImage?idtag5=${payload}`);
};

const getAllTools = async () => {
  return await GetData(API_URL + `/Tool/GETAll`);
};

const getTools = async (user) => {
  return await GetData(
    API_URL + `/Tool/GETEmployeeInventory?username5=${user.USERNAME}`
  );
};

// const postTool = async (tool) => {
//   return await PostData(API_URL + "/Tool/POST", tool);
// };

const postTool = async (tool) => {
  return await PostData(API_URL + "/Tool/POSTNewTool", tool); // enhanced POST method with multiple image upload
};

const postDateToCheckAll = async (payload) => {
  return await PostData(API_URL + "/Tool/PUTDateAll", payload);
};

const updateTool = async (tool) => {
  return await PutData(API_URL + "/Tool/PUT", tool);
};

export {
  checkTool,
  checkToolUnique,
  deleteTool,
  deleteToolImage,
  getAllTools,
  getTools,
  postTool,
  postDateToCheckAll,
  postToolImage,
  updateTool,
};
