import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";

import AppContext from "../../context/AppContext";
import { checkTool, getTools } from "../../services/ToolService";

import ToolInventoryTable from "../../components/CustomTable/ToolInventoryTable";

export default function EmployeeToolOrders() {
  const { tools, user, setTools } = useContext(AppContext);
  const [loadingTools, setLoadingTools] = useState(false);

  const retrieveTools = async () => {
    try {
      setLoadingTools(true);
      const newTools = await getTools(user);
      setTools(newTools);
      setLoadingTools(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading tools!");
    }
  };

  useEffect(() => {
    if (user) retrieveTools();
  }, []);

  const handleToolCheck = (rowData) => {
    checkTool(rowData)
      .then(async (res) => {
        if (res.status === 200) {
          await retrieveTools();
          toast.success("Tool updated");
        } else toast.error("Tool not updated");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Tool not updated");
      });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ToolInventoryTable
          title="Tool Inventory"
          loading={loadingTools}
          items={tools || []}
          hiddenColumns={["TOOL_STATUS"]}
          handleCheck={handleToolCheck}
        />
      </Grid>
    </Grid>
  );
}
