import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../../context/AppContext";

import ToolInventoryTable from "../../../components/CustomTable/ToolInventoryTable";
import ToolOrdersTable from "../../../components/CustomTable/ToolOrdersTable";
import AddTool from "./AddTool";
import EditTool from "./EditTool";

import {
  checkTool,
  checkToolUnique,
  deleteTool,
  deleteToolImage,
  getAllTools,
  getTools,
  postTool,
  postToolImage,
  postDateToCheckAll,
  updateTool,
} from "../../../services/ToolService";
import {
  approveToolOrder,
  getAllToolOrders,
  getReviewerToolOrders,
  getToolOrders,
} from "../../../services/ToolOrderService";

const useStyles = makeStyles({
  root: {},
});

export default function ToolManagement() {
  const classes = useStyles();
  const {
    adminToolOrders,
    tools,
    toolOrders,
    user,
    setAdminToolOrders,
    setTools,
    setToolOrders,
  } = useContext(AppContext);

  const initialToolData = {
    idTag: "",
    shortDescription: "",
    description: "",
    price: "",
    toolStatus: "",
    purchaseDate: null,
    checked: 0,
    dateLastChecked: null,
    dateToCheck: null,
    serviceStatus: null,
    images: [{ name: "", file: null }],
  };
  const [toolData, setToolData] = useState(initialToolData);
  const [dateToCheckAll, setDateToCheckAll] = useState(null);
  const [submittingDate, setSubmittingDate] = useState(false);
  const [loadingTools, setLoadingTools] = useState(false);
  const [loadingToolOrders, setLoadingToolOrders] = useState(false);
  const [isToolAdding, setIsToolAdding] = useState(false);
  const [isToolEditing, setIsToolEditing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const retrieveTools = async () => {
    try {
      setLoadingTools(true);
      const newTools =
        user.USER_ROLE === "ADMIN" ? await getAllTools() : await getTools(user);
      setTools(newTools);
      setLoadingTools(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading tools!");
    }
  };

  const retrieveToolOrders = async () => {
    try {
      setLoadingToolOrders(true);
      const newToolsOrders =
        user.USER_ROLE === "ADMIN"
          ? await getAllToolOrders()
          : user.USER_ROLE === "ESTIMATOR"
          ? await getReviewerToolOrders(user)
          : await getToolOrders(user);
      setToolOrders(newToolsOrders);
      setLoadingToolOrders(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading tool orders!");
    }
  };

  useEffect(() => {
    retrieveTools();
    retrieveToolOrders();
  }, []);

  const handleToolCreate = (data) => {
    setSubmitting(true);
    checkToolUnique(data.ID_TAG)
      .then((res) => {
        if (res.length) {
          setSubmitting(false);
          toast.error("ID Tag already exists");
        } else {
          postTool(data) // Need to work on what apis should be
            .then(async (res) => {
              if (res.status === 200) {
                setToolData(initialToolData);
                setIsToolAdding(false);
                setSubmitting(false);
                await retrieveTools();
                toast.success("New tool added!");
              } else {
                setSubmitting(false);
                toast.error("New tool not added!");
              }
            })
            .catch((err) => {
              // TODO check what happens here
              console.error(err);
              setSubmitting(false);
              toast.error("New tool not added!");
            });
        }
      })
      .catch((err) => {
        // TODO check what happens here
        console.error(err);
        setSubmitting(false);
        toast.error("Something went wrong");
      });
  };

  const handleToolUpdate = (rowData) => {
    setSubmitting(true);
    try {
      deleteToolImage(rowData.TOOL_ID).then((res) => {
        if (res.success) {
          updateTool(rowData) // Need to work on what apis should be
            .then(async (res) => {
              setSubmitting(false);
              if (res.status === 200) {
                const newImages = rowData.TOOL_IMAGES.map((image) => ({
                  TOOL_ID: rowData.TOOL_ID,
                  NAME: image.NAME,
                  FILE_STREAM: image.FILE_STREAM,
                }));
                let promises = newImages.map(async (item) => {
                  await postToolImage(item);
                });

                Promise.all(promises).then(async (results) => {
                  console.log(results);
                  setToolData(initialToolData);
                  setIsToolEditing(false);
                  await retrieveTools();
                  toast.success("Tool updated!");
                });
              } else {
                toast.error("Tool not updated!");
              }
            });
        } else {
          toast.error(res.message);
          setSubmitting(false);
        }
      });
    } catch (err) {
      console.log(err);
      setSubmitting(false);
      // TODO check what happens here (whether it exits func if err is created or if response else clause will be triggered)
      toast.error("Tool not updated!");
    }
  };

  const handleToolOrderApprove = (rowData) => {
    approveToolOrder(rowData)
      .then((res) => {
        if (res.status === 200) {
          const newAdminToolOrders = adminToolOrders.map((item) => {
            if (item.TOOL_REQUEST_ID === rowData.TOOL_REQUEST_ID) {
              return { ...item, APPROVED: rowData.APPROVED };
            }
            return item;
          });
          const newToolOrders = toolOrders.map((item) => {
            if (item.TOOL_REQUEST_ID === rowData.TOOL_REQUEST_ID) {
              return { ...item, APPROVED: rowData.APPROVED };
            }
            return item;
          });
          setAdminToolOrders(newAdminToolOrders);
          setToolOrders(newToolOrders);
          if (rowData.APPROVED) toast.success("Tool order approved!");
          else toast.success("Tool order declined!");
        } else {
          toast.error("Tool order not updated");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleToolAdd = () => {
    setIsToolAdding(true);
    setIsToolEditing(false);
  };
  const handleToolEdit = (rowData) => {
    let newImages = [{ name: "", file: null }];
    newImages = (
      rowData.IMAGE_FILE_STREAMS?.split(";").map((image, index) => ({
        name: rowData.IMAGE_NAMES?.split(";")[index],
        file: image,
      })) || []
    ).concat(newImages);

    setIsToolAdding(false);
    setIsToolEditing(true);
    setToolData({
      toolId: rowData.TOOL_ID,
      idTag: rowData.ID_TAG,
      shortDescription: rowData.TOOL_SHORT_DESCRIPTION,
      description: rowData.TOOL_DESCRIPTION,
      price: rowData.PRICE,
      toolStatus: rowData.TOOL_STATUS,
      purchaseDate: rowData.PURCHASE_DATE,
      checked: rowData.CHECKED,
      dateLastChecked: rowData.DATE_LAST_CHECKED,
      dateToCheck: rowData.DATE_TO_CHECK,
      serviceStatus: rowData.SERVICE_STATUS,
      images: newImages,
    });
  };

  const handleToolDelete = (rowData) => {
    setIsToolAdding(false);
    setIsToolEditing(false);
    deleteToolImage(rowData.TOOL_ID)
      .then((res) => {
        deleteTool(rowData.ID_TAG) // Need to work on what apis should be
          .then(async (res) => {
            setToolData(initialToolData);
            await retrieveTools();
            toast.success("Tool deleted!");
          })
          .catch((err) => {
            // TODO check what happens here
            console.error(err);
            toast.error("Tool not deleted!");
          });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Tool not deleted");
      });
  };

  const handleToolCheck = (rowData) => {
    checkTool(rowData)
      .then(async (res) => {
        if (res.status === 200) {
          await retrieveTools();
          toast.success("Tool updated");
        } else toast.error("Tool not updated");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Tool not updated");
      });
  };

  const handleDateToCheckAllChange = (value) => {
    setDateToCheckAll(value);
  };
  const handleDateToCheckSubmit = () => {
    setSubmittingDate(true);

    postDateToCheckAll({ DATE_TO_CHECK: dateToCheckAll })
      .then(async (res) => {
        setSubmittingDate(true);

        if (res.status === 200) {
          await retrieveTools();
          setDateToCheckAll(null);
          setSubmittingDate(true);
          toast.success("Updated the check date");
        } else toast.error("Date not updated");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Date not updated");
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {isToolAdding && (
          <Grid item xs={12}>
            <AddTool
              tool={initialToolData}
              submitting={submitting}
              handleSubmit={handleToolCreate}
            />
          </Grid>
        )}
        {isToolEditing && (
          <Grid item xs={12}>
            <EditTool
              tool={toolData}
              submitting={submitting}
              handleSubmit={handleToolUpdate}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={4}
                  >
                    <Grid item>
                      <Typography variant="body2">
                        Date to check for all tools
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControl fullWidth>
                        <DatePicker
                          label="Select a date"
                          value={
                            dateToCheckAll ? new Date(dateToCheckAll) : null
                          }
                          onChange={handleDateToCheckAllChange}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                size="small"
                                placeholder="Select a date"
                                error={submittingDate && !dateToCheckAll}
                                fullWidth
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleDateToCheckSubmit}
                        disabled={submittingDate || !dateToCheckAll}
                      >
                        Set
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ToolInventoryTable
                    title="Tool Inventory"
                    loading={loadingTools}
                    items={tools}
                    handleAdd={handleToolAdd}
                    handleEdit={handleToolEdit}
                    handleDelete={handleToolDelete}
                    handleCheck={handleToolCheck}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <ToolOrdersTable
                title="Tool Orders"
                loading={loadingToolOrders}
                items={adminToolOrders}
                handleApprove={handleToolOrderApprove}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
