import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getAllChangeRequests = async () => {
  return await GetData(API_URL + `/ChangeRequest/GETAll`);
};

const getChangeRequest = async (crId) => {
  return await GetData(API_URL + `/ChangeRequest/GETChangeRequest/${crId}`);
};

const getChangeRequests = async (user) => {
  return await GetData(API_URL + `/ChangeRequest/GET/${user.USERNAME}`);
};

const getCRLabor = async (crID) => {
  return await GetData(API_URL + `/ChangeRequest/GETLabor/${crID}`);
};

const deleteCRLabor = async (crLaborId) => {
  return await GetData(
    API_URL + `/ChangeRequest/GETDeleteCRLabor/${crLaborId}`
  );
};

const deleteCRMaterial = async (crMaterialId) => {
  return await GetData(
    API_URL + `/ChangeRequest/GETDeleteCRMaterial/${crMaterialId}`
  );
};

const getLastChangeRequestID = async () => {
  return await GetData(API_URL + `/ChangeRequest/GETChangeRequestID`);
};

const getCRMaterials = async (crID) => {
  return await GetData(API_URL + `/ChangeRequest/GETMaterials/${crID}`);
};

const getJobSuppliers = async (jobId) => {
  return await GetData(API_URL + `/Material/GETSuppliers/${jobId}`);
};

const getSupplierMaterials = async (jobId, supplierId) => {
  return await GetData(
    API_URL + `/Material/GETSupplierMaterials/${jobId}/${supplierId}`
  );
};

const getReviewerChangeRequests = async (user) => {
  return await GetData(API_URL + `/ChangeRequest/GETReviewer/${user.USERNAME}`);
};

const postChangeRequest = async (postData) => {
  return await PostData(API_URL + "/ChangeRequest/POST", postData);
};

const postCRLabor = async (data) => {
  return await PostData(API_URL + "/ChangeRequest/POSTCRLabor", data);
};

const putCRLabor = async (data) => {
  return await PostData(API_URL + "/ChangeRequest/PUTCRLabor", data);
};

const postCRMaterial = async (data) => {
  return await PostData(API_URL + "/ChangeRequest/POSTCRMaterial", data);
};

const putCRMaterial = async (data) => {
  return await PostData(API_URL + "/ChangeRequest/PUTCRMaterial", data);
};

const putChangeRequest = async (params) => {
  return await PutData(API_URL + "/ChangeRequest/PUT", params);
};
const putChangeRequestSignature = async (params) => {
  return await PutData(API_URL + "/ChangeRequest/PUTSignature", params);
};

export {
  getAllChangeRequests,
  getChangeRequest,
  getChangeRequests,
  getCRLabor,
  deleteCRLabor,
  deleteCRMaterial,
  getCRMaterials,
  getJobSuppliers,
  getLastChangeRequestID,
  getSupplierMaterials,
  getReviewerChangeRequests,
  postChangeRequest,
  postCRLabor,
  putCRLabor,
  postCRMaterial,
  putCRMaterial,
  putChangeRequest,
  putChangeRequestSignature,
};
