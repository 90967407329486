import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

import AppContext from "../../../context/AppContext";
import {
  getAllChangeRequests,
  getChangeRequests,
  getReviewerChangeRequests,
} from "../../../services/ChangeRequestService";

import CRTable from "../../../components/CustomTable/CRTable";

const useStyles = makeStyles({
  root: {},
});

export default function ChangeRequests() {
  const classes = useStyles();
  const { changeRequests, user, setChangeRequests } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] =
    useState("in-progress");

  const retrieveChangeRequests = async () => {
    try {
      setLoading(true);
      const crs =
        user.USER_ROLE === "ADMIN"
          ? await getAllChangeRequests()
          : user.USER_ROLE === "ESTIMATOR"
          ? await getReviewerChangeRequests(user)
          : await getChangeRequests(user);

      crs.sort((a, b) => b.CHANGE_REQUEST_ID - a.CHANGE_REQUEST_ID);

      setChangeRequests([...crs]);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpect error occurs while loading change requests!");
    }
  };

  useEffect(() => {
    retrieveChangeRequests();
  }, []);

  const handleStatusFilterChange = (e) => {
    setSelectedStatusFilter(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ToggleButtonGroup
                size="small"
                aria-label="Change Request Status"
                value={selectedStatusFilter}
                onChange={handleStatusFilterChange}
                exclusive
              >
                <ToggleButton value="">All</ToggleButton>
                <ToggleButton color="primary" value="in-progress">
                  In progress
                </ToggleButton>
                <ToggleButton color="error" value="closed">
                  Closed
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CRTable
            title="Change Requests"
            loading={loading}
            items={changeRequests.filter(
              (item) =>
                selectedStatusFilter === "" ||
                (selectedStatusFilter === "in-progress" &&
                  (item.APPROVED === null ||
                    (item.APPROVED === "1" && item.PO_CREATE_DATE === null))) ||
                (selectedStatusFilter === "closed" &&
                  (item.APPROVED === "0" ||
                    (item.APPROVED === "1" && item.PO_CREATE_DATE)))
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
