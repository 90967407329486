import { Grid, Modal } from "@mui/material";

import SetOrderDeliveryDateForm from "./SetOrderDeliveryDateForm";

const SetOrderDeliveryDateModal = ({
  open,
  loading,
  orders,
  formData,
  handleSave,
  handleClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        m: "auto",
        width: 480,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <SetOrderDeliveryDateForm
            loading={loading}
            orders={orders}
            values={formData}
            handleSubmit={handleSave}
            handleCancel={handleClose}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default SetOrderDeliveryDateModal;
