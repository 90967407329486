import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

import { convertDate } from "../../utils/function";

import FileUploadCard from "../FileUploadCard";

export default function DocumentsTable({
  title,
  loading,
  items,
  handleAdd,
  handleEdit,
}) {
  const [data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isEditing, setIsEditing] = useState([]);

  const columns = [
    {
      name: "DOCUMENT_ID",
      label: "ID",
    },
    {
      name: "STREAM_ID",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "FILE_STREAM",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "PROJECT_DESCRIPTION",
      label: "Project",
    },
    {
      name: "JOB_DESCRIPTION",
      label: "Job",
    },
    {
      name: "TITLE",
      label: "Title",
    },
    {
      name: "DESCRIPTION",
      label: "Description",
    },
    {
      name: "SUPPLIER_USERNAME",
      label: "Supplier",
    },
    {
      name: "CREATE_DATE",
      label: "Created at",
      options: {
        display: false,
      },
    },
    {
      name: "LAST_UPDATED",
      label: "Updated at",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const rowIndex = rowMeta.rowIndex;
      const fileName = rowData[2];
      const file = rowData[3];
      const supplier = rowData[8];
      const createdDate = rowData[9];
      const updatedDate = rowData[10];

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              variant="span"
                              component="p"
                              align="right"
                            >
                              <strong>Supplier:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="span">
                              {supplier ? supplier : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              variant="span"
                              component="p"
                              align="right"
                            >
                              <strong>Created at:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="span">
                              {createdDate ? convertDate(createdDate) : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography
                              variant="span"
                              component="p"
                              align="right"
                            >
                              <strong>Updated at:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="span">
                              {updatedDate ? convertDate(updatedDate) : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {!isEditing[rowIndex] && (
                        <Grid item xs={12}>
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={6}>
                              <Typography
                                variant="span"
                                component="p"
                                align="right"
                              >
                                <strong>Document:</strong>
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="text"
                                onClick={() =>
                                  handleDocumentDownload(fileName, file)
                                }
                              >
                                {fileName}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            {isEditing[rowIndex] && (
                              <FileUploadCard
                                file={{
                                  name: selectedFile.name,
                                  size: atob(selectedFile.FILE_STREAM).length,
                                  file: selectedFile.FILE_STREAM,
                                }}
                                handleChange={handleFileChange}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            {isEditing[rowIndex] ? (
                              <Grid container spacing={1}>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      handleRowEditCancel(rowIndex)
                                    }
                                  >
                                    Cancel
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    onClick={() => handleRowEditSave(rowIndex)}
                                  >
                                    Save
                                  </Button>
                                </Grid>
                              </Grid>
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={() => handleRowEdit(rowIndex)}
                              >
                                Edit
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    },
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null) {
      setData([...items]);
      setIsEditing(Array(items.length).fill(false));
    }
  }, [items]);

  const handleRowEdit = (rowIndex) => {
    const newIsEditing = [...isEditing];
    newIsEditing[rowIndex] = true;
    setIsEditing(newIsEditing);
    setSelectedFile({
      STREAM_ID: data[rowIndex].STREAM_ID,
      name: data[rowIndex].name,
      FILE_STREAM: data[rowIndex].FILE_STREAM,
    });
  };

  const handleRowEditSave = (rowIndex) => {
    const newIsEditing = [...isEditing];
    newIsEditing[rowIndex] = false;
    setIsEditing(newIsEditing);
    handleEdit(selectedFile);
  };
  const handleRowEditCancel = (rowIndex) => {
    const newIsEditing = [...isEditing];
    newIsEditing[rowIndex] = false;
    setIsEditing(newIsEditing);
  };

  const handleFileChange = (fileData) => {
    if (!fileData) return;

    setSelectedFile({
      ...selectedFile,
      name: fileData.name,
      FILE_STREAM: fileData.file,
    });
  };

  const handleDocumentDownload = (fileName, fileStream) => {
    // Decode base64 string to binary data
    const binaryData = atob(fileStream);

    // Create a Uint8Array from the binary data
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([byteArray], { type: "application/octet-stream" });

    // Create a download link
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
