import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getReceipts = async () => {
  return await GetData(API_URL + `/Receipts/GET`);
};

const postReceipt = async (postData) => {
  return await PostData(API_URL + "/Receipts/POST", postData);
};

export { getReceipts, postReceipt };
