import { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Mail as MailIcon } from "@mui/icons-material";

import AppContext from "../../context/AppContext";
import config from "../../config";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "orange",
  },
  title: {
    flexGrow: 1,
  },
  navLink: {
    textDecoration: "none",
    color: "inherit",
  },
  navButtons: {
    marginLeft: "2%",
    width: "100%",
    float: "left",
  },
  // Want it so that the button stays colored after clicked and does not
  // go back to normal after 'focus' moves to another clicked item
  navButton: {
    color: "white",
    // backgroundColor: "white",
  },
  badge: {
    color: "white",
  },
  subAppBar: {
    backgroundColor: "black",
  },
});

export default function Header() {
  const classes = useStyles();
  let history = useHistory();
  let { messages, user, setUser, userAuthenticated, setUserAuthenticated } =
    useContext(AppContext);
  const { PAGE_PERMISSIONS } = config;

  const handleNavigation = (route) => {
    history.push(`/${route}`);
  };

  const handleLogout = () => {
    setUserAuthenticated(false);
    setUser(null);
    history.push("/login");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Link to="/" className={classes.navLink}>
            <Typography variant="h6" className={classes.title}>
              Horizon
            </Typography>
          </Link>
          {userAuthenticated ? (
            <div className={classes.navButtons}>
              {PAGE_PERMISSIONS[user.USER_ROLE].ADMIN && (
                <Button
                  onClick={() => handleNavigation("admin")}
                  className={classes.navButton}
                  variant="text"
                >
                  Admin
                </Button>
              )}
              {PAGE_PERMISSIONS[user.USER_ROLE].DASHBOARD && (
                <Button
                  onClick={() => handleNavigation("dashboard")}
                  className={classes.navButton}
                  variant="text"
                >
                  Dashboard
                </Button>
              )}
              {PAGE_PERMISSIONS[user.USER_ROLE].CHANGE_REQUSET && (
                <Button
                  onClick={() => handleNavigation("changeRequest")}
                  className={classes.navButton}
                  variant="text"
                >
                  Change Request
                </Button>
              )}
              {PAGE_PERMISSIONS[user.USER_ROLE].ORDERS && (
                <Button
                  onClick={() => handleNavigation("orders")}
                  className={classes.navButton}
                  variant="text"
                >
                  Orders
                </Button>
              )}
              {/* <Button
                onClick={() => handleNavigation("ordersSummary")}
                className={classes.navButton}
                variant="text"
              >
                Orders Summary
              </Button> */}
              {PAGE_PERMISSIONS[user.USER_ROLE].TOOLS && (
                <Button
                  onClick={() => handleNavigation("tools")}
                  className={classes.navButton}
                  variant="text"
                >
                  Tools
                </Button>
              )}
              {/* <Button
                onClick={() => handleNavigation("inventory")}
                className={classes.navButton}
                variant="text"
              >
                Inventory
              </Button> */}
              {PAGE_PERMISSIONS[user.USER_ROLE].DOCUMENTS && (
                <Button
                  onClick={() => handleNavigation("documents")}
                  className={classes.navButton}
                  variant="text"
                >
                  Documents
                </Button>
              )}
              {PAGE_PERMISSIONS[user.USER_ROLE].REPORTS && (
                <Button
                  onClick={() => handleNavigation("reports")}
                  className={classes.navButton}
                  variant="text"
                >
                  Reports
                </Button>
              )}
              {PAGE_PERMISSIONS[user.USER_ROLE].RECEIPTS && (
                <Button
                  onClick={() => handleNavigation("receipts")}
                  className={classes.navButton}
                  variant="text"
                >
                  Receipts
                </Button>
              )}
              {PAGE_PERMISSIONS[user.USER_ROLE].HELP && (
                <Button
                  onClick={() => handleNavigation("help")}
                  className={classes.navButton}
                  variant="text"
                >
                  Help
                </Button>
              )}
              {PAGE_PERMISSIONS[user.USER_ROLE].CONTACT_US && (
                <Button
                  onClick={() => handleNavigation("contactus")}
                  className={classes.navButton}
                  variant="text"
                >
                  Contact Us
                </Button>
              )}
            </div>
          ) : null}
          {userAuthenticated ? (
            <>
              <IconButton onClick={() => handleNavigation("")}>
                <Badge
                  className={classes.badge}
                  color="secondary"
                  badgeContent={messages?.length || 0}
                >
                  <MailIcon />
                </Badge>
              </IconButton>
              <Button
                className={classes.navButton}
                onClick={handleLogout}
                variant="text"
              >
                Logout
              </Button>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
    </div>
  );
}
