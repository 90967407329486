import { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { format } from "fecha";
import { makeStyles } from "@mui/styles";
import { Button, FormControl } from "@mui/material";
import { Build as BuildIcon } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";

import ToolOrdersTable from "../../components/CustomTable/ToolOrdersTable";
import FuzzySearchSelect from "../../components/FuzzySearchSelect";

import AppContext from "../../context/AppContext";

import { PostData } from "../../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
  },
  header: {
    backgroundColor: "lightGray",
    padding: 7,
  },
  formDiv: {
    display: "flex",
    flexFlow: "row wrap",
  },
  selector: {
    width: "20%",
    margin: 10,
  },
});

const toolColumnTitles = [
  "Tool",
  "Project",
  "Job Site",
  "                                ", // Delete Column
];

const accessOrder = ["tool", "project", "job"];

export default function Inventory() {
  const classes = useStyles();
  let appContext = useContext(AppContext);

  const [values, setValues] = useState({
    job: "",
    project: "",
    tool: "",
    filteredToolOrders: [],
    submitKey: 0,
    alert: null,
  });

  const [fetchedData, setFetchedData] = useState({
    jobs: null,
    projects: null,
    tools: null,
    toolOrders: null,
  });

  useEffect(() => {
    setFetchedData({
      ...fetchedData,
      projects: appContext.projects,
      tools: appContext.tools,
      toolOrders: appContext.toolOrders,
    });
  }, [appContext]);

  useEffect(() => {
    let filteredToolOrders = fetchedData.toolOrders;
    if (filteredToolOrders === null) {
      return;
    }

    if (fetchedData.projects === null) {
      return;
    }
    if (
      values.project === null ||
      values.project === undefined ||
      values.project === ""
    ) {
      setValues({
        ...values,
        job: null,
        submitKey: values.submitKey === 1 ? 0 : 1,
      });
      setFetchedData({
        ...fetchedData,
        jobs: null,
      });
    }
    let jobData = appContext.jobs.filter(
      (job) => job.PRO_ID === values.project.PRO_ID
    );
    filteredToolOrders = filteredToolOrders.filter(
      (toolOrder) => toolOrder.PRO_ID === values.project.PRO_ID
    );
    setFetchedData({
      ...fetchedData,
      jobs: jobData,
    });
    setValues({
      ...values,
      filteredToolOrders: filteredToolOrders,
    });
  }, [values.project]);

  useEffect(() => {
    let filteredToolOrders = values.filteredToolOrders;
    if (filteredToolOrders === null) {
      return;
    }

    if (
      !(values.job === null || values.job === undefined || values.job === "")
    ) {
      filteredToolOrders = filteredToolOrders.filter(
        (to) => to.JOB_ID === values.job.JOB_ID
      );
    }

    if (
      !(values.tool === null || values.tool === undefined || values.tool === "")
    ) {
      filteredToolOrders = filteredToolOrders.filter(
        (to) => to.ID_TAG === values.tool.ID_TAG
      );
    }

    setValues({
      ...values,
      filteredToolOrders: filteredToolOrders,
    });
  }, [fetchedData, values.job, values.tool]);

  useEffect(() => {
    if (values.alert === null) {
      return;
    }
    toast[values.alert.type](values.alert.message);
    setTimeout(() => {
      setValues({
        ...values,
        alert: null,
      });
    }, 1000);
  }, [values.alert]);

  return (
    <div className={classes.root}>
      <h2 className={classes.header}>Tool Inventory</h2>
      <div className={classes.formDiv}>
        <FormControl className={classes.selector}>
          <FuzzySearchSelect
            label="Project"
            valueLabel="project"
            values={values}
            setValues={setValues}
            optionLabel="DESCRIPTION"
            options={fetchedData.projects}
          />
        </FormControl>
        <FormControl className={classes.selector}>
          <FuzzySearchSelect
            label="Job Description"
            valueLabel="job"
            values={values}
            setValues={setValues}
            optionLabel="JOB_DESC"
            options={fetchedData.jobs}
          />
        </FormControl>
        <FormControl className={classes.selector}>
          <FuzzySearchSelect
            label="Tool"
            valueLabel="tool"
            values={values}
            setValues={setValues}
            optionLabel="TOOL_DESCRIPTION"
            options={fetchedData.tools}
          />
        </FormControl>
      </div>
      <div>
        <ToolOrdersTable
          items={values.filteredToolOrders}
          values={values}
          setValues={setValues}
        />
      </div>
    </div>
  );
}
