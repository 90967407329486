import {
  Avatar,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { MonetizationOn as MonetizationOnIcon } from "@mui/icons-material";

const Summary = ({ data, direction = "vertical" }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <List sx={{ display: direction === "horizontal" && "flex" }}>
              {data !== null &&
                Object.keys(data).map((key, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar>
                        <MonetizationOnIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`$${parseFloat(data[key].value).toFixed(2)}`}
                      secondary={data[key].title}
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Summary;
