import { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
} from "@mui/material";
import { toast } from "react-toastify";

const placeholderImage = "/assets/images/image-upload-placeholder.png";

const ImageUploadCard = ({
  image = null,
  placeholder = placeholderImage,
  handleChange,
}) => {
  const fileUploadRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(image || null);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(!!image);
    setSelectedImage(image);
  }, [image]);

  const handleUploadClick = () => {
    fileUploadRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 8 * 1024 * 1024; // 8MB

    if (fileSizeInBytes > maxSizeInBytes) {
      toast.error("File size exceeds the maximum limit of 8MB.");
      return;
    }

    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      const binaryString = reader.result;
      const base64String = btoa(binaryString);

      const imageData = {
        name: file.name,
        type: file.type,
        file: base64String,
      };
      setSelected(true);
      setSelectedImage(base64String);
      handleChange(imageData);
    };
  };

  const handleImageRemove = () => {
    setSelected(false);
    setSelectedImage(null);
    handleChange({ name: "", type: "", file: null });
    fileUploadRef.current.value = ""; // Reset the file input value
  };

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          image={
            selectedImage
              ? `data:image/png;base64,${selectedImage}`
              : placeholder
          }
          title="preview image"
          sx={{ height: 240 }}
        />
      </CardActionArea>
      {handleChange && (
        <CardActions>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>
              <>
                {selected ? (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={handleImageRemove}
                  >
                    Remove Image
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={handleUploadClick}
                  >
                    Upload Image
                  </Button>
                )}
                <input
                  ref={fileUploadRef}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageUpload}
                />
              </>
            </Grid>
          </Grid>
        </CardActions>
      )}
    </Card>
  );
};

export default ImageUploadCard;
