import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { getEmailData, getJobAddress } from "../../services/EmailService";
import { convertDate } from "../../utils/function";

import {
  getChangeRequest,
  getCRLabor,
  getCRMaterials,
  putChangeRequestSignature,
} from "../../services/ChangeRequestService";

import CRLaborTable from "../../components/CustomTable/CRLaborTable";
import CRMaterialsTable from "../../components/CustomTable/CRMaterialsTable";
import CRSummary from "../Admin/ChangeRequests/CRSummary";

const useStyles = makeStyles({
  root: {},
  navLink: {
    textDecoration: "none",
  },
});

const defaultTaxRate = 0.085;
const websiteURL = "https://horizoncarpentry.com";

export default function POPreview() {
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const to = params.get("to");
  const crId = params.get("crid"); // CHANGE_REQUEST_ID
  const supplierId = params.get("sid"); //SUPPLIER_ID
  const jobId = params.get("jid"); //JOB_ID
  const signature = params.get("ec"); //EMAIL_STATUS
  const customerEmail = params.get("email"); //CUSTOMER_EMAIL_ADDRESS
  const markupValue = params.get("m");
  const [CRData, setCRData] = useState(null);
  const [CRLabor, setCRLabor] = useState([]);
  const [CRMaterials, setCRMaterials] = useState([]);
  const [emailData, setEmailData] = useState(null);
  const [CRSummaryData, setCRSummaryData] = useState(null);
  const [taxRate, setTaxRate] = useState(defaultTaxRate);
  const [markup, setMarkup] = useState(markupValue);
  const [loadingCRData, setLoadingCRData] = useState(false);
  const [loadingEmailData, setLoadingEmailData] = useState(false);
  const [loadingCRLabor, setLoadingCRLabor] = useState(false);
  const [loadingCRMaterials, setLoadingCRMaterials] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const retrieveEmailData = useCallback(async (JID, SID) => {
    try {
      setLoadingEmailData(true);
      getEmailData(JID, SID).then((res) => {
        const newEmailData = res[0];
        console.log("email data: ", newEmailData);

        setLoadingEmailData(false);
        setEmailData(newEmailData);
        if (newEmailData?.TAX_RATE) setTaxRate(newEmailData?.TAX_RATE);
      });
    } catch (e) {
      console.error(e);
      setLoadingEmailData(false);
    }
  }, []);

  const retrieveJobAddress = useCallback(async (JID, SID) => {
    try {
      setLoadingEmailData(true);
      getJobAddress(JID).then((res) => {
        const newJobAddressData = res[0];
        console.log(newJobAddressData);

        setEmailData(newJobAddressData);
      });
    } catch (e) {
      console.error(e);
      setLoadingEmailData(false);
    }
  }, []);

  const retrieveCRData = useCallback(async (CRID) => {
    try {
      setLoadingCRData(true);
      getChangeRequest(CRID).then((res) => {
        console.log(res);
        if (res.length) {
          const newCRData = res[0];
          console.log("cr data: ", newCRData);

          setCRData(newCRData);
          if (markupValue) setMarkup(markupValue);
          else if (newCRData.MARKUP) setMarkup(newCRData.MARKUP);
        }
      });
    } catch (e) {
      console.error(e);
      setLoadingCRData(false);
    }
  }, []);

  const retrieveCRLabor = useCallback(async (CRID) => {
    try {
      setLoadingCRLabor(true);
      getCRLabor(CRID).then((res) => {
        setLoadingCRLabor(false);
        setCRLabor(res);
      });
    } catch (e) {
      console.error(e);
      setLoadingCRLabor(false);
    }
  }, []);

  const retrieveCRMaterials = useCallback(async (CRID) => {
    try {
      setLoadingCRMaterials(true);
      getCRMaterials(CRID).then((res) => {
        console.log("materials: ", res);
        setLoadingCRMaterials(false);
        setCRMaterials(res);
      });
    } catch (e) {
      console.error(e);
      setLoadingCRMaterials(false);
    }
  }, []);

  useEffect(() => {
    if (jobId) {
      if (supplierId) retrieveEmailData(jobId, supplierId);
      else {
        const res = retrieveJobAddress(jobId);
      }
    }
    if (crId) {
      retrieveCRData(crId);
      if (to === "customer") retrieveCRLabor(crId);
      retrieveCRMaterials(crId);
    }
  }, [to, crId, jobId, supplierId]);

  useEffect(() => {
    let subtotal = 0;
    let tax = 0;
    let total = 0;
    let laborTotal = 0;
    let materialTotal = 0;

    if (CRLabor !== null) {
      laborTotal += CRLabor.reduce(
        (a, crItem) => a + (Number(crItem.LHOURS) * Number(crItem.LCOST) || 0),
        0
      );
    }
    if (CRMaterials !== null) {
      materialTotal += CRMaterials.reduce(
        (a, crItem) =>
          a +
          (Number(crItem.QUANTITY) *
            Number(crItem.COST + crItem.COST * markup) || 0),
        0
      );
    }
    subtotal = laborTotal + materialTotal;
    tax = materialTotal * taxRate; // will be some tax multiplier later identified
    total = subtotal + tax;

    setCRSummaryData({
      subtotal: { title: "Subtotal", value: subtotal },
      tax: { title: `Tax (${taxRate * 100}%)`, value: tax },
      total: { title: "Total", value: total },
    });
  }, [CRMaterials, CRLabor, taxRate, markup]);

  const handleSubmit = (action) => {
    setSubmitted(true);
    const params = {
      CHANGE_REQUEST_ID: crId,
      CUSTOMER_SIGNATURE: action,
    };

    putChangeRequestSignature(params)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("Entry updated!");
        } else {
          toast.error("Entry not updated!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${err}`);
      });
  };

  const displayTopRightArea = () => {
    const defaultText = "PURCHASE ORDER";
    const requestType = CRData?.REQUEST_TYPE;
    const displayText =
      to === "customer"
        ? defaultText
        : `${
            requestType === "Material Overage"
              ? "Material Credit"
              : requestType === "Backcharge"
              ? "Backcharge"
              : defaultText
          }`;

    return displayText;
  };

  return (
    <Container component="main">
      <Box sx={{ mb: 10, mt: 10 }}>
        <Card>
          <CardHeader
            title={
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <a
                    className={classes.navLink}
                    href={websiteURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="h5" color="white">
                      HORIZON CARPENTRY
                    </Typography>
                  </a>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="white">
                    {displayTopRightArea()}
                  </Typography>
                </Grid>
              </Grid>
            }
            sx={{ backgroundColor: "orange" }}
          />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" spacing={4}>
                  <Grid item>
                    <Typography variant="body2">PO BOX 5874</Typography>
                    <Typography variant="body2">
                      Elgin, Illnois 60121
                    </Typography>
                    <Typography variant="body2">
                      <strong>Office:</strong> 815-572-5656
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fax:</strong> 815-572-5011
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>Number</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Start Date</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Delivery Date</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{crId}</TableCell>
                            <TableCell>
                              {emailData?.RELEASE_DATE
                                ? convertDate(emailData?.RELEASE_DATE)
                                : ""}
                            </TableCell>
                            <TableCell>
                              {CRData?.PO_CREATE_DATE
                                ? convertDate(CRData?.PO_CREATE_DATE)
                                : ""}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Card sx={{ height: "100%" }}>
                      <CardHeader
                        title={
                          <Typography variant="h6">
                            {`To: ${
                              to === "customer"
                                ? customerEmail ||
                                  CRData?.CUSTOMER_EMAIL_ADDRESS ||
                                  ""
                                : ""
                            }`}
                          </Typography>
                        }
                      />
                      <Divider />
                      <CardContent>
                        {to === "customer" ? (
                          <></>
                        ) : (
                          <>
                            <Typography variant="body2">
                              {emailData?.NAME}
                            </Typography>
                            <Typography variant="body2">
                              {emailData?.CONTACT}
                            </Typography>
                            <Typography variant="body2">
                              {emailData?.ADDRESS}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Phone: </strong> {emailData?.PHONE}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Fax: </strong> {emailData?.FAX}
                            </Typography>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ height: "100%" }}>
                      <CardHeader
                        title={<Typography variant="h6">Ship To:</Typography>}
                      />
                      <Divider />
                      <CardContent>
                        <Typography variant="body2">
                          <strong>Project:</strong> {emailData?.DESCRIPTION}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Bld/Lot:</strong> {emailData?.JOB_DESC}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Job Id:</strong> {jobId}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Address/Unit:</strong>{" "}
                          {emailData?.DESCRIPTION1}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Builder:</strong> {emailData?.BUILDER}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    {/* <Typography variant="body2">
                      <strong>Phase: </strong>
                      {emailData?.PHASE}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Load: </strong>
                      {emailData?.LOAD}
                    </Typography> */}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <strong>Model: </strong>
                      {emailData?.MODEL}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Elevation: </strong>
                      {emailData?.ELEVATION}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Type: </strong>
                      {emailData?.SHORT_DESC}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CRSummary direction="horizontal" data={CRSummaryData} />
              </Grid>
              {to === "customer" && (
                <Grid item xs={12}>
                  <CRLaborTable title="Labor" items={CRLabor} />
                </Grid>
              )}
              <Grid item xs={12}>
                <CRMaterialsTable markup={markup} items={CRMaterials} />
              </Grid>
              {to === "customer" &&
                (signature === "SEND_EMAIL_WITH_SIGNATURE" ||
                  CRData?.EMAIL_STATUS === "SEND_EMAIL_WITH_SIGNATURE") && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ mt: 8 }}>
                        <Grid container alignItems="center" spacing={4}>
                          <Grid item>
                            <Button
                              variant="contained"
                              onClick={() => handleSubmit("ACCEPT")}
                              disabled={
                                submitted || !!CRData?.CUSTOMER_SIGNATURE
                              }
                            >
                              ACCEPT
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              onClick={() => handleSubmit("DENY")}
                              disabled={
                                submitted || !!CRData?.CUSTOMER_SIGNATURE
                              }
                            >
                              DENY
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2" color="grey">
                              *by clicking ACCEPT, you agree to the
                              aforementioned order.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    {CRData?.CUSTOMER_SIGNATURE &&
                      CRData?.CUSTOMER_APPROVED_DATE && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle2" color="grey">
                            {`This request was ${
                              CRData?.CUSTOMER_SIGNATURE === "ACCEPT"
                                ? "accepted"
                                : "denied"
                            } on ${convertDate(
                              new Date(CRData.CUSTOMER_APPROVED_DATE)
                            )}`}
                          </Typography>
                        </Grid>
                      )}
                  </>
                )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
