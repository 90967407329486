import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppContext from "../../context/AppContext";

import ForemanHours from "./ForemanHours";
import HoursSummary from "./HoursSummary";
import TrimHours from "./TrimHours";
import WIP from "./WIP";

import SubHeader from "../../components/SubHeader";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
  },
  mainDiv: {
    margin: "5rem auto",
  },
});

const adminSubHeaderItems = [
  { value: "wip", text: "WIP" },
  { value: "hoursSummary", text: "Hours Summary" },
  { value: "foremanHours", text: "Foreman Hours" },
  { value: "trimHours", text: "Trim Hours" },
];
const foremanSubHeaderItems = [
  { value: "foremanHours", text: "Foreman Hours" },
];

export default function Reports() {
  const classes = useStyles();
  const { user } = useContext(AppContext);

  const [selectedSubHeaderItem, setSelectedSubHeaderItem] = useState(null);
  const [subHeaderItems, setSubHeaderItems] = useState([]);

  useEffect(() => {
    if (!user) return;

    if (user.USER_ROLE === "ADMIN") {
      setSubHeaderItems(adminSubHeaderItems);
      setSelectedSubHeaderItem(adminSubHeaderItems[0].value);
    } else if (user.USER_ROLE === "FOREMAN") {
      setSubHeaderItems(foremanSubHeaderItems);
      setSelectedSubHeaderItem(foremanSubHeaderItems[0].value);
    }
  }, [user]);

  const handleSubHeaderItemSelect = (selected) => {
    setSelectedSubHeaderItem(selected);
  };

  return (
    <div>
      <SubHeader
        items={subHeaderItems}
        handleSelect={handleSubHeaderItemSelect}
        selected={selectedSubHeaderItem}
      />
      <div className={classes.root}>
        <div className={classes.mainDiv}>
          {selectedSubHeaderItem === "wip" ? (
            <WIP />
          ) : selectedSubHeaderItem === "hoursSummary" ? (
            <HoursSummary />
          ) : selectedSubHeaderItem === "foremanHours" ? (
            <ForemanHours />
          ) : selectedSubHeaderItem === "trimHours" ? (
            <TrimHours />
          ) : user?.USER_ROLE === "ADMIN" ? (
            <WIP />
          ) : user?.USER_ROLE === "FOREMAN" ? (
            <ForemanHours />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
