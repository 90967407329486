import { makeStyles } from "@mui/styles";
import { AppBar, Toolbar } from "@mui/material";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  navButtons: {
    marginLeft: "5%",
    width: "100%",
    float: "left",
  },
  // Want it so that the button stays colored after clicked and does not
  // go back to normal after 'focus' moves to another clicked item
  navButton: {
    border: "none",
    backgroundColor: "black",
    color: "white",
    padding: 5,
    marginLeft: 5,
    marginRight: 5,
    "&:hover": {
      backgroundColor: "gray",
      cursor: "pointer",
    },
  },
  badge: {
    color: "white",
  },
  appBar: {
    backgroundColor: "black",
  },
});

export default function SubHeader({
  items,
  handleSelect,
  selected = items[0].value,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar variant="dense">
          <div className={classes.navButtons}>
            {items.map((item, index) => (
              <button
                key={index}
                className={classes.navButton}
                onClick={() => handleSelect(item.value)}
              >
                {item.text}
              </button>
            ))}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
