import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getEmailData = async (jobId, supplierId) => {
  return await GetData(
    API_URL + `/Email/GET/${jobId}${supplierId ? `/${supplierId}` : ""}`
  );
};

const getJobAddress = async (jobId) => {
  return await GetData(API_URL + `/Email/GETJobAddress/${jobId}`);
};

export { getEmailData, getJobAddress };
