import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import AppContext from "../../../../context/AppContext";
import { getEmailData } from "../../../../services/EmailService";
import { getOrder, getUnitMaterials } from "../../../../services/OrderService";
import { getSuppliers } from "../../../../services/SupplierService";
import { convertDate } from "../../../../utils/function";

import UnitMaterialsTable from "../../../../components/CustomTable/UnitMaterialsTable";
import Summary from "./Summary";

const useStyles = makeStyles({
  root: {},
  navLink: {
    textDecoration: "none",
  },
});

const defaultTaxRate = 0.085;
const websiteURL = "https://horizoncarpentry.com";

export default function OrderPreview() {
  const { suppliers, setSuppliers } = useContext(AppContext);
  const classes = useStyles();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const orderId = params.get("oid"); // CHANGE_REQUEST_ID
  const jobId = params.get("jid"); //JOB_ID
  const supplierId = params.get("sid"); //SUPPLIER_ID
  const deliveryDate = params.get("ddate"); // PO_CREATE_DATE
  const [emailData, setEmailData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [unitMaterials, setUnitMaterials] = useState([]);
  const [taxRate, setTaxRate] = useState(defaultTaxRate);
  const [summaryData, setSummaryData] = useState(null);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [loadingOrderData, setLoadingOrderData] = useState(false);
  const [loadingEmailData, setLoadingEmailData] = useState(false);
  const [loadingUnitMaterials, setLoadingUnitMaterials] = useState(false);

  const retrieveEmailData = useCallback(async (JID, SID) => {
    try {
      setLoadingEmailData(true);
      getEmailData(JID, SID).then((res) => {
        const newEmailData = res[0];
        console.log(newEmailData);

        setLoadingEmailData(false);
        setEmailData(newEmailData);
        if (newEmailData?.TAX_RATE) setTaxRate(newEmailData?.TAX_RATE);
      });
    } catch (e) {
      console.error(e);
      setLoadingEmailData(false);
    }
  }, []);

  const retrieveSuppliers = async () => {
    try {
      setLoadingSuppliers(true);
      const resSuppliers = await getSuppliers();
      setSuppliers(resSuppliers);
      setLoadingSuppliers(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading suppliers!");
    }
  };

  const retrieveOrderData = useCallback(async (id) => {
    try {
      setLoadingOrderData(true);
      getOrder(id).then((res) => {
        if (res.length) {
          const newOrderData = res[0];
          console.log(newOrderData);

          setOrderData(newOrderData);
        }
      });
    } catch (e) {
      console.error(e);
      setLoadingOrderData(false);
    }
  }, []);

  const retrieveUnitMaterials = useCallback(async (id) => {
    try {
      setLoadingUnitMaterials(true);
      getUnitMaterials(id).then((res) => {
        setLoadingUnitMaterials(false);
        const resUnitMaterials = res.filter(
          (item) => item.SUPPLIER_ID === parseInt(supplierId)
        );
        setUnitMaterials(resUnitMaterials);
      });
    } catch (e) {
      console.error(e);
      setLoadingUnitMaterials(false);
    }
  }, []);

  useEffect(() => {
    retrieveSuppliers();
    if (jobId && supplierId) retrieveEmailData(jobId, supplierId);
    if (orderId) {
      retrieveOrderData(orderId);
      retrieveUnitMaterials(orderId);
    }
  }, [jobId, orderId, supplierId]);

  useEffect(() => {
    let subtotal = 0;
    let tax = 0;
    let total = 0;

    if (unitMaterials !== null) {
      subtotal += unitMaterials.reduce(
        (a, crItem) =>
          a + (Number(crItem.ITEM_QTY) * Number(crItem.ITEM_COST) || 0),
        0
      );
    }
    tax = subtotal * defaultTaxRate; // will be some tax multiplier later identified
    total = subtotal + tax;

    setSummaryData({
      subtotal: { title: "Subtotal", value: subtotal },
      tax: { title: `Tax (${taxRate * 100}%)`, value: tax },
      total: { title: "Total", value: total },
    });
  }, [unitMaterials, taxRate]);

  return (
    <Container component="main">
      <Box sx={{ mb: 10, mt: 10 }}>
        <Card>
          <CardHeader
            title={
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <a
                    className={classes.navLink}
                    href={websiteURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="h5" color="white">
                      HORIZON CARPENTRY
                    </Typography>
                  </a>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="white">
                    ORDER
                  </Typography>
                </Grid>
              </Grid>
            }
            sx={{ backgroundColor: "orange" }}
          />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" spacing={4}>
                  <Grid item>
                    <Typography variant="body2">PO BOX 5874</Typography>
                    <Typography variant="body2">
                      Elgin, Illnois 60121
                    </Typography>
                    <Typography variant="body2">
                      <strong>Office:</strong> 815-572-5656
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fax:</strong> 815-572-5011
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>Number</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Start Date</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Delivery Date</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{orderId}</TableCell>
                            <TableCell>
                              {emailData?.RELEASE_DATE
                                ? convertDate(emailData?.RELEASE_DATE)
                                : ""}
                            </TableCell>
                            <TableCell>
                              {orderData?.PO_CREATE_DATE
                                ? convertDate(orderData?.PO_CREATE_DATE)
                                : deliveryDate}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Card sx={{ height: "100%" }}>
                      <CardHeader
                        title={<Typography variant="h6">To:</Typography>}
                      />
                      <Divider />
                      <CardContent>
                        <Typography variant="body2">
                          {emailData?.NAME}
                        </Typography>
                        <Typography variant="body2">
                          {emailData?.CONTACT}
                        </Typography>
                        <Typography variant="body2">
                          {emailData?.ADDRESS}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Phone: </strong> {emailData?.PHONE}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Fax: </strong> {emailData?.FAX}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ height: "100%" }}>
                      <CardHeader
                        title={<Typography variant="h6">Ship To:</Typography>}
                      />
                      <Divider />
                      <CardContent>
                        <Typography variant="body2">
                          <strong>Project:</strong> {emailData?.DESCRIPTION}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Bld/Lot:</strong> {emailData?.JOB_DESC}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Job Id:</strong> {jobId}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Address/Unit:</strong>{" "}
                          {emailData?.DESCRIPTION1}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Builder:</strong> {emailData?.BUILDER}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <strong>Phase: </strong>
                      {orderData?.PHASE_ID}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Load: </strong>
                      {orderData?.LOAD || 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <strong>Model: </strong>
                      {emailData?.MODEL}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Elevation: </strong>
                      {emailData?.ELEVATION}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Type: </strong>
                      {emailData?.SHORT_DESC}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Summary direction="horizontal" data={summaryData} />
              </Grid>
              <Grid item xs={12}>
                <UnitMaterialsTable
                  title="Unit materials"
                  items={unitMaterials.filter(
                    (item) =>
                      !supplierId || item.SUPPLIER_ID === parseInt(supplierId)
                  )}
                  loading={loadingUnitMaterials}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
