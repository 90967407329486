import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import AppContext from "../../../../context/AppContext";
import { getEmailData } from "../../../../services/EmailService";
import {
  getJobHours,
  getJobOptions,
  getJobOptionsTotal,
  getJobOrders,
  getJobTotals,
  getOrder,
  getUnitMaterials,
} from "../../../../services/OrderService";
import { getSuppliers } from "../../../../services/SupplierService";
import { convertDate } from "../../../../utils/function";

import UnitMaterialsTable from "../../../../components/CustomTable/UnitMaterialsTable";
import OrderSummaryTable from "../../../../components/CustomTable/OrderSummaryTable";
import JobOptionsTable from "../../../../components/CustomTable/JobOptionsTable";
import LabelValuePairDisplay from "../../../../components/LabelValuePairDisplay";
const useStyles = makeStyles({
  root: {},
  navLink: {
    textDecoration: "none",
  },
});

const defaultTaxRate = 0.085;
const websiteURL = "https://horizoncarpentry.com";

export default function OrderSummaryPreview() {
  const classes = useStyles();
  const { setSuppliers } = useContext(AppContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const jobId = params.get("jid"); //JOB_ID
  const [jobOptions, setJobOptions] = useState([]);
  const [jobOptionsTotal, setJobOptionsTotal] = useState([]);
  const [jobTotals, setJobTotals] = useState([]);
  const [jobHours, setJobHours] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [materialsTotal, setMaterialsTotal] = useState(0);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);
  const [loadingJobHours, setLoadingJobHours] = useState(false);
  const [loadingJobOptions, setLoadingJobOptions] = useState(false);
  const [loadingJobOptionsTotal, setLoadingJobOptionsTotal] = useState(false);
  const [loadingJobTotals, setLoadingJobTotals] = useState(false);
  const [loadingSummaryData, setLoadingSummaryData] = useState(false);

  const getUnitMaterialsTotalCost = (unitMaterials) => {
    let sum = 0;
    for (let i = 0; i < unitMaterials.length; i++) {
      const item = unitMaterials[i];
      sum += parseFloat(item.ITEM_COST) * parseInt(item.ITEM_QTY);
    }
    return parseFloat(sum).toFixed(2);
  };

  useEffect(() => {
    if (!jobId) return;
    let mounted = true;

    const retrieveSuppliers = async () => {
      try {
        setLoadingSuppliers(true);
        const resSuppliers = await getSuppliers();
        setSuppliers(resSuppliers);
        setLoadingSuppliers(false);
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading suppliers!");
      }
    };

    const retrieveJobOrders = async (id) => {
      //JOB_ID
      try {
        if (mounted) setLoadingSummaryData(true);
        const res = await getJobOrders(id);
        if (mounted) {
          setSummaryData(res);
          setLoadingSummaryData(false);
        }
      } catch (e) {
        console.error(e);
        toast.error(
          "Unexpected error occurs while getting the offer summary data"
        );
      }
    };

    const retreiveJobOptions = async (id) => {
      //JOB_ID
      try {
        if (mounted) setLoadingJobOptions(true);
        const res = await getJobOptions(id);
        if (mounted) {
          setJobOptions(res);
          setLoadingJobOptions(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while getting the job options");
      }
    };

    const retreiveJobOptionsTotal = async (id) => {
      //JOB_ID
      try {
        if (mounted) setLoadingJobOptionsTotal(true);
        const res = await getJobOptionsTotal(id);
        if (mounted) {
          setJobOptionsTotal(res);
          setLoadingJobOptionsTotal(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while getting the job options");
      }
    };

    const retreiveJobTotals = async (id) => {
      //JOB_ID
      try {
        if (mounted) setLoadingJobTotals(true);
        const res = await getJobTotals(id);

        if (mounted) {
          setJobTotals(res);
          setLoadingJobTotals(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while getting the job totals");
      }
    };

    const retreiveJobHours = async (id) => {
      //JOB_ID
      try {
        if (mounted) setLoadingJobHours(true);
        const res = await getJobHours(id);

        if (mounted) {
          setJobHours(res);
          setLoadingJobHours(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while getting the job hours");
      }
    };

    retrieveSuppliers();
    retrieveJobOrders(jobId);
    retreiveJobOptions(jobId);
    retreiveJobOptionsTotal(jobId);
    retreiveJobTotals(jobId);
    retreiveJobHours(jobId);

    return () => {
      mounted = false;
    };
  }, [jobId]);

  useEffect(() => {
    let mounted = true;

    const initiate = async () => {
      let promises = summaryData.map(async (item) => {
        const resMaterials = await getUnitMaterials(item.ORDER_ID);
        return { [item.ORDER_ID]: resMaterials };
      });

      Promise.all(promises)
        .then((results) => {
          const materials = Object.assign({}, ...results);
          let total = 0;
          if (Object.keys(materials).length) {
            for (let i = 0; i < Object.keys(materials).length; i++) {
              const item = materials[Object.keys(materials)[i]];
              total += parseFloat(getUnitMaterialsTotalCost(item));
            }
          }
          if (mounted) setMaterialsTotal(total);
        })
        .catch((error) => {
          console.error("Error fetching unit materials:", error);
        });
    };

    initiate();

    return () => {
      mounted = false;
    };
  }, [summaryData]);

  const getSum = (array) => {
    let result = 0;

    for (let i = 0; i < array.length; i++) result += array[i];

    return result;
  };

  const getGrossProfit = () => {
    if (!jobTotals.length) return 0;

    const { CONTRACT_COST, ESTIMATED_HR, ACTUAL_HR } = jobTotals[0];
    const { OptionsTotal } = jobOptionsTotal[0];

    const grossProfit =
      CONTRACT_COST + OptionsTotal - ESTIMATED_HR * ACTUAL_HR - materialsTotal;

    return grossProfit;
  };

  return (
    <Container component="main">
      <Box sx={{ mb: 10, mt: 10 }}>
        <Card>
          <CardHeader
            title={
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <a
                    className={classes.navLink}
                    href={websiteURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="h5" color="white">
                      HORIZON CARPENTRY
                    </Typography>
                  </a>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="white">
                    ORDER SUMMARY
                  </Typography>
                </Grid>
              </Grid>
            }
            sx={{ backgroundColor: "orange" }}
          />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" spacing={4}>
                  <Grid item>
                    <Typography variant="body2">PO BOX 5874</Typography>
                    <Typography variant="body2">
                      Elgin, Illnois 60121
                    </Typography>
                    <Typography variant="body2">
                      <strong>Office:</strong> 815-572-5656
                    </Typography>
                    <Typography variant="body2">
                      <strong>Fax:</strong> 815-572-5011
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>Number</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Start Date</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Delivery Date</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Card sx={{ height: "100%" }}>
                      <CardHeader
                        title={<Typography variant="h6">To:</Typography>}
                      />
                      <Divider />
                      <CardContent>
                        <Typography variant="body2">
                          {jobOptions[0]?.NAME}
                        </Typography>
                        <Typography variant="body2">
                          {jobOptions[0]?.CONTACT}
                        </Typography>
                        <Typography variant="body2">
                          {jobOptions[0]?.ADDRESS}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Phone: </strong> {jobOptions[0]?.PHONE}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Fax: </strong> {jobOptions[0]?.FAX}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card sx={{ height: "100%" }}>
                      <CardHeader
                        title={<Typography variant="h6">Ship To:</Typography>}
                      />
                      <Divider />
                      <CardContent>
                        <Typography variant="body2">
                          <strong>Project:</strong> {jobOptions[0]?.DESCRIPTION}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Bld/Lot:</strong> {jobOptions[0]?.JOB_DESC}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Job Id:</strong> {jobId}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Address/Unit:</strong>{" "}
                          {jobOptions[0]?.DESCRIPTION1}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Builder:</strong> {jobOptions[0]?.BUILDER}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <strong>Phase: </strong> {jobOptions[0]?.PHASE_ID}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Load: </strong> {jobOptions[0]?.LOAD_ID}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <strong>Model: </strong> {jobOptions[0]?.MODEL}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Elevation: </strong> {jobOptions[0]?.ELEVATION}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Type: </strong> {jobOptions[0]?.SHORT_DESC}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <OrderSummaryTable
                  title="Order Summary"
                  loading={loadingSummaryData}
                  items={summaryData}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={8}>
                    <JobOptionsTable
                      title="Options"
                      loading={loadingJobOptions}
                      items={jobOptions}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Card variant="outlined">
                      <CardContent>
                        <Grid container spacing={8}>
                          <Grid item xs={12}>
                            <Grid container spacing={8}>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Base"
                                      value={`$${jobTotals[0]?.CONTRACT_COST.toFixed(
                                        2
                                      )}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Options"
                                      value={`${jobOptionsTotal[0]?.OptionsTotal.toFixed(
                                        2
                                      )}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Divider />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Total"
                                      value={`$${
                                        (
                                          jobTotals[0]?.CONTRACT_COST +
                                          jobOptionsTotal[0]?.OptionsTotal
                                        ).toFixed(2) || 0
                                      }`}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Hrs"
                                      value={`${(
                                        jobHours[0]?.HOURS || 0
                                      ).toFixed(2)}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Rate"
                                      value={`$${jobTotals[0]?.ESTIMATED_HR.toFixed(
                                        2
                                      )}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Labor"
                                      value={`$${(
                                        jobTotals[0]?.ESTIMATED_HR *
                                          jobHours[0]?.HOURS || 0
                                      ).toFixed(2)}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Material"
                                      value={`$${materialsTotal.toFixed(2)}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Rough Hardware"
                                      value={`$${(
                                        jobTotals[0]?.EST_RH || 0
                                      ).toFixed(2)}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Equipment"
                                      value={`$${(
                                        jobTotals[0]?.EST_EQUIPMENT || 0
                                      ).toFixed(2)}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Crane"
                                      value={`$${(
                                        jobTotals[0]?.EST_CRANE || 0
                                      ).toFixed(2)}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Divider />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Gross Profit"
                                      value={`$${getGrossProfit().toFixed(2)}`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="%"
                                      value={`${(
                                        getGrossProfit() /
                                        (jobTotals[0]?.CONTRACT_COST +
                                          jobOptionsTotal[0]?.OptionsTotal.toFixed(
                                            2
                                          ))
                                      ).toFixed(2)}%`}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <LabelValuePairDisplay
                                      label="Per Hr"
                                      value={`$${(
                                        getGrossProfit() /
                                        jobTotals[0]?.ACTUAL_HR
                                      ).toFixed(2)}`}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
