import { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";

import AppContext from "../../context/AppContext";
import { getUsers } from "../../services/UserService";

import Autocomplete from "../Autocomplete";

const MessageForm = ({ handleSubmit }) => {
  const { users, setUsers } = useContext(AppContext);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [formData, setFormData] = useState({
    user: null,
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({ user: "", subject: "", message: "" });

  const retrieveUsers = async () => {
    setLoadingUsers(true);
    const _users = await getUsers();
    setUsers(_users);
    setLoadingUsers(false);
  };

  useEffect(() => {
    retrieveUsers();
  }, []);

  const handleUserChange = (e, newValue) => {
    setErrors({ ...errors, user: newValue ? "" : "User is required." });
    setFormData({ ...formData, user: newValue });
  };

  const handleSubjectChange = (e) => {
    setErrors({
      ...errors,
      subject: e.target.value ? "" : "Subject is required.",
    });
    setFormData({ ...formData, subject: e.target.value });
  };

  const handleMessageChange = (e) => {
    setErrors({
      ...errors,
      message: e.target.value ? "" : "Message is required.",
    });
    setFormData({ ...formData, message: e.target.value });
  };

  const handleSend = () => {
    setErrors({
      ...errors,
      user: formData.user ? "" : "User is required",
      subject: formData.subject ? "" : "Subject is required.",
      message: formData.message ? "" : "Message is required.",
    });

    const isValid = formData.user && formData.subject && formData.message;

    if (!isValid) return;

    handleSubmit({ ...formData });
  };

  return (
    <Card>
      <CardHeader title="Send Message" />
      <Divider />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                label="Users"
                variant="outlined"
                loading={loadingUsers}
                disabled={loadingUsers}
                options={users || []}
                getOptionLabel={(option) => option.USERNAME}
                value={formData.user}
                onChange={handleUserChange}
              />
              {errors.user && (
                <FormHelperText error>{errors.user}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Subject"
              variant="outlined"
              value={formData.subject}
              onChange={handleSubjectChange}
              error={Boolean(errors.subject)}
              helperText={errors.subject}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              variant="outlined"
              value={formData.message}
              onChange={handleMessageChange}
              error={Boolean(errors.message)}
              helperText={errors.message}
              fullWidth
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container>
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="outlined"
              endIcon={<SendIcon />}
              onClick={handleSend}
              fullWidth
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default MessageForm;
