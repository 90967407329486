import React, { useEffect, useMediaQuery, useState } from "react";
import { format } from "fecha";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/system";
import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Flip as FlipIcon,
} from "@mui/icons-material";

import { convertDate } from "../../utils/function";

const defaultImage = "/assets/images/image-upload-placeholder.png";

const useStyles = makeStyles({
  barcodeScannerContainer: {
    width: "100%",
    height: "100%",
    "& > video": {
      objectFit: "cover",
    },
  },
});

export default function ToolInventoryTable({
  title,
  items,
  hiddenColumns = [],
  loading,
  handleAdd,
  handleEdit,
  handleDelete,
  handleCheck,
}) {
  const classes = useStyles();
  const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [data, setData] = useState([]);
  const [rowsExpanded, setRowsExpanded] = useState([]);
  const [rowsChecked, setRowsChecked] = useState([]);
  const [showScanner, setShowScanner] = useState(false);
  const [barcodeData, setBarcodeData] = useState("");
  const [scanCompleted, setScanCompleted] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  const columns = [
    {
      name: "TOOL_ID",
      label: "TOOL_ID",
      options: {
        display: false,
      },
    },
    {
      name: "ID_TAG",
      label: "ID TAG",
    },
    {
      name: "TOOL_SHORT_DESCRIPTION",
      label: "TOOL",
    },
    {
      name: "TOOL_DESCRIPTION",
      label: "DESCRIPTION",
    },
    {
      name: "TOOL_STATUS",
      label: "EMPLOYEE",
      options: {
        display: !hiddenColumns.includes("TOOL_STATUS"),
      },
    },
    {
      name: "PURCHASE_DATE",
      label: "PURCHASE DATE",
      options: {
        display: false,
      },
    },
    {
      name: "PRICE",
      label: "PRICE",
      options: {
        display: false,
      },
    },
    {
      name: "CHECKED",
      label: "CHECKED",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return handleCheck ? (
            <Switch
              checked={Boolean(rowsChecked[rowIndex])}
              onChange={(e) => handleToolCheck(e, rowIndex, rowData)}
              color="primary"
            />
          ) : (
            <Chip
              size="small"
              variant="outlined"
              label={value && parseInt(value) ? "Yes" : "No"}
              color={value && parseInt(value) ? "primary" : "error"}
            />
          );
        },
      },
    },
    {
      name: "DATE_LAST_CHECKED",
      label: "DATE LAST CHECKED",
      options: {
        display: false,
      },
    },
    {
      name: "DATE_TO_CHECK",
      label: "DATE TO CHECK",
      options: {
        display: false,
      },
    },
    {
      name: "SERVICE_STATUS",
      label: "SERVICE STATUS",
      options: {
        display: false,
      },
    },
    {
      name: "IMAGE_NAME",
      label: "NAME",
      options: {
        display: false,
      },
    },
    {
      name: "IMAGE_FILE_STREAMS",
      label: "FILE STREAM",
      options: {
        display: false,
      },
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleEdit && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={(event) => handleToolEdit(event, rowData)}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
              {handleDelete && (
                <Grid item>
                  <IconButton
                    color="error"
                    onClick={(event) => handleToolDelete(event, rowData)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    expandableRows: true,
    rowsExpanded: rowsExpanded,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const purchaseDate = rowData[5]; //PURCHASE_DATE
      const price = rowData[6]; //PRICE
      const dateLastChecked = rowData[8]; //DATE_LAST_CHECKED
      const dateToCheck = rowData[9]; //DATE_TO_CHECK
      const serviceStatus = rowData[10]; //SERVICE_STATUS
      const images = rowData[12]
        ? rowData[12].split(";").map((img) => `data:image/jpeg;base64, ${img}`)
        : []; // IMAGE DATA

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="span" component="p" align="right">
                          Purchase Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="span">
                          {purchaseDate
                            ? convertDate(purchaseDate)
                            : "Undefined"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="span" component="p" align="right">
                          Price:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="span">
                          {price ? `$${price}` : "Undefined"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="span" component="p" align="right">
                          Date Last Checked:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="span">
                          {dateLastChecked
                            ? convertDate(dateLastChecked)
                            : "Undefined"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="span" component="p" align="right">
                          Date To Check:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="span">
                          {dateToCheck ? convertDate(dateToCheck) : "Undefined"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="span" component="p" align="right">
                          Service Status:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="span">
                          {serviceStatus ? serviceStatus : "Undefined"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  {images.length ? (
                    images.map((image, index) => (
                      <Grid item key={index} xs={4}>
                        <Card>
                          <CardActionArea>
                            <CardMedia
                              image={image}
                              title="preview"
                              sx={{ height: 180 }}
                            />
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={4}>
                      <Card>
                        <CardActionArea>
                          <CardMedia
                            image={defaultImage}
                            title="preview"
                            sx={{ height: 180 }}
                          />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    },
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null && items.length) {
      const _data = items.map((item) => ({ ...item, ACTIONS: null }));
      const _rowsChecked = items.map((item) => item.CHECKED);
      setData(_data);
      setRowsChecked(_rowsChecked);
    }
  }, [items]);

  const handleRowExpandToggle = (rowData) => {
    const expandedRowIndex = rowsExpanded.indexOf(rowData.index);

    if (expandedRowIndex === -1) {
      // Row is currently not expanded, so add it to the rowsExpanded array
      setRowsExpanded([...rowsExpanded, rowData.index]);
    } else {
      // Row is currently expanded, so remove it from the rowsExpanded array
      setRowsExpanded(rowsExpanded.filter((index) => index !== rowData.index));
    }
  };

  const handleToolEdit = (event, rowData) => {
    event.stopPropagation();
    handleEdit(rowData);
  };

  const handleToolDelete = (event, rowData) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  const handleToolCheck = (event, rowIndex, rowData) => {
    event.stopPropagation();

    setSelectedRowData(rowData);
    setSelectedRowIndex(rowIndex);

    if (!rowsChecked[rowIndex]) openBarcodeScanner();
    else {
      const newRowsChecked = rowsChecked.map((item, index) =>
        index === rowIndex ? !item : item
      );
      setRowsChecked(newRowsChecked);

      const newRowData = {
        ...rowData,
        CHECKED: rowsChecked[rowIndex] ? 0 : 1,
        DATE_LAST_CHECKED: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
      };
      handleCheck(newRowData);
    }
  };

  const openBarcodeScanner = () => {
    setShowScanner(true);
  };

  const handleScannerUpdate = (err, result) => {
    if (result) {
      setBarcodeData(result.text);
      setScanCompleted(true);
      if (result.text === selectedRowData.ID_TAG) {
        handleDialogConfirm();
      }
    } else {
      setScanCompleted(false);
    }
  };

  const handleDialogConfirm = () => {
    setShowScanner(false);

    const newRowsChecked = rowsChecked.map((item, index) =>
      index === selectedRowIndex ? !item : item
    );
    setRowsChecked(newRowsChecked);

    const newRowData = {
      ...selectedRowData,
      CHECKED: rowsChecked[selectedRowIndex] ? 0 : 1,
      DATE_LAST_CHECKED: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
    };
    handleCheck(newRowData);
  };

  const handleDialogClose = () => {
    setShowScanner(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
          onRowExpansionChange={handleRowExpandToggle}
        />
        <Dialog
          open={showScanner}
          onClose={handleDialogClose}
          // fullScreen={fullScreen}
          aria-labelledby="scanner-dialog"
        >
          <DialogTitle id="scanner-dialog">Barcode Scanner</DialogTitle>
          <Divider />
          <DialogContent>
            <Typography variant="h5" align="center">
              <strong>Barcode: </strong>
              {scanCompleted ? barcodeData : "Not Found"}
              {selectedRowData.ID_TAG !== barcodeData ? "(Not matched)" : ""}
            </Typography>
            <div className={classes.barcodeScannerContainer}>
              <BarcodeScannerComponent
                torch="true"
                onUpdate={handleScannerUpdate}
              />
            </div>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button variant="outlined" onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDialogConfirm}>
              Check Anyway
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
}
