import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

const useStyles = makeStyles({});

export default function CRLaborTable({
  title,
  items,
  hiddenColumns = [],
  loading,
  handleAdd,
  handleEdit,
  handleDelete,
}) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const [editableRow, setEditableRow] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);

  const columns = [
    {
      name: "LDESCRIPTION",
      label: "Description",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.LDESCRIPTION}
                onChange={handleDescriptionChange}
                error={editableRow.LDESCRIPTION === ""}
                helperText={
                  editableRow.LDESCRIPTION === ""
                    ? "This field should not be empty."
                    : ""
                }
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "LHOURS",
      label: "Hours",
      options: {
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseFloat(obj1.data);
            const val2 = parseFloat(obj2.data);
            data.sort((a, b) => {
              return (
                (parseFloat(a.LHOURS) - parseFloat(b.LHOURS)) *
                (order === "asc" ? 1 : -1)
              );
            });

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                type="number"
                value={editableRow.LHOURS}
                onChange={handleHoursChange}
                error={editableRow.LHOURS <= 0}
                helperText={
                  editableRow.LHOURS <= 0
                    ? "This field should be more than 0."
                    : ""
                }
              />
            );
          }

          return value;
        },
      },
    },
    {
      name: "LCOST",
      label: "Cost",
      options: {
        sort: true,
        display: !hiddenColumns.includes("LCOST"),
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseFloat(obj1.data);
            const val2 = parseFloat(obj2.data);
            data.sort((a, b) => {
              return (
                (parseFloat(a.LCOST) - parseFloat(b.LCOST)) *
                (order === "asc" ? 1 : -1)
              );
            });

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                type="number"
                value={editableRow.LCOST}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChange={handleCostChange}
                error={editableRow.LCOST <= 0}
                helperText={
                  editableRow.LCOST <= 0
                    ? "This field should be more than 0."
                    : ""
                }
                sx={{ minWidth: "80px" }}
              />
            );
          }

          return "$" + parseFloat(value).toFixed(2);
        },
      },
    },
    {
      name: "LINE_TOTAL",
      label: "Line Total",
      options: {
        sort: false,
        display: !hiddenColumns.includes("LINE_TOTAL"),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          return (
            "$" +
            (
              data[rowIndex]?.LHOURS * parseFloat(data[rowIndex]?.LCOST)
            ).toFixed(2)
          );
        },
      },
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = items[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleEdit && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      {editableRowIndex === rowIndex ? (
                        <IconButton
                          title="save"
                          color="primary"
                          onClick={handleRowSave}
                          disabled={isInvalid}
                        >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          title="edit"
                          color="primary"
                          onClick={(event) => handleRowEdit(event, rowIndex)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Grid>
                    {editableRowIndex === rowIndex && (
                      <Grid item>
                        <IconButton
                          title="cancel"
                          onClick={handleRowEditCancel}
                        >
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {handleDelete && editableRowIndex !== rowIndex && (
                <Grid item>
                  <IconButton
                    title="delete"
                    color="error"
                    onClick={(event) => handleRowDelete(event, rowData)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleRowAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null) setData([...items]);
  }, [items]);

  const handleDescriptionChange = (e) => {
    setIsInvalid(e.target.value === "");
    setEditableRow({ ...editableRow, LDESCRIPTION: e.target.value });
  };

  const handleHoursChange = (e) => {
    setIsInvalid(e.target.value <= 0);
    setEditableRow({ ...editableRow, LHOURS: e.target.value });
  };

  const handleCostChange = (e) => {
    setIsInvalid(e.target.value <= 0);
    setEditableRow({ ...editableRow, LCOST: e.target.value });
  };

  const handleRowAdd = (event) => {
    event.preventDefault();

    let newItems = [];
    const newRow = { ID: items.length, LDESCRIPTION: "", LCOST: 0, LHOURS: 0 };

    newItems.push(newRow);
    newItems = [...newItems, ...items];

    setIsAdding(true);
    setData(newItems);
    setEditableRowIndex(0);
    setEditableRow(newRow);
  };

  const handleRowEdit = (event, index) => {
    event.preventDefault();
    if (isAdding) {
      setIsAdding(false);
      setData(items);
      setEditableRowIndex(index - 1);
    } else {
      setEditableRowIndex(index);
    }
    setEditableRow(data[index]);
  };

  const handleRowSave = (event) => {
    event.preventDefault();
    if (isAdding) {
      setIsAdding(false);
      handleAdd(editableRow);
    } else handleEdit(editableRow);
    setEditableRowIndex(-1);
    setEditableRow(null);
  };

  const handleRowEditCancel = () => {
    if (isAdding) {
      setIsAdding(false);
      setData(items);
    }
    setEditableRow(null);
    setEditableRowIndex(-1);
  };

  const handleRowDelete = (event, rowData) => {
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {/* {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )} */}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
