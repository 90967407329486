import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const severityValues = [
  { name: "Low", value: "low" },
  { name: "Medium", value: "medium" },
  { name: "High", value: "high" },
];
const statusValues = [
  { name: "Open", value: "open" },
  { name: "Closed", value: "closed" },
];

const RowContent = ({ data, handleSave }) => {
  const [errors, setErrors] = useState({
    resolution: "",
    status: "",
    severity: "",
  });
  const [rowData, setRowData] = useState(data);
  const [isEditing, setIsEditing] = useState(false);

  useState(() => {
    setRowData(data);
  }, [data]);

  const handleRowEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsEditing(true);
  };
  const handleRowEditSave = (e) => {
    setErrors({
      ...errors,
      resolution: rowData.RESOLUTION ? "" : "Resolution is required.",
      status: rowData.STATUS ? "" : "Status is required.",
      severity: rowData.SEVERITY ? "" : "Severity is required.",
    });

    const isValid = rowData.RESOLUTION && rowData.STATUS && rowData.SEVERITY;

    if (isValid) {
      setIsEditing(false);
      handleSave(rowData);
    }
  };
  const handleRowEditCancel = (e) => {
    setIsEditing(false);
  };

  const handleResolutionChange = (e) => {
    setErrors({
      ...errors,
      resolution: e.target.value ? "" : "Resolution is required.",
    });
    setRowData({ ...rowData, RESOLUTION: e.target.value });
  };
  const handleCommentChange = (e) => {
    setRowData({ ...rowData, COMMENTS: e.target.value });
  };
  const handleStatusChange = (e) => {
    setErrors({
      ...errors,
      status: e.target.value ? "" : "Status is required.",
    });
    setRowData({ ...rowData, STATUS: e.target.value });
  };
  const handleSeverityChange = (e) => {
    setErrors({
      ...errors,
      severity: e.target.value ? "" : "Severity is required.",
    });
    setRowData({ ...rowData, SEVERITY: e.target.value });
  };

  return (
    <Grid container justifyContent="space-between" spacing={4}>
      <Grid item xs={8}>
        <Grid container justifyContent="center">
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Resolution"
                  value={rowData.RESOLUTION}
                  onChange={handleResolutionChange}
                  error={Boolean(errors.resolution)}
                  helperText={errors.resolution}
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  value={rowData.DESCRIPTION}
                  fullWidth
                  multiline
                  rows={3}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Comment"
                  value={rowData.COMMENT}
                  onChange={handleCommentChange}
                  fullWidth
                  multiline
                  rows={3}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth disabled={!isEditing}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    value={rowData.STATUS || ""}
                    onChange={handleStatusChange}
                    error={Boolean(errors.status)}
                  >
                    <MenuItem value="" key="">
                      <em>None</em>
                    </MenuItem>
                    {statusValues.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.status !== "" && (
                    <FormHelperText error>{errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth disabled={!isEditing}>
                  <InputLabel>Severity</InputLabel>
                  <Select
                    label="Severity"
                    value={rowData.SEVERITY || ""}
                    onChange={handleSeverityChange}
                    error={Boolean(errors.severity)}
                  >
                    <MenuItem value="" key="">
                      <em>None</em>
                    </MenuItem>
                    {severityValues.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.severity !== "" && (
                    <FormHelperText error>{errors.severity}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            {isEditing ? (
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleRowEditCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleRowEditSave}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button variant="contained" size="small" onClick={handleRowEdit}>
                Edit
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function ContactUsTable({
  title,
  items,
  hiddenColumns = [],
  loading,
  handleEdit,
}) {
  const [data, setData] = useState([]);
  const [rowsExpanded, setRowsExpanded] = useState([]);

  const columns = [
    {
      name: "CONTACT_ID",
      label: "ID",
      options: {
        display: !hiddenColumns.includes("CONTACT_ID"),
      },
    },
    {
      name: "REASON",
      label: "REASON",
      options: {
        display: !hiddenColumns.includes("REASON"),
      },
    },
    {
      name: "NAME",
      label: "NAME",
      options: {
        display: !hiddenColumns.includes("NAME"),
      },
    },
    {
      name: "SUBJECT",
      label: "SUBJECT",
      options: {
        display: !hiddenColumns.includes("SUBJECT"),
      },
    },
    {
      name: "DESCRIPTION",
      label: "DESCRIPTION",
      options: {
        display: !hiddenColumns.includes("DESCRIPTION"),
      },
    },
    {
      name: "RESOLUTION",
      label: "RESOLUTION",
      options: {
        display: !hiddenColumns.includes("RESOLUTION"),
      },
    },
    {
      name: "COMMENTS",
      label: "COMMENTS",
      options: {
        display: !hiddenColumns.includes("COMMENTS"),
      },
    },
    {
      name: "STATUS",
      label: "STATUS",
      options: {
        display: !hiddenColumns.includes("STATUS"),
      },
    },
    {
      name: "SEVERITY",
      label: "SEVERITY",
      options: {
        display: !hiddenColumns.includes("SEVERITY"),
      },
    },
    {
      name: "USERNAME",
      label: "USERNAME",
      options: {
        display: !hiddenColumns.includes("USERNAME"),
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    expandableRows: true,
    rowsExpanded: rowsExpanded,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const selected = data.find((item) => item.CONTACT_ID === rowData[0]);

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <RowContent data={selected} handleSave={handleEdit} />
          </TableCell>
        </TableRow>
      );
    },
  };

  useEffect(() => {
    if (items !== null) setData([...items]);
  }, [items]);

  const handleRowExpandToggle = (rowData) => {
    const expandedRowIndex = rowsExpanded.indexOf(rowData.index);

    if (expandedRowIndex === -1) {
      // Row is currently not expanded, so add it to the rowsExpanded array
      setRowsExpanded([...rowsExpanded, rowData.index]);
    } else {
      // Row is currently expanded, so remove it from the rowsExpanded array
      setRowsExpanded(rowsExpanded.filter((index) => index !== rowData.index));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
          onRowExpansionChange={handleRowExpandToggle}
        />
      </Grid>
    </Grid>
  );
}
