import { GetData, PostData, PutData } from "../helpers/httpRequests";

const API_URL = process.env.REACT_APP_API_URL;

const getForemenProjects = async () => {
  return await GetData(API_URL + `/Foremen/GETAll`);
};

const getForemen = async () => {
  return await GetData(API_URL + `/Foremen/GET`);
};

const postForeman = async (payload) => {
  return await PostData(API_URL + `/Foremen/POST`, payload);
};

const putForeman = async (payload) => {
  return await PutData(API_URL + `/Foremen/PUT`, payload);
};

const deleteForeman = async (payload) => {
  return await GetData(API_URL + `/Foremen/GETDelete/${payload}`);
};

export {
  getForemenProjects,
  getForemen,
  postForeman,
  putForeman,
  deleteForeman,
};
