import { useContext, useEffect, useState } from "react";
import { format } from "fecha";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import AppContext from "../../../context/AppContext";

import {
  getAllDocuments,
  getDocuments,
  postDocument,
  putDocument,
} from "../../../services/DocumentService";

import DocumentsTable from "../../../components/CustomTable/DocumentsTable";
import AddDocument from "./AddDocument";

const useStyles = makeStyles({
  root: {},
});

export default function Documents() {
  const classes = useStyles();
  const { user } = useContext(AppContext);

  const initialDocumentData = {};
  const [documents, setDocuments] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const retrieveDocuments = async () => {
    setLoading(true);
    const newDocuments =
      user.USER_ROLE === "SUPPLIER"
        ? await getDocuments(user)
        : await getAllDocuments();

    setDocuments([...newDocuments]);
    setLoading(false);
  };

  useEffect(() => {
    if (user) retrieveDocuments();
  }, [user]);

  const handleDocumentCreate = (data) => {
    setSubmitting(true);
    postDocument(data)
      .then(async (res) => {
        setSubmitting(false);
        if (res.status === 200) {
          setIsAdding(false);
          await retrieveDocuments();
          toast.success("Document added!");
        } else {
          toast.error("Document not added!");
        }
      })
      .catch((err) => {
        console.error(err);
        setSubmitting(false);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleDocumentUpdate = (rowData) => {
    const payload = {
      ...rowData,
      LAST_UPDATED: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
    };

    setSubmitting(true);
    putDocument(payload)
      .then(async (res) => {
        setSubmitting(false);
        if (res.status === 200) {
          await retrieveDocuments();
          toast.success("Document updated");
        } else {
          toast.error("Document not updated!");
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleDocumentAdd = () => {
    setIsAdding(true);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {isAdding && (
          <Grid item xs={12}>
            <AddDocument
              document={initialDocumentData}
              submitting={submitting}
              handleSubmit={handleDocumentCreate}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DocumentsTable
            title="Documents"
            items={documents}
            loading={loading}
            handleAdd={handleDocumentAdd}
            handleEdit={handleDocumentUpdate}
          />
        </Grid>
      </Grid>
    </div>
  );
}
