import { useState, useContext } from "react";
import { makeStyles } from "@mui/styles";

import AppContext from "../../context/AppContext";
import SendMessage from "../../components/SendMessage";
import SubHeader from "../../components/SubHeader";

import Users from "./Users";
import ChangeRequests from "./ChangeRequests";
import ContactUs from "./ContactUs";
import Orders from "./Orders";
import Receipts from "./Receipts";
import ToolManagement from "./ToolManagement";
import Suppliers from "./Suppliers";
import Foremen from "./Foremen";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
  },
  adminDiv: {
    margin: "5rem auto",
  },
});

const subHeaderItems = [
  { value: "users", text: "Users" },
  { value: "reviewChangeRequests", text: "Review Change Requests" },
  { value: "reviewOrders", text: "Review Orders" },
  { value: "toolManagement", text: "Tool Management" },
  { value: "receiptsSubmitted", text: "Receipts Submitted" },
  { value: "contactUsRequests", text: "Contact Us Requests" },
  { value: "suppliers", text: "Suppliers" },
  { value: "foremen", text: "Foremen" },
];

export default function Admin() {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const [selectedSubHeaderItem, setSelectedSubHeaderItem] = useState(
    subHeaderItems[0].value
  );

  const handleSubHeaderItemSelect = (selected) => {
    setSelectedSubHeaderItem(selected);
  };

  return (
    <div>
      <SubHeader
        items={subHeaderItems}
        handleSelect={handleSubHeaderItemSelect}
        selected={selectedSubHeaderItem}
      />
      <div className={classes.root}>
        <h1>Welcome {user?.USERNAME}</h1>
        <div className={classes.adminDiv}>
          {selectedSubHeaderItem === "users" ? (
            <Users />
          ) : selectedSubHeaderItem === "reviewChangeRequests" ? (
            <ChangeRequests />
          ) : selectedSubHeaderItem === "reviewOrders" ? (
            <Orders />
          ) : selectedSubHeaderItem === "toolManagement" ? (
            <ToolManagement />
          ) : selectedSubHeaderItem === "receiptsSubmitted" ? (
            <Receipts />
          ) : selectedSubHeaderItem === "contactUsRequests" ? (
            <ContactUs />
          ) : selectedSubHeaderItem === "suppliers" ? (
            <Suppliers />
          ) : selectedSubHeaderItem === "foremen" ? (
            <Foremen />
          ) : (
            <Users />
          )}
        </div>
      </div>
    </div>
  );
}
