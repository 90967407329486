import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { FormControl, Grid } from "@mui/material";

import AppContext from "../../context/AppContext";
import { getJobs } from "../../services/JobService";
import { getProjects } from "../../services/ProjectService";
import { getLaborHours } from "../../services/ReportService";

import Autocomplete from "../../components/Autocomplete";
import HoursSummaryTable from "../../components/CustomTable/HoursSummaryTable";

const useStyles = makeStyles({
  root: {},
});

export default function HoursSummary() {
  const classes = useStyles();
  const { jobs, projects, setJobs, setProjects } = useContext(AppContext);
  const [laborHours, setLaborHours] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);

  useEffect(() => {
    let mounted = true;

    const retrieveLaborHours = async () => {
      try {
        if (mounted) setLoading(true);
        const res = await getLaborHours();
        if (mounted) {
          setLaborHours(res);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading labor hours!");
      }
    };

    const retrieveProjects = async () => {
      try {
        if (mounted) setLoadingProjects(true);
        const newProjects = await getProjects();
        if (mounted) {
          setProjects(newProjects);
          setLoadingProjects(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading projects!");
      }
    };

    const retrieveJobs = async () => {
      try {
        if (mounted) setLoadingJobs(true);
        const newJobs = await getJobs();
        if (mounted) {
          setJobs(newJobs);
          setLoadingJobs(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading jobs!");
      }
    };

    retrieveProjects();
    retrieveJobs();
    retrieveLaborHours();

    return () => {
      mounted = false;
    };
  }, []);

  const handleProjectChange = (e, newValue) => {
    setSelectedProject(newValue);
  };

  const handleJobChange = (e, newValue) => {
    setSelectedJob(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <FormControl sx={{ width: 240 }}>
                <Autocomplete
                  label="Project"
                  size="small"
                  variant="outlined"
                  loading={loadingProjects}
                  disabled={loadingProjects}
                  options={projects}
                  isOptionEqualToValue={(option, value) =>
                    option.PRO_ID === value.PRO_ID
                  }
                  getOptionLabel={(option) => option.DESCRIPTION}
                  value={selectedProject}
                  onChange={handleProjectChange}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl sx={{ width: 240 }}>
                <Autocomplete
                  label="Job"
                  size="small"
                  variant="outlined"
                  loading={loadingJobs}
                  disabled={loadingJobs || !selectedProject}
                  options={jobs.filter(
                    (job) =>
                      !selectedProject || job.PRO_ID === selectedProject.PRO_ID
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.JOB_ID === value.JOB_ID
                  }
                  getOptionLabel={(option) => option.JOB_DESC}
                  value={selectedJob}
                  onChange={handleJobChange}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <HoursSummaryTable
            title="Hours Summary"
            loading={loading}
            items={laborHours.filter(
              (item) =>
                (!selectedProject ||
                  item.JOB === selectedProject.DESCRIPTION) &&
                (!selectedJob || item.LOT === selectedJob.JOB_DESC)
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
