// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
export async function PostData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json", 
      //"Access-Control-Allow-Origin": "http://horizonlogin.com",
      //"Access-Control-Allow-Headers": "Content-Type, Authorization",
      //"Access-Control-Allow-Methods": "*",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response; // parses JSON response into native JavaScript objects
}

export async function GetData(url = "") {
  // Default options are marked with *
  const response = await fetch(url);
  return response.json(); // parses JSON response into native JavaScript objects
}

export async function PutData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return response;
}

export async function DeleteData(url = "") {
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response; //.json()?
}


export const fetch_retry = async (url, options, n) => {
    try {
        if (options === null) {
            return await fetch(url);
        } else {
            return await fetch(url, options);
        }
    } catch (err) {
        if (n <= 1) throw err;
        return await fetch_retry(url, options, n - 1);
    }
}