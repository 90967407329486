const config = {
  PAGE_PERMISSIONS: {
    ADMIN: {
      ADMIN: true,
      DASHBOARD: true,
      CHANGE_REQUSET: true,
      ORDERS: true,
      TOOLS: true,
      REPORTS: true,
      RECEIPTS: true,
      HELP: true,
      CONTACT_US: true,
      DOCUMENTS: true,
    },
    EMPLOYEE: {
      ADMIN: false,
      DASHBOARD: true,
      CHANGE_REQUSET: true,
      ORDERS: true,
      TOOLS: true,
      REPORTS: false,
      RECEIPTS: true,
      HELP: true,
      CONTACT_US: true,
      DOCUMENTS: true,
    },
    FOREMAN: {
      ADMIN: false,
      DASHBOARD: true,
      CHANGE_REQUSET: true,
      ORDERS: true,
      TOOLS: true,
      REPORTS: true,
      RECEIPTS: true,
      HELP: true,
      CONTACT_US: true,
      DOCUMENTS: true,
    },
    TRIM: {
      ADMIN: false,
      DASHBOARD: true,
      CHANGE_REQUSET: true,
      ORDERS: true,
      TOOLS: true,
      REPORTS: false,
      RECEIPTS: true,
      HELP: true,
      CONTACT_US: true,
      DOCUMENTS: true,
    },
    ESTIMATOR: {
      ADMIN: false,
      DASHBOARD: true,
      CHANGE_REQUSET: true,
      ORDERS: true,
      TOOLS: true,
      REPORTS: true,
      RECEIPTS: true,
      HELP: true,
      CONTACT_US: true,
      DOCUMENTS: true,
    },
    SUPPLIER: {
      ADMIN: false,
      DASHBOARD: false,
      CHANGE_REQUSET: false,
      ORDERS: false,
      TOOLS: false,
      REPORTS: false,
      RECEIPTS: false,
      HELP: false,
      CONTACT_US: false,
      DOCUMENTS: true,
    },
  },
};

export default config;
