import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "fecha";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../../context/AppContext";
import { getSuppliers } from "../../../services/DocumentService";
import { getJobs } from "../../../services/JobService";
import { getProjects } from "../../../services/ProjectService";

import Autocomplete from "../../../components/Autocomplete";
import FileUploadCard from "../../../components/FileUploadCard";

const useStyles = makeStyles({});

export default function AddDocument({
  document,
  submitting = false,
  handleSubmit,
}) {
  const { jobs, projects, user, setJobs, setProjects } = useContext(AppContext);
  const classes = useStyles();
  const [formData, setFormData] = useState({ ...document });
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    project: "",
    job: "",
  });
  const [availableJobs, setAvailableJobs] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);

  const retrieveSuppliers = async () => {
    try {
      setLoadingSuppliers(true);
      const newSuppliers = await getSuppliers();
      setSuppliers(newSuppliers);
      setLoadingSuppliers(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading suppliers");
    }
  };

  const retrieveProjects = async () => {
    try {
      setLoadingProjects(true);
      const newProjects = await getProjects();
      setProjects(newProjects);
      setLoadingProjects(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading projects!");
    }
  };

  const retrieveJobs = async () => {
    try {
      setLoadingJobs(true);
      const newJobs = await getJobs();
      setJobs(newJobs);
      setLoadingJobs(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading jobs!");
    }
  };

  useEffect(() => {
    retrieveSuppliers();
    retrieveProjects();
    retrieveJobs();
  }, []);

  useEffect(() => {
    if (user && user.USER_ROLE === "SUPPLIER") {
      setFormData({
        ...formData,
        SUPPLIER: suppliers.find((item) => item.USERNAME === user.USERNAME),
      });
    }
  }, [user, suppliers]);

  useEffect(() => {
    const newJobs = jobs.filter(
      (job) => !formData.PROJECT || job.PRO_ID === formData.PROJECT.PRO_ID
    );
    setAvailableJobs(newJobs);
  }, [jobs, formData.PROJECT]);

  const formValidate = () => {
    const valid =
      formData.TITLE &&
      formData.DESCRIPTION &&
      formData.PROJECT &&
      formData.JOB;

    setErrors({
      title: formData.TITLE ? "" : "Title is required.",
      description: formData.DESCRIPTION ? "" : "Description is required.",
      project: formData.PROJECT ? "" : "Project is required.",
      job: formData.JOB ? "" : "Job is required.",
    });

    return valid;
  };

  const handleFormSubmit = () => {
    if (!formValidate()) return;

    const data = {
      TITLE: formData.TITLE,
      DESCRIPTION: formData.DESCRIPTION,
      PROJECT_DESCRIPTION: formData.PROJECT.DESCRIPTION,
      JOB_DESCRIPTION: formData.JOB.JOB_DESC,
      SUPPLIER_USERNAME:
        user.USER_ROLE === "SUPPLIER"
          ? user.USERNAME
          : formData.SUPPLIER?.USERNAME || null,
      CREATE_DATE: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
      NAME: formData.NAME,
      FILE_STREAM: formData.FILE_STREAM,
    };

    handleSubmit(data);
  };

  const handleTitleChange = (e) => {
    setErrors({ ...errors, title: e.target.value ? "" : "Title is required." });
    setFormData({ ...formData, TITLE: e.target.value });
  };

  const handleSupplierChange = (e, newValue) => {
    setFormData({ ...formData, SUPPLIER: newValue });
  };

  const handleDescriptionChange = (e) => {
    setErrors({
      ...errors,
      description: e.target.value ? "" : "Description is required.",
    });
    setFormData({ ...formData, DESCRIPTION: e.target.value });
  };

  const handleProjectChange = (e, newValue) => {
    setErrors({
      ...errors,
      project: newValue ? "" : "Project is required.",
    });
    setFormData({ ...formData, PROJECT: newValue });
  };

  const handleJobChange = (e, newValue) => {
    setErrors({
      ...errors,
      job: newValue ? "" : "Job is required.",
    });
    setFormData({ ...formData, JOB: newValue });
  };

  const handleFileChanged = (fileData) => {
    setFormData({
      ...formData,
      NAME: fileData ? fileData.name : null,
      FILE_STREAM: fileData ? fileData.file : null,
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader title={<Typography variant="h6">Add Document</Typography>} />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Title"
                  type="text"
                  variant="outlined"
                  value={formData.TITLE}
                  onChange={handleTitleChange}
                  error={!!errors.title}
                  helperText={errors.title}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Supplier"
                    variant="outlined"
                    loading={loadingSuppliers}
                    disabled={Boolean(
                      loadingSuppliers || user.USER_ROLE === "SUPPLIER"
                    )}
                    options={suppliers || []}
                    getOptionLabel={(option) => option.USERNAME}
                    value={formData.SUPPLIER}
                    onChange={handleSupplierChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  type="text"
                  variant="outlined"
                  value={formData.DESCRIPTION}
                  onChange={handleDescriptionChange}
                  error={!!errors.description}
                  helperText={errors.description}
                  rows={3}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Project"
                    variant="outlined"
                    loading={loadingProjects}
                    disabled={loadingProjects}
                    options={projects || []}
                    getOptionLabel={(option) => option.DESCRIPTION}
                    value={formData.PROJECT}
                    onChange={handleProjectChange}
                  />
                  {errors.project !== "" && (
                    <FormHelperText error>{errors.project}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <Autocomplete
                    label="Job"
                    variant="outlined"
                    loading={loadingJobs}
                    disabled={loadingJobs}
                    options={availableJobs || []}
                    getOptionLabel={(option) => option.JOB_DESC}
                    value={formData.JOB}
                    onChange={handleJobChange}
                  />
                  {errors.job !== "" && (
                    <FormHelperText error>{errors.job}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={8}>
                <FileUploadCard handleChange={handleFileChanged} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              disabled={submitting}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
