import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

import { getSupplierMaterials } from "../../services/ChangeRequestService";

export default function SuppliersTable({
  title,
  items,
  hiddenColumns = [],
  loading,
  handleAdd,
  handleEdit,
  handleDelete,
}) {
  const [data, setData] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const [editableRow, setEditableRow] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [errors, setErrors] = useState({ name: "", email: "" });

  const columns = [
    {
      name: "EMAIL_ID",
      label: "ID",
      options: {
        display: !hiddenColumns.includes("EMAIL_ID"),
      },
    },
    {
      name: "SUPPLIER_NAME",
      label: "Name",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.SUPPLIER_NAME || ""}
                onChange={handleNameChange}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            );
          }

          return data[rowIndex].SUPPLIER_NAME;
        },
      },
    },
    {
      name: "SUPPLIER_EMAIL",
      label: "Email",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <TextField
                variant="standard"
                size="small"
                value={editableRow.SUPPLIER_EMAIL}
                onChange={handleEmailChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            );
          }

          return data[rowIndex].SUPPLIER_EMAIL;
        },
      },
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleEdit && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      {editableRowIndex === rowIndex ? (
                        <IconButton
                          title="save"
                          color="primary"
                          onClick={handleRowSave}
                          disabled={isInvalid}
                        >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          title="edit"
                          color="primary"
                          onClick={(event) => handleRowEdit(event, rowIndex)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Grid>
                    {editableRowIndex === rowIndex && (
                      <Grid item>
                        <IconButton
                          title="cancel"
                          onClick={handleRowEditCancel}
                        >
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {handleDelete && editableRowIndex !== rowIndex && (
                <Grid item>
                  <IconButton
                    title="delete"
                    color="error"
                    onClick={(event) => handleRowDelete(event, rowData)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleRowAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null) setData([...items]);
  }, [items]);

  const handleNameChange = (e) => {
    validateEditableRow();

    setEditableRow({
      ...editableRow,
      SUPPLIER_NAME: e.target.value,
    });
  };

  const handleEmailChange = (e) => {
    validateEditableRow();

    setEditableRow({
      ...editableRow,
      SUPPLIER_EMAIL: e.target.value,
    });
  };

  const validateEditableRow = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    setErrors({
      name: editableRow.SUPPLIER_NAME ? "" : "Name is required.",
      email: editableRow.SUPPLIER_EMAIL
        ? emailRegex.test(editableRow.SUPPLIER_EMAIL)
          ? ""
          : "Email is invalid."
        : "Email is required.",
    });

    const rowValid =
      editableRow.SUPPLIER_NAME &&
      editableRow.SUPPLIER_EMAIL &&
      emailRegex.test(editableRow.SUPPLIER_EMAIL);

    setIsInvalid(!rowValid);

    return rowValid;
  };

  const handleRowAdd = (event) => {
    event.preventDefault();

    let newItems = [];
    const newRow = {
      ID: items.length,
      SUPPLIER_NAME: "",
      SUPPLIER_EMAIL: "",
    };

    newItems.push(newRow);
    newItems = [...newItems, ...items];

    setIsAdding(true);
    setData(newItems);
    setEditableRowIndex(0);
    setEditableRow(newRow);
  };

  const handleRowEdit = (event, index) => {
    event.preventDefault();

    if (isAdding) {
      setIsAdding(false);
      setData(items);
      setEditableRowIndex(index - 1);
    } else {
      setEditableRowIndex(index);
    }

    setEditableRow(data[index]);
  };

  const handleRowSave = (event) => {
    event.preventDefault();

    if (!validateEditableRow()) return;

    const rowData = {
      ...editableRow,
    };

    if (isAdding) {
      setIsAdding(false);
      handleAdd(rowData);
    } else handleEdit(rowData);
    setEditableRowIndex(-1);
    setEditableRow(null);
  };

  const handleRowEditCancel = () => {
    if (isAdding) {
      setIsAdding(false);
      setData(items);
    }
    setEditableRow(null);
    setEditableRowIndex(-1);
  };

  const handleRowDelete = (event, rowData) => {
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
