import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "fecha";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import AppContext from "../../../context/AppContext";
import { getUnitMaterials } from "../../../services/OrderService";

import UnitMaterialsTable from "../../../components/CustomTable/UnitMaterialsTable";
import { convertDate } from "../../../utils/function";

const useStyles = makeStyles({
  root: {},
});

const OrderDetails = ({ data, handleUpdate }) => {
  const classes = useStyles();
  const { suppliers } = useContext(AppContext);
  const [unitMaterials, setUnitMaterials] = useState([]);
  const [supplierUnitMaterials, setSupplierUnitMaterials] = useState([]);
  const [materialSuppliers, setMaterialSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState({ SUPPLIER_ID: "" });
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!data) return;

    let isMounted = true;

    const retreiveUnitMaterials = async () => {
      const { ORDER_ID: orderID, PO_CREATE_DATE } = data;

      try {
        if (isMounted) {
          setDeliveryDate(
            PO_CREATE_DATE
              ? format(new Date(PO_CREATE_DATE), "YYYY-MM-DD hh:mm:ss")
              : null
          );
          setLoading(true);
        }
        const newUnitMaterials = await getUnitMaterials(orderID);
        if (isMounted) setLoading(false);

        const resSupplierIds = [
          ...new Set(newUnitMaterials.map((item) => item.SUPPLIER_ID)),
        ];
        const resSuppliers = suppliers.filter(
          (supplier) =>
            supplier.JOB_ID === data.JOB_ID &&
            resSupplierIds.includes(supplier.SUPPLIER_ID)
        );

        if (isMounted) {
          setUnitMaterials(
            newUnitMaterials.map((item, index) => ({ ID: index, ...item }))
          );
          setSupplierUnitMaterials(
            newUnitMaterials.map((item, index) => ({ ID: index, ...item }))
          );
          setMaterialSuppliers([...resSuppliers]);
        }
      } catch (e) {
        console.error(e);
      }
    };

    retreiveUnitMaterials();

    return () => {
      isMounted = false;
    };
  }, [data]);

  useEffect(() => {
    const newSupplierUnitMaterials = unitMaterials.filter(
      (item) =>
        selectedSupplier.SUPPLIER_ID === "" ||
        (!selectedSupplier && item.SUPPLIER_ID === 0) ||
        (selectedSupplier && item?.SUPPLIER_ID === selectedSupplier.SUPPLIER_ID)
    );
    setSupplierUnitMaterials(newSupplierUnitMaterials);
  }, [selectedSupplier, unitMaterials]);

  const handleDeliveryDateChange = (value) => {
    setDeliveryDate(value);
  };

  const handleSupplierSelect = (e) => {
    const newSelectedSupplierId = e.target.value;
    const newSelectedSupplier =
      newSelectedSupplierId > 0
        ? materialSuppliers.find(
            (item) => item.SUPPLIER_ID === newSelectedSupplierId
          )
        : newSelectedSupplierId === 0
        ? { NAME: "Horizon", SUPPLIER_ID: 0 }
        : { SUPPLIER_ID: "" };

    setSelectedSupplier(newSelectedSupplier);
  };

  const handleOrderSubmit = () => {
    const newOrderData = {
      ...data,
      PO_CREATE_DATE: format(new Date(deliveryDate), "YYYY-MM-DD hh:mm:ss"),
      ORDER_TIME: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
    };

    handleUpdate(newOrderData);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={data?.PO_CREATE_DATE ? 12 : 8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <UnitMaterialsTable
                        title="Unit materials"
                        loading={loading}
                        items={supplierUnitMaterials}
                        hiddenColumns={[
                          "DESCRIPTION",
                          "JOB_DESC",
                          "UNIT_ID",
                          "PHASE_ID",
                        ]}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {!data?.PO_CREATE_DATE && (
          <Grid item xs={4}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={4}>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel>Suppliers</InputLabel>
                              <Select
                                value={selectedSupplier?.SUPPLIER_ID}
                                onChange={handleSupplierSelect}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value={0}>Horizon</MenuItem>
                                {materialSuppliers.map((item) => (
                                  <MenuItem
                                    value={item.SUPPLIER_ID}
                                    key={item.SUPPLIER_ID}
                                  >
                                    {item.NAME}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <DatePicker
                              label="Delivery Date"
                              value={
                                deliveryDate ? new Date(deliveryDate) : null
                              }
                              onChange={handleDeliveryDateChange}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                              disabled={Boolean(data.PO_CREATE_DATE)}
                            />
                          </Grid>
                          {!data.PO_CREATE_DATE && (
                            <Grid item xs={12}>
                              <Grid container spacing={4}>
                                <Grid item xs={6}>
                                  <Button
                                    variant="contained"
                                    type="primary"
                                    onClick={(e) => handleOrderSubmit("ACCEPT")}
                                    disabled={!deliveryDate}
                                    fullWidth
                                  >
                                    ACCEPT
                                  </Button>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button
                                    variant="outlined"
                                    type="primary"
                                    onClick={(e) => handleOrderSubmit("DENY")}
                                    disabled={!deliveryDate}
                                    fullWidth
                                  >
                                    DENY
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          {selectedSupplier && (
                            <Grid item xs={12}>
                              <Box sx={{ mt: 4 }}>
                                <Link
                                  to={`/order/preview?oid=${
                                    data.ORDER_ID
                                  }&jid=${data.JOB_ID}&sid=${
                                    selectedSupplier?.SUPPLIER_ID
                                  }${
                                    deliveryDate
                                      ? `&ddate=${convertDate(deliveryDate)}`
                                      : ""
                                  }`}
                                  className={classes.navLink}
                                  target="_blank"
                                >
                                  <Button size="small" variant="text" fullWidth>
                                    Preivew
                                  </Button>
                                </Link>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default OrderDetails;
