import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { format } from "fecha";
import { Fab, Grid, Popover } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Email as EmailIcon } from "@mui/icons-material";

import AppContext from "../../context/AppContext";
import { postMessage } from "../../services/MessageService";

import MessageForm from "./MessageForm";

const useStyles = makeStyles({
  msgButton: {
    position: "fixed",
    bottom: 20,
    right: 20,
  },
});

export default function SendMessage() {
  const { user } = useContext(AppContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSubmit = (formData) => {
    let data = {
      MESSAGE_TYPE: "MESSAGE",
      SUBJECT: formData.subject,
      MESSAGE: formData.message,
      USERNAME_TO: formData.user.USERNAME,
      USERNAME_FROM: user.USERNAME,
      SENT_DATE: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
    };
    console.log(data);

    postMessage(user, data)
      .then((res) => {
        if (res.status === 200) {
          setAnchorEl(null);
          toast.success("Message sent!");
        } else {
          toast.error("Message not sent!");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Fab
        className={classes.msgButton}
        color="secondary"
        variant="contained"
        onClick={(e) => handleClick(e, "send")}
      >
        <EmailIcon fontSize="large" sx={{ color: "white" }} />
      </Fab>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <MessageForm handleSubmit={handleSubmit} />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
