import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "fecha";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import AppContext from "../../../context/AppContext";
import {
  deleteCRLabor,
  deleteCRMaterial,
  getAllChangeRequests,
  getCRLabor,
  getCRMaterials,
  getChangeRequests,
  getJobSuppliers,
  getReviewerChangeRequests,
  postCRLabor,
  postCRMaterial,
  putCRLabor,
  putCRMaterial,
  putChangeRequest,
} from "../../../services/ChangeRequestService";

import Autocomplete from "../../../components/Autocomplete";
import CRLaborTable from "../../../components/CustomTable/CRLaborTable";
import CRMaterialsTable from "../../../components/CustomTable/CRMaterialsTable";
import CREmailForm from "./CREmailForm";
import CRSummary from "./CRSummary";

const useStyles = makeStyles({
  root: {},
});

const defaultTaxRate = 0.085;

const CRDetails = ({ data }) => {
  const classes = useStyles();
  const { user, setChangeRequests } = useContext(AppContext);
  const [taxRate, setTaxRate] = useState(defaultTaxRate);
  const [CRMaterials, setCRMaterials] = useState([]);
  const [CRLabor, setCRLabor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [CRSummaryData, setCRSummaryData] = useState(null);
  const [availableSuppliers, setAvailableSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [materialMarkup, setMaterialMarkup] = useState(0);
  const [markupInputValue, setMarkupInputValue] = useState(0);
  const [isMaterialMarkupEditable, setIsMaterialMarkupEditable] =
    useState(false);
  const isEditable =
    data?.APPROVED === null ||
    (data?.APPROVED === "1" && data?.PO_CREATE_DATE === null);

  useEffect(() => {
    if (!data) return;

    const initialize = async () => {
      const { CHANGE_REQUEST_ID: CRID, JOB_ID, MARKUP } = data;

      try {
        setMarkupInputValue(MARKUP * 100 || 0);
        setMaterialMarkup(MARKUP);

        setLoading(true);
        const newSuppliers = await getJobSuppliers(JOB_ID);
        const newLabor = await getCRLabor(CRID);
        const newMaterials = await getCRMaterials(CRID);
        setLoading(false);

        setAvailableSuppliers(newSuppliers);
        setCRLabor(newLabor.map((item, index) => ({ ID: index, ...item })));
        setCRMaterials(
          newMaterials.map((item, index) => ({ ID: index, ...item }))
        );
        if (newMaterials.length) {
          const { TAX_RATE } = newMaterials[0];
          const materialSupplier = newSuppliers.find(
            (item) => item.SUPPLIER_ID === newMaterials[0].SUPPLIER_ID
          );

          if (TAX_RATE) setTaxRate(TAX_RATE);
          setSelectedSupplier(materialSupplier);
        }
      } catch (e) {
        console.error(e);
      }
    };

    initialize();
  }, [data]);

  useEffect(() => {
    let subtotal = 0;
    let tax = 0;
    let total = 0;
    let laborTotal = 0;
    let materialTotal = 0;

    if (CRLabor !== null) {
      laborTotal += CRLabor.reduce(
        (a, crItem) => a + (Number(crItem.LHOURS) * Number(crItem.LCOST) || 0),
        0
      );
    }
    if (CRMaterials !== null) {
      materialTotal += CRMaterials.reduce(
        (a, crItem) =>
          a +
          (Number(crItem.QUANTITY) *
            Number(crItem.COST + crItem.COST * materialMarkup) || 0),
        0
      );
    }
    subtotal = laborTotal + materialTotal;
    tax = materialTotal * taxRate; // will be some tax multiplier later identified
    total = subtotal + tax;

    setCRSummaryData({
      subtotal: { title: "Subtotal", value: subtotal },
      tax: { title: `Tax (${taxRate * 100}%)`, value: tax },
      total: { title: "Total", value: total },
    });
  }, [CRMaterials, CRLabor, taxRate, materialMarkup]);

  const retrieveChangeRequests = async () => {
    try {
      setLoading(true);
      const crs =
        user.USER_ROLE === "ADMIN"
          ? await getAllChangeRequests()
          : user.USER_ROLE === "ESTIMATOR"
          ? await getReviewerChangeRequests(user)
          : await getChangeRequests(user);

      crs.sort((a, b) => b.CHANGE_REQUEST_ID - a.CHANGE_REQUEST_ID);

      setChangeRequests([...crs]);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpect error occurs while loading change requests!");
    }
  };

  const handleCRLaborAdd = (newRowData) => {
    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const laborData = {
      CHANGE_REQUEST_ID: data.CHANGE_REQUEST_ID,
      LDESCRIPTION: newRowData.LDESCRIPTION,
      LHOURS: newRowData.LHOURS,
      LCOST: newRowData?.LCOST || 0,
      LTIME_REQUESTED: timeRequested,
    };

    postCRLabor(laborData)
      .then(async (res) => {
        console.log(res);
        if (res.status === 200) {
          const resCRLabor = await getCRLabor(data.CHANGE_REQUEST_ID);

          setCRLabor([...resCRLabor]);
          toast.success("Labor added");
        } else {
          toast.error("Labor not added");
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleCRLaborEdit = (rowData) => {
    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const laborData = {
      ...rowData,
      LDESCRIPTION: rowData.LDESCRIPTION,
      LHOURS: rowData.LHOURS,
      LCOST: rowData?.LCOST || 0,
      LTIME_REQUESTED: timeRequested,
    };

    putCRLabor(laborData)
      .then(async (res) => {
        if (res.status === 200) {
          const resCRLabor = await getCRLabor(data.CHANGE_REQUEST_ID);

          setCRLabor([...resCRLabor]);
          toast.success("Labor updated");
        } else {
          toast.error("Labor not updated");
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleCRLaborDelete = (rowData) => {
    deleteCRLabor(rowData.CHANGE_REQUEST_LABOR_ID)
      .then(async (res) => {
        const resCRLabor = await getCRLabor(data.CHANGE_REQUEST_ID);

        setCRLabor([...resCRLabor]);
        toast.success("Labor deleted");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleCRMaterialAdd = (newRowData) => {
    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const materialData = {
      CHANGE_REQUEST_ID: data.CHANGE_REQUEST_ID,
      SUPPLIER_ID: newRowData.SUPPLIER_ID || 99,
      ITEM_ID: newRowData.SUPPLIER_ID ? newRowData.ITEM.ITEM_ID : null,
      DESCRIPTION: newRowData.SUPPLIER_ID
        ? newRowData.ITEM.ITEM_DESC
        : newRowData.DESCRIPTION,
      QUANTITY: parseInt(newRowData.QUANTITY),
      COST: newRowData?.COST || 0,
      TIME_REQUESTED: timeRequested,
    };

    postCRMaterial(materialData)
      .then(async (res) => {
        console.log(res);
        if (res.status === 200) {
          const resCRMaterials = await getCRMaterials(data.CHANGE_REQUEST_ID);

          setCRMaterials([...resCRMaterials]);
          toast.success("Material added");
        } else {
          toast.error("Material not added");
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleCRMaterialEdit = (rowData) => {
    const timeRequested = format(new Date(), "YYYY-MM-DD hh:mm:ss");
    const materialData = {
      ...rowData,
      ITEM_ID: rowData.ITEM_ID,
      DESCRIPTION: rowData.SUPPLIER_ID
        ? rowData.ITEM
          ? rowData.ITEM.ITEM_DESC
          : rowData.DESCRIPTION
        : rowData.DESCRIPTION,
      COST: rowData?.COST || 0,
      SUPPLIER_ID: rowData?.SUPPLIER_ID || 99,
      TIME_REQUESTED: timeRequested,
    };

    putCRMaterial(materialData)
      .then(async (res) => {
        if (res.status === 200) {
          const resCRMaterials = await getCRMaterials(data.CHANGE_REQUEST_ID);

          setCRMaterials([...resCRMaterials]);
          toast.success("Material updated");
        } else {
          toast.error("Material not updated");
        }
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleCRMaterialDelete = (rowData) => {
    deleteCRMaterial(rowData.CHANGE_REQUEST_MATERIAL_ID)
      .then(async (res) => {
        const resCRMaterials = await getCRMaterials(data.CHANGE_REQUEST_ID);

        setCRMaterials([...resCRMaterials]);
        toast.success("Material deleted");
      })
      .catch((e) => {
        console.error(e);
        toast.error("Unexpected error occurs!");
      });
  };

  const handleSupplierChange = (e, newSupplier) => {
    setSelectedSupplier({ ...newSupplier });
  };

  const handleMaterialMarkupEdit = () => {
    setIsMaterialMarkupEditable(true);
  };

  const handleMaterialMarkupChange = (e) => {
    setMarkupInputValue(e.target.value);
  };

  const handleMaterialMarkupConfirm = () => {
    setMaterialMarkup(parseFloat(markupInputValue / 100.0).toFixed(2));
    setIsMaterialMarkupEditable(false);
  };

  const handleMaterialMarkupCancel = () => {
    setMarkupInputValue(materialMarkup * 100);
    setIsMaterialMarkupEditable(false);
  };

  const handleEmailFormSubmit = (formData) => {
    setSubmitting(true);

    const params = {
      ...data,
      MARKUP: parseFloat(materialMarkup),
      CONTRACT_PRICE: parseFloat(CRSummaryData.total.value),
      APPROVED: formData.action === "ACCEPT" ? 1 : 0,
      EMAIL_STATUS: formData.emailChoice,
      NOTES: formData.notes,
      CUSTOMER_EMAIL_ADDRESS: formData.email,
      PO_CREATE_DATE:
        formData.action === "ACCEPT" && formData.deliveryDate
          ? format(new Date(formData.deliveryDate), "YYYY-MM-DD hh:mm:ss")
          : null,
      TIME_REQUESTED: format(new Date(), "YYYY-MM-DD hh:mm:ss"),
    };

    putChangeRequest(params)
      .then(async (res) => {
        setSubmitting(false);
        if (res.status === 200) {
          await retrieveChangeRequests();
          toast.success("Entry recorded!");
        } else {
          toast.error("Entry not recorded!");
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        toast.error(`${err}`);
      });
  };

  const displayMaterialsTableTitle = () => {
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="h5">Materials</Typography>
        </Grid>
        <Grid item>
          <FormControl sx={{ minWidth: "300px" }}>
            <Autocomplete
              size="small"
              label="Supplier"
              variant="outlined"
              loading={loading}
              disabled={Boolean(!isEditable || loading || CRMaterials.length)}
              options={availableSuppliers || []}
              getOptionLabel={(option) => option.NAME}
              value={selectedSupplier}
              isOptionEqualToValue={(option, value) =>
                option.SUPPLIER_ID === value?.SUPPLIER_ID
              }
              onChange={handleSupplierChange}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      {!loading ? (
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    {!isEditable ? (
                      <CRLaborTable title="Labor" items={CRLabor} />
                    ) : (
                      <CRLaborTable
                        title="Labor"
                        items={CRLabor}
                        handleAdd={handleCRLaborAdd}
                        handleEdit={handleCRLaborEdit}
                        handleDelete={handleCRLaborDelete}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item xs={8}>
                            <TextField
                              variant="outlined"
                              size="small"
                              label="Material Markup"
                              value={markupInputValue}
                              onChange={handleMaterialMarkupChange}
                              disabled={!isMaterialMarkupEditable}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          {isEditable && (
                            <Grid item xs={4}>
                              <Grid container spacing={1}>
                                {isMaterialMarkupEditable ? (
                                  <>
                                    <Grid item>
                                      <IconButton
                                        color="primary"
                                        onClick={handleMaterialMarkupConfirm}
                                      >
                                        <CheckIcon />
                                      </IconButton>
                                    </Grid>
                                    <Grid item>
                                      <IconButton
                                        color="error"
                                        onClick={handleMaterialMarkupCancel}
                                      >
                                        <ClearIcon />
                                      </IconButton>
                                    </Grid>
                                  </>
                                ) : (
                                  <Grid item>
                                    <IconButton
                                      color="primary"
                                      onClick={handleMaterialMarkupEdit}
                                    >
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {!isEditable ? (
                          <CRMaterialsTable
                            title={displayMaterialsTableTitle()}
                            job={{ JOB_ID: data.JOB_ID }}
                            supplier={selectedSupplier}
                            markup={materialMarkup}
                            items={CRMaterials}
                          />
                        ) : (
                          <CRMaterialsTable
                            title={displayMaterialsTableTitle()}
                            job={{ JOB_ID: data.JOB_ID }}
                            supplier={selectedSupplier}
                            markup={materialMarkup}
                            items={CRMaterials}
                            handleAdd={handleCRMaterialAdd}
                            handleEdit={handleCRMaterialEdit}
                            handleDelete={handleCRMaterialDelete}
                            handleSupplierChange={handleSupplierChange}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12}>
                <CRSummary data={CRSummaryData} />
              </Grid>
              <Grid item xs={12}>
                <CREmailForm
                  crData={{ ...data, MARKUP: materialMarkup }}
                  supplier={selectedSupplier}
                  onSubmit={handleEmailFormSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default CRDetails;
