import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

import AppContext from "../../context/AppContext";
import { getSuppliers } from "../../services/SupplierService";

const useStyles = makeStyles({});

export default function UnitMaterialsTable({
  title,
  items,
  hiddenColumns = [],
  loading,
  handleDelete,
}) {
  const classes = useStyles();
  const { suppliers } = useContext(AppContext);
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "DESCRIPTION",
      label: "Project",
      options: {
        display: !hiddenColumns.includes("DESCRIPTION"),
      },
    },
    {
      name: "JOB_DESC",
      label: "Job",
      options: {
        display: !hiddenColumns.includes("JOB_DESC"),
      },
    },
    {
      name: "UNIT_ID",
      label: "Unit",
      options: {
        display: !hiddenColumns.includes("UNIT_ID"),
      },
    },
    {
      name: "PHASE_ID",
      label: "Phase",
      options: {
        display: !hiddenColumns.includes("PHASE_ID"),
      },
    },
    {
      name: "ITEM_DESC",
      label: "Material",
    },
    {
      name: "SUPPLIER",
      label: "Supplier",
      options: {
        sort: false,
        display: !hiddenColumns.includes("SUPPLIER"),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const supp = suppliers.find(
            (item) => item.SUPPLIER_ID === data[rowIndex].SUPPLIER_ID
          );
          return supp ? supp.NAME : "Horizon";
        },
      },
    },
    {
      name: "ITEM_COST",
      label: "Cost",
      options: {
        sort: false,
        display: !hiddenColumns.includes("ITEM_COST"),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          return "$" + parseFloat(data[rowIndex]?.ITEM_COST || 0).toFixed(2);
        },
      },
    },
    {
      name: "ITEM_QTY",
      label: "Quantity",
    },
    {
      name: "",
      label: "Line Total",
      options: {
        sort: false,
        display: !hiddenColumns.includes("LINE_TOTAL"),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          return (
            "$" +
            parseFloat(
              data[rowIndex]?.ITEM_QTY * data[rowIndex]?.ITEM_COST || 0
            ).toFixed(2)
          );
        },
      },
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleDelete && (
                <Grid item>
                  <IconButton
                    title="delete"
                    color="error"
                    onClick={(event) => handleRowDelete(event, rowData)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  };

  useEffect(() => {
    if (items) setData([...items]);
  }, [items]);

  const handleRowDelete = (event, rowData) => {
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
