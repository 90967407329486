import { useState } from "react";
import { makeStyles } from "@mui/styles";

import SubHeader from "../../components/SubHeader";
import Documents from "./Documents";
import JobMaterials from "./JobMaterials";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
  },
  dashboardDiv: {
    margin: "5rem auto",
  },
});

const subHeaderItems = [
  { value: "documents", text: "Documents" },
  { value: "jobMaterials", text: "Job Materials" },
];

export default function SupplierDocuments() {
  const classes = useStyles();
  const [selectedSubHeaderItem, setSelectedSubHeaderItem] = useState(
    subHeaderItems[0].value
  );

  const handleSubHeaderItemSelect = (selected) => {
    setSelectedSubHeaderItem(selected);
  };

  return (
    <div>
      <SubHeader
        items={subHeaderItems}
        handleSelect={handleSubHeaderItemSelect}
        select={selectedSubHeaderItem}
      />
      <div className={classes.root}>
        <div className={classes.dashboardDiv}>
          {selectedSubHeaderItem === "documents" ? (
            <Documents />
          ) : selectedSubHeaderItem === "jobMaterials" ? (
            <JobMaterials />
          ) : (
            <Documents />
          )}
        </div>
      </div>
    </div>
  );
}
