import { useState, useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

import AppContext from "../../context/AppContext";
import { postContactUs } from "../../services/ContactUsService";

const useStyles = makeStyles({
  root: {
    fontFamily: "Arial",
    width: "90%",
    margin: "auto",
    maxWidth: 1400,
  },
  container: {
    margin: "5rem auto",
  },
});

export default function ContactUs() {
  const classes = useStyles();
  const { user } = useContext(AppContext);

  const [formData, setFormData] = useState({
    contactType: "ISSUE",
    nameIncluded: false,
    subject: "",
    description: "",
  });

  const handleContactTypeChange = (e) => {
    setFormData({
      ...formData,
      contactType: e.target.value,
    });
  };

  const handleNameIncludedChange = (e) => {
    setFormData({
      ...formData,
      nameIncluded: e.target.checked,
    });
  };

  const handleSubjectChange = (e) => {
    setFormData({ ...formData, subject: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setFormData({ ...formData, description: e.target.value });
  };

  const handleSubmit = () => {
    let data = {
      REASON: formData.contactType,
      NAME: formData.nameIncluded ? user.USERNAME : "",
      SUBJECT: formData.subject,
      DESCRIPTION: formData.description,
      USERNAME: user.USERNAME,
    };

    postContactUs(data)
      .then((res) => {
        if (res.status === 200) {
          setFormData({
            contactType: "ISSUE",
            nameIncluded: false,
            subject: "",
            description: "",
          });
          toast.success(
            `${
              formData.contactType === "ISSUE" ? "Issue" : "Suggestion"
            } submitted!`
          );
        } else {
          toast.success(
            `${
              formData.contactType === "ISSUE" ? "Issue" : "Suggestion"
            } not submitted!`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Unexpected error occurs!");
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={<Typography variant="h5">Contact Us</Typography>}
              />
              <Divider />
              <CardContent>
                <Grid container justifyContent="center">
                  <Grid item lg={6} md={8} sm={12} xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            value={formData.contactType}
                            onChange={handleContactTypeChange}
                            row
                          >
                            <FormControlLabel
                              value="ISSUE"
                              control={<Radio />}
                              label="Issue"
                            />
                            <FormControlLabel
                              value="SUGGESTION"
                              control={<Radio />}
                              label="Suggestions"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formData.nameIncluded}
                              onChange={handleNameIncludedChange}
                            />
                          }
                          label="Include Name"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Subject"
                          type="search"
                          variant="outlined"
                          value={formData.subject}
                          onChange={handleSubjectChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Description"
                          type="search"
                          variant="outlined"
                          value={formData.description}
                          onChange={handleDescriptionChange}
                          fullWidth
                          multiline
                          rows={6}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button variant="outlined" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
