import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

export default function JobMaterialsTable({
  title,
  loading,
  items,
  handleAdd,
  handleEdit,
  handleDelete,
}) {
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "PRO_ID",
      label: "PRO_ID",
    },
    {
      name: "JOB_ID",
      label: "JOB_ID",
    },
    {
      name: "SUPPLIER_ID",
      label: "SUPPLIER_ID",
    },
    {
      name: "ITEM_ID",
      label: "ITEM_ID",
    },
    {
      name: "ITEM_DESC",
      label: "ITEM_DESC",
    },
    {
      name: "ITEM_SUPPLIER",
      label: "ITEM_SUPPLIER",
    },
    {
      name: "ITEM_COSTxUNIT",
      label: "ITEM_COSTxUNIT",
    },
    {
      name: "ITEM_UNIT",
      label: "ITEM_UNIT",
    },
    {
      name: "ITEM_DIV",
      label: "ITEM_DIV",
    },
    {
      name: "ITEM_COST",
      label: "ITEM_COST",
    },
    {
      name: "ITEM_SHORT_DESC",
      label: "ITEM_SHORT_DESC",
    },
    {
      name: "ITEM_CATEGORY",
      label: "ITEM_CATEGORY",
    },
    {
      name: "ITEM_ORDER",
      label: "ITEM_ORDER",
    },
    {
      name: "CREATE_DATE",
      label: "CREATE_DATE",
    },
    {
      name: "",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleEdit && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={(event) => handleRowEdit(event, rowData)}
                    disabled={loading}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
              {handleDelete && (
                <Grid item>
                  <IconButton
                    color="error"
                    onClick={(event) => handleRowDelete(event, rowData)}
                    disabled={loading}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null) {
      setData([...items]);
    }
  }, [items]);

  const handleRowEdit = (event, rowData) => {
    event.stopPropagation();
    handleEdit(rowData);
  };

  const handleRowDelete = (event, rowData) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
