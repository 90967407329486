import { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  Backup as BackupIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from "@mui/icons-material";

const FileUploadCard = ({ file = null, handleChange }) => {
  const fileUploadRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(file);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(!!file);
    setSelectedFile(file);
  }, [file]);

  const handleUploadClick = () => {
    fileUploadRef.current.click();
  };

  const formatFileSize = (size) => {
    if (size < 1024) {
      return `${size} B`;
    } else if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)} KB`;
    } else {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    const reader = new FileReader();
    reader.readAsBinaryString(selectedFile);
    reader.onload = () => {
      const binaryString = reader.result;
      const base64String = btoa(binaryString);
      const fileData = {
        name: selectedFile.name,
        size: selectedFile.size,
        type: selectedFile.type,
        file: base64String,
      };

      setSelected(true);
      setSelectedFile(fileData);
      handleChange(fileData);
    };
  };

  const handleFileRemove = () => {
    setSelected(false);
    setSelectedFile(null);
    handleChange(null);
    fileUploadRef.current.value = ""; // Reset the file input value
  };

  return (
    <Card>
      <CardContent sx={{ height: 160, display: "flex", alignItems: "center" }}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={4}
        >
          {selected ? (
            <>
              <Grid item>
                <InsertDriveFileIcon fontSize="large" />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <strong>Name: </strong>
                  {selectedFile?.name}
                </Typography>
                <Typography variant="body2">
                  <strong>Size: </strong>
                  {formatFileSize(selectedFile?.size)}
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <BackupIcon fontSize="large" color="grey" />
              </Grid>
              <Grid item>
                <Typography variant="body2" color="grey">
                  No file chosen, yet!
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <>
              {selected ? (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={handleFileRemove}
                >
                  Remove a File
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={handleUploadClick}
                >
                  Choose a File
                </Button>
              )}
              <input
                ref={fileUploadRef}
                type="file"
                accept="*"
                hidden
                onChange={handleFileUpload}
              />
            </>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default FileUploadCard;
