import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";

import Orders from "./Orders";
import OrderSummary from "./OrderSummary";

const useStyles = makeStyles({
  root: {},
});

export default function AdminOrders() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState("orders");

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab label="Orders" value="orders" />
                <Tab label="Order Summary By Job" value="summary" />
              </Tabs>
            </Box>
            <TabPanel value="orders">
              <Orders />
            </TabPanel>
            <TabPanel value="summary">
              <OrderSummary />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
}
