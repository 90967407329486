import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

export default function JobOptionsTable({
  title,
  loading,
  items,
  hiddenColumns = [],
}) {
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "Description",
      label: "Description",
    },
    {
      name: "Qty",
      label: "Qty",
    },
    {
      name: "Cost",
      label: "Cost",
      options: {
        sort: true,
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = parseFloat(obj1.data);
            const val2 = parseFloat(obj2.data);
            data.sort((a, b) => {
              return (
                (parseFloat(a.ITEM_COST) - parseFloat(b.ITEM_COST)) *
                (order === "asc" ? 1 : -1)
              );
            });

            return (val1 - val2) * (order === "asc" ? 1 : -1);
          };
        },
        customBodyRender: (value, tableMeta) => {
          return "$" + parseFloat(value).toFixed(2);
        },
      },
    },
  ];

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  };

  useEffect(() => {
    if (items !== null) setData([...items]);
  }, [items]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
