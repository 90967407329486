export const info = [
    {
        title: 'Admin',
		info: (<>
				<p>
					<b>User</b>
				</p>
				<p>
					a.Add / Edit<br />
					b.User Roles- Employee, Trim, Estimator, Admin
				</p>
				<p>
					<b>Change Request</b>
				</p>
				<p>
					a.Approve, Deny, Edit<br />
					b.Add cost<br />
					c.Select Email to customer y / n with or without invoice signature required<br />
					d.Approval required checked will send invoice link in email to customer to invoice signature page<br />
					e.All approved change requests will default send to email to material supplier(if applicable), estimator and user who logged request
				</p>
				<p>
					<b>Tool Management</b>
				</p>
				<p>
					a.Add / Edit tool, service status and tool location
				</p>
				<p>
					<b>Inventory</b>
				</p>
				<p>
					a.Set Quarterly inventory start date<br />
					b.Validate shop inventory
				</p>
		</>)
	},
	{
		title: 'Dashboard',
		info: (<> 
			<p>
				<b>Message</b>
			</p>
			<p>
				a.Lists new message <br />
				b.Check new message as reviewed	
			</p>
			<p>
				<b>Change Request</b>
			</p>
			<p>
				a.Lists all open change requests
			</p>
			<p>
				<b>Orders</b>
			</p>
			<p>
				a.Lists Orders placed
			</p>
			<p>
				<b>Tools</b>
			</p>
			<p>
				a.Lists Tools requested
			</p>
		</>)
	},
	{
		title: 'Change Request',
		info: (<>
			<p>
				a.Request types (EWO,Material Shortage,Field EPO Short,Backcharge,Service,Time and Half and Theft)<br />
				b.Project<br />
				c.Job<br />
				d.Estimator<br />
				e.Description<br />
				d.Add Receipt (if applicable)<br />
				e.Labor Description<br />
				f.Labor Hours<br />
				g.Materials (select from manual entry,current sppllier/s used,all suppliers)<br />
				h.Material Description<br />
				i.Material Quantity<br />
			</p>
		</>)
	},
	{
		title: 'Orders',
		info: (<>
			<p>
				a.Project<br />
				b.Job<br />
				c.Phases<br />
				d.Material removal option<br />
			</p>
		</>)
	},
	{
		title: 'Tools',
		info: (<>
			<p>
				a.Project<br />
				b.Job<br />
				c.Tool selection<br />
				d.Tool requested emailed to shop<br />
				e.Request sending tool to shop or another employee<br />
				f.Approve or deny tool request sent from another employee<br />
			</p>
		</>)
	},
	{
		title: 'Inventory',
		info: (<>
			<p>
				a.Check tools in possession quarterly
			</p>
		</>)
	},
	{
		title: 'Reports',
		info: (<>
			<p>
				a.WIP (Admin)<br />
				b.Trim Hours (Trim,Estimator,Admin)<br />
				c.All Hours (Estimator,Admin)
			</p>
		</>)
	},
	{
		title: 'Receipts',
		info: (<>
			<p>
				a.Description<br />
				b.Price<br />
				c.Receipt upload
				</p>
		</>)
    }
]