import MUIDataTable from "mui-datatables";
import {
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

const defaultImage = "/assets/images/image-upload-placeholder.png";

export default function OrdersTable({ title, loading, items }) {
  const columns = [
    {
      name: "RECEIPTS_ID",
      label: "ID",
    },
    {
      name: "FILE_STREAM",
      label: "Image",
      options: {
        customBodyRender: (value, tableMeta) => {
          const image = value
            ? `data:image/jpeg;base64, ${value}`
            : defaultImage; // IMAGE DATA

          return (
            <Card>
              <CardActionArea>
                <CardMedia
                  image={image}
                  title="receipt thumbnail"
                  sx={{ height: 120 }}
                />
              </CardActionArea>
            </Card>
          );
        },
      },
    },
    {
      name: "DESCRIPTION",
      label: "Title",
    },
    {
      name: "COMMENTS",
      label: "Description",
    },
    {
      name: "COST",
      label: "Cost",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          return "$" + parseFloat(value).toFixed(2);
        },
      },
    },
    {
      name: "USERNAME",
      label: "Username",
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={items}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
