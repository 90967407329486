import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@mui/styles";
import {
  Chip,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import OrderDetails from "./OrderDetails";

const useStyles = makeStyles({});

export default function OrdersTable({
  title,
  loading,
  items,
  hiddenColumns = [],
  handleSubmit,
}) {
  const classes = useStyles();
  const [rowsExpanded, setRowsExpanded] = useState([]);

  const columns = [
    {
      name: "ORDER_ID",
      label: "#",
    },
    {
      name: "DESCRIPTION",
      label: "Project",
    },
    {
      name: "JOB_DESC",
      label: "Job",
      options: {
        display: !hiddenColumns.includes("JOB_DESC"),
      },
    },
    {
      name: "UNIT_ID",
      label: "Unit",
      options: {
        display: !hiddenColumns.includes("UNIT_ID"),
      },
    },
    {
      name: "PHASE_ID",
      label: "Phase",
      options: {
        display: !hiddenColumns.includes("PHASE_ID"),
      },
    },
    {
      name: "FOREMAN",
      label: "Foreman",
    },
    {
      name: "PO_CREATE_DATE",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = items[rowIndex];

          return value ? (
            <Chip label="Closed" variant="outlined" color="error" />
          ) : (
            <Chip label="In progress" variant="outlined" color="primary" />
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    expandableRows: true,
    rowsExpanded: rowsExpanded,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const selectedOrderID = rowData[0];
      const selectedOrder = items.find(
        (item) => item.ORDER_ID === selectedOrderID
      );

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12}>
                <OrderDetails
                  data={selectedOrder}
                  handleUpdate={handleOrderUpdate}
                />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    },
  };

  const handleRowExpandToggle = (rowData) => {
    const expandedRowIndex = rowsExpanded.indexOf(rowData.index);

    if (expandedRowIndex === -1) {
      // Row is currently not expanded, so add it to the rowsExpanded array
      setRowsExpanded([...rowsExpanded, rowData.index]);
    } else {
      // Row is currently expanded, so remove it from the rowsExpanded array
      setRowsExpanded(rowsExpanded.filter((index) => index !== rowData.index));
    }
  };

  const handleOrderUpdate = (payload) => {
    handleSubmit(payload);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={items}
          columns={columns}
          options={options}
          onRowExpansionChange={handleRowExpandToggle}
        />
      </Grid>
    </Grid>
  );
}
