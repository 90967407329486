import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { FormControl, Grid } from "@mui/material";

import AppContext from "../../context/AppContext";
import { getJobs } from "../../services/JobService";
import { getProjects } from "../../services/ProjectService";
import { getForemanHours } from "../../services/ReportService";
import { getForemen } from "../../services/UserService";

import Autocomplete from "../../components/Autocomplete";
import HoursSummaryTable from "../../components/CustomTable/HoursSummaryTable";

const useStyles = makeStyles({
  root: {},
});

export default function ForemanHours() {
  const classes = useStyles();
  const { jobs, projects, user, setJobs, setProjects } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [foremen, setForemen] = useState([]);
  const [selectedForeman, setSelectedForeman] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingForemen, setLoadingForemen] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);

  useEffect(() => {
    if (!user) return;

    let mounted = true;

    const retrieveForemen = async () => {
      try {
        if (mounted) setLoadingForemen(true);
        const resForemen = await getForemen();
        if (mounted) {
          setForemen(resForemen);
          setLoadingForemen(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading foremen!");
      }
    };

    const retrieveProjects = async () => {
      try {
        if (mounted) setLoadingProjects(true);
        const newProjects = await getProjects();
        if (mounted) {
          setProjects(newProjects);
          setLoadingProjects(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading projects!");
      }
    };

    const retrieveJobs = async () => {
      try {
        if (mounted) setLoadingJobs(true);
        const newJobs = await getJobs();
        if (mounted) {
          setJobs(newJobs);
          setLoadingJobs(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading jobs!");
      }
    };
    if (user.USER_ROLE === "ADMIN") retrieveForemen();
    retrieveProjects();
    retrieveJobs();

    return () => {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    if (!user || (user.USER_ROLE === "ADMIN" && !selectedForeman)) return;

    let mounted = true;

    const retrieveForemanHours = async () => {
      try {
        if (mounted) setLoading(true);
        const foremanID =
          user.USER_ROLE === "ADMIN"
            ? selectedForeman.USER_ID
            : user.USER_ROLE === "FOREMAN"
            ? user.USER_ID
            : null;
        if (!foremanID) return;

        const res = await getForemanHours(foremanID);

        if (mounted) {
          setData(res);
          setLoading(false);
        }
      } catch (e) {
        console.error(e);
        toast.error("Unexpected error occurs while loading foreman hours!");
      }
    };

    retrieveForemanHours();

    return () => {
      mounted = false;
    };
  }, [user, selectedForeman]);

  const handleForemanChange = (e, newValue) => {
    if (!newValue) setData([]);
    setSelectedForeman(newValue);
  };

  const handleProjectChange = (e, newValue) => {
    if (!newValue) setSelectedJob(null);
    setSelectedProject(newValue);
  };

  const handleJobChange = (e, newValue) => {
    console.log(newValue);
    setSelectedJob(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xl={6} md={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                {user.USER_ROLE === "ADMIN" && (
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <Autocomplete
                        label="Foreman"
                        size="small"
                        variant="outlined"
                        loading={loadingForemen}
                        disabled={loadingForemen}
                        options={foremen}
                        isOptionEqualToValue={(option, value) =>
                          option.USER_ID === value.USER_ID
                        }
                        getOptionLabel={(option) => option.USERNAME}
                        value={selectedForeman}
                        onChange={handleForemanChange}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      label="Project"
                      size="small"
                      variant="outlined"
                      loading={loadingProjects}
                      disabled={loadingProjects}
                      options={projects}
                      isOptionEqualToValue={(option, value) =>
                        option.PRO_ID === value.PRO_ID
                      }
                      getOptionLabel={(option) => option.DESCRIPTION}
                      value={selectedProject}
                      onChange={handleProjectChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      label="Job"
                      size="small"
                      variant="outlined"
                      loading={loadingJobs}
                      disabled={loadingJobs || !selectedProject}
                      options={jobs.filter(
                        (job) =>
                          !selectedProject ||
                          job.PRO_ID === selectedProject.PRO_ID
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.JOB_ID === value.JOB_ID
                      }
                      getOptionLabel={(option) => option.JOB_DESC}
                      value={selectedJob}
                      onChange={handleJobChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <HoursSummaryTable
            title="Foreman Hours"
            loading={loading}
            items={data.filter(
              (item) =>
                (!selectedProject ||
                  item.JOB === selectedProject.DESCRIPTION) &&
                (!selectedJob ||
                  parseInt(item.JOB_ID) === parseInt(selectedJob.JOB_ID))
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
}
