import { useEffect, useReducer } from "react";

import AppContext from "./AppContext";
import AppReducer from "./AppReducer";
import {
  SET_ADMIN_CHANGE_REQUESTS,
  SET_ADMIN_TOOL_ORDERS,
  SET_ALL_TOOLS,
  SET_CHANGE_REQUESTS,
  SET_ESTIMATORS,
  SET_FOREMEN,
  SET_JOBS,
  SET_LABOR_HOURS,
  SET_LOADING_ALL,
  SET_MESSAGES,
  SET_MATERIALS,
  SET_ORDERS,
  SET_PHASES,
  SET_PROJECTS,
  SET_REPORTS,
  SET_REVIEWER_TOOL_ORDERS,
  SET_SUPPLIERS,
  SET_TOOL_ORDERS,
  SET_TOOLS,
  SET_UNITS,
  SET_USER,
  SET_USER_AUTHENTICATED,
  SET_USERS,
  SET_WIPS,
  SET_WORK,
} from "./types";

import useLocalStorage from "../hooks/useLocalStorage";

const API_URL = process.env.REACT_APP_API_URL;

const AppContextProvider = ({ children }) => {
  let initialState = {
    adminChangeRequests: [],
    adminToolOrders: [],
    allTools: [],
    changeRequests: [],
    estimators: [],
    foremen: [],
    jobs: [],
    laborHours: [],
    loadingAll: false,
    materials: [],
    messages: [],
    orders: [],
    projects: [],
    phases: [],
    reports: [],
    reviewerToolOrders: [],
    suppliers: [],
    tools: [],
    toolOrders: [],
    units: [],
    user: null,
    userAuthenticated: false,
    users: [],
    wips: [],
    work: [],
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);
  const [authenticated, setAuthenticated] = useLocalStorage(
    "authenticated",
    false
  );
  const [userData, setUserData] = useLocalStorage("userData", {});

  const setAdminChangeRequests = (task) => {
    dispatch({ type: SET_ADMIN_CHANGE_REQUESTS, payload: task });
  };

  const setAdminToolOrders = (task) => {
    dispatch({ type: SET_ADMIN_TOOL_ORDERS, payload: task });
  };

  const setAllTools = (task) => {
    dispatch({ type: SET_ALL_TOOLS, payload: task });
  };

  const setChangeRequests = (task) => {
    dispatch({ type: SET_CHANGE_REQUESTS, payload: task });
  };

  const setEstimators = (task) => {
    dispatch({ type: SET_ESTIMATORS, payload: task });
  };

  const setForemen = (task) => {
    dispatch({ type: SET_FOREMEN, payload: task });
  };

  const setJobs = (task) => {
    dispatch({ type: SET_JOBS, payload: task });
  };

  const setLaborHours = (task) => {
    dispatch({ type: SET_LABOR_HOURS, payload: task });
  };

  const setLoadingAll = (task) => {
    dispatch({ type: SET_LOADING_ALL, payload: task });
  };

  const setMaterials = (task) => {
    dispatch({ type: SET_MATERIALS, payload: task });
  };

  const setMessages = (task) => {
    dispatch({ type: SET_MESSAGES, payload: task });
  };

  const setOrders = (task) => {
    dispatch({ type: SET_ORDERS, payload: task });
  };

  const setPhases = (task) => {
    dispatch({ type: SET_PHASES, payload: task });
  };

  const setProjects = (task) => {
    dispatch({ type: SET_PROJECTS, payload: task });
  };

  const setReports = (task) => {
    dispatch({ type: SET_REPORTS, payload: task });
  };

  const setReviewerToolOrders = (task) => {
    dispatch({ type: SET_REVIEWER_TOOL_ORDERS, payload: task });
  };

  const setSuppliers = (task) => {
    dispatch({ type: SET_SUPPLIERS, payload: task });
  };

  const setTools = (task) => {
    dispatch({ type: SET_TOOLS, payload: task });
  };

  const setToolOrders = (task) => {
    dispatch({ type: SET_TOOL_ORDERS, payload: task });
  };

  const setUnits = (task) => {
    dispatch({ type: SET_UNITS, payload: task });
  };

  const setUser = (task) => {
    setUserData(task);
    dispatch({ type: SET_USER, payload: task });
  };

  const setUserAuthenticated = (task) => {
    setAuthenticated(task);
    dispatch({ type: SET_USER_AUTHENTICATED, payload: task });
  };

  const setUsers = (task) => {
    dispatch({ type: SET_USERS, payload: task });
  };

  const setWIPs = (task) => {
    dispatch({ type: SET_WIPS, payload: task });
  };

  const setWork = (task) => {
    dispatch({ type: SET_WORK, payload: task });
  };

  useEffect(() => {
    if (userData && authenticated) {
      dispatch({ type: SET_USER, payload: userData });
      dispatch({ type: SET_USER_AUTHENTICATED, payload: authenticated });
    }
  }, [authenticated, userData]);

  useEffect(() => {
    // need to have suppliers api,,, etc
    if (state.user === null) {
      return;
    }

    if (state.user.USER_ROLE === "ADMIN") {
      const adminFetches = async () => {
        try {
          let [resLaborHours, resWIPs] = await Promise.all([
            fetch(API_URL + `/WIP/GETCompleted`).then((res) => res.json()),
            fetch(API_URL + `/WIP/GETWip`).then((res) => res.json()),
          ]).catch((err) => console.log(err));

          setLaborHours(resLaborHours);
          setWIPs(resWIPs);
        } catch (error) {
          console.log(error);
        }
      };
      adminFetches();
    }
    if (state.user.USER_ROLE === "ESTIMATOR") {
      const estimatorFetches = async () => {
        try {
          let [resLaborHours, resToolOrders] = await Promise.all([
            fetch(API_URL + `/WIP/GETCompleted`).then((res) => res.json()),
            fetch(
              API_URL + `/ToolOrder/GETReviewer/${state.user.USERNAME}`
            ).then((res) => res.json()),
          ]).catch((err) => console.log(err));

          setLaborHours(resLaborHours);
          setAdminToolOrders(resToolOrders);
        } catch (error) {
          console.log(error);
        }
      };
      estimatorFetches();
    }
  }, [state.user]);

  return (
    <AppContext.Provider
      value={{
        ...state,
        setAdminChangeRequests,
        setAdminToolOrders,
        setAllTools,
        setChangeRequests,
        setEstimators,
        setForemen,
        setJobs,
        setLaborHours,
        setLoadingAll,
        setMaterials,
        setMessages,
        setOrders,
        setProjects,
        setPhases,
        setReports,
        setReviewerToolOrders,
        setSuppliers,
        setTools,
        setToolOrders,
        setUnits,
        setUser,
        setUserAuthenticated,
        setUsers,
        setWIPs,
        setWork,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
