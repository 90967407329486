import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

import AppContext from "../../../context/AppContext";
import {
  getAllOrders,
  getForemanOrders,
  getOrders,
  putOrder,
} from "../../../services/OrderService";
import { getSuppliers } from "../../../services/SupplierService";

import OrdersTable from "../../../components/CustomTable/OrdersTable";

const useStyles = makeStyles({
  root: {},
});

export default function Orders() {
  const classes = useStyles();
  const { orders, user, setOrders, setSuppliers } = useContext(AppContext);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] =
    useState("in-progress");
  const [submitting, setSubmitting] = useState(false);
  const [loadingSuppliers, setLoadingSuppliers] = useState(false);

  const retrieveOrders = async () => {
    try {
      setLoadingOrders(true);
      const resOrders =
        user.USER_ROLE === "ADMIN"
          ? await getAllOrders()
          : user.USER_ROLE === "FOREMAN"
          ? await getForemanOrders(user)
          : await getOrders(user);

      setOrders(resOrders);
      setLoadingOrders(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading orders!");
    }
  };

  const retrieveSuppliers = async () => {
    try {
      setLoadingSuppliers(true);
      const resSuppliers = await getSuppliers();
      setSuppliers(resSuppliers);
      setLoadingSuppliers(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading suppliers!");
    }
  };

  useEffect(() => {
    retrieveOrders();
    retrieveSuppliers();
  }, []);

  const handleStatusFilterChange = (e) => {
    setSelectedStatusFilter(e.target.value);
  };

  const handleSubmit = (payload) => {
    setSubmitting(true);
    putOrder(payload)
      .then(async (res) => {
        setSubmitting(false);
        if (res.status === 200) {
          await retrieveOrders();
          toast.success("Entry recorded!");
        } else {
          toast.error("Entry not recorded!");
        }
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        toast.error(`${err}`);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <ToggleButtonGroup
                size="small"
                aria-label="Change Request Status"
                value={selectedStatusFilter}
                onChange={handleStatusFilterChange}
                exclusive
              >
                <ToggleButton value="">All</ToggleButton>
                <ToggleButton color="primary" value="in-progress">
                  In progress
                </ToggleButton>
                <ToggleButton color="error" value="closed">
                  Closed
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <OrdersTable
            title="Orders"
            loading={loadingOrders}
            items={orders.filter(
              (item) =>
                selectedStatusFilter === "" ||
                (selectedStatusFilter === "in-progress" &&
                  item.PO_CREATE_DATE === null) ||
                (selectedStatusFilter === "closed" &&
                  item.PO_CREATE_DATE !== null)
            )}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </div>
  );
}
