import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

import {
  getContactUsData,
  putContactUs,
} from "../../../services/ContactUsService";

import ContactUsTable from "../../../components/CustomTable/ContactUsTable";

const useStyles = makeStyles({
  root: {},
});

export default function ContactUs() {
  const classes = useStyles();
  const [contactUsData, setContactUsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const retrieveContactUsData = async () => {
    try {
      setIsLoading(true);
      const resContactUsData = await getContactUsData();
      setContactUsData(resContactUsData);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs!");
    }
  };

  useEffect(() => {
    retrieveContactUsData();
  }, []);

  const handleRowEdit = (rowData) => {
    const putData = {
      ...rowData,
      RESOLUTION: rowData.RESOLUTION,
      COMMENTS: rowData.COMMENTS,
      STATUS: rowData.STATUS,
      SEVERITY: rowData.SEVERITY,
    };

    setIsLoading(true);

    putContactUs(putData)
      .then(async (res) => {
        setIsLoading(false);
        if (res.status === 200) {
          await retrieveContactUsData();
          toast.success("Entry updated.");
        } else toast.error("Entry not updated!");
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
        toast.error("Unexpected error occurs while updating the data!");
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ContactUsTable
            title="Contact Us"
            loading={isLoading}
            items={contactUsData}
            hiddenColumns={[
              "NAME",
              "DESCRIPTION",
              "RESOLUTION",
              "COMMENTS",
              "SEVERITY",
              "STATUS",
            ]}
            handleEdit={handleRowEdit}
          />
        </Grid>
      </Grid>
    </div>
  );
}
