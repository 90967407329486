import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@mui/icons-material";

import Autocomplete from "../Autocomplete";

import AppContext from "../../context/AppContext";
import { getForemen } from "../../services/UserService";
import { getProjects } from "../../services/ProjectService";

export default function ForemenTable({
  title,
  items,
  hiddenColumns = [],
  loading,
  handleAdd,
  handleEdit,
  handleDelete,
}) {
  const { projects, setProjects } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [editableRowIndex, setEditableRowIndex] = useState(-1);
  const [editableRow, setEditableRow] = useState(null);
  const [isInvalid, setIsInvalid] = useState(false);
  const [errors, setErrors] = useState({ foreman: "", projects: "" });
  const [foremen, setForemen] = useState([]);
  const [availableForemen, setAvailableForemen] = useState([]);
  const [loadingForemen, setLoadingForemen] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);

  const retrieveForemen = async () => {
    try {
      setLoadingForemen(true);
      const resForemen = await getForemen();
      const resAvailableForemen = resForemen.filter(
        (fm) => !items.map((item) => item.USER_ID).includes(fm.USER_ID)
      );

      setForemen(resForemen);
      setAvailableForemen(resAvailableForemen);
      setLoadingForemen(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading foremen!");
    }
  };

  const retrieveProjects = async () => {
    try {
      setLoadingProjects(true);
      const newProjects = await getProjects();
      setProjects(newProjects);
      setLoadingProjects(false);
    } catch (e) {
      console.error(e);
      toast.error("Unexpected error occurs while loading projects!");
    }
  };

  useEffect(() => {
    retrieveForemen();
    retrieveProjects();
  }, [items]);

  const columns = [
    {
      name: "USER_ID",
      label: "Name",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <FormControl sx={{ minWidth: "300px" }}>
                <Autocomplete
                  size="small"
                  label="Foreman"
                  loading={loadingForemen}
                  options={availableForemen || []}
                  getOptionLabel={(option) => option.USERNAME}
                  value={foremen.find(
                    (item) => item.USER_ID === editableRow.USER_ID
                  )}
                  onChange={handleForemanChange}
                />
                {!editableRow.USER_ID && (
                  <FormHelperText error>Select a foreman.</FormHelperText>
                )}
              </FormControl>
            );
          }

          return foremen.find(
            (foreman) => foreman.USER_ID === parseInt(data[rowIndex].USER_ID)
          )?.USERNAME;
        },
      },
    },
    {
      name: "PRO_ID",
      label: "Projects",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;

          if (rowIndex === editableRowIndex) {
            return (
              <FormControl sx={{ minWidth: "300px" }}>
                <Autocomplete
                  size="small"
                  label="Project"
                  loading={loadingProjects}
                  options={projects || []}
                  getOptionLabel={(option) => option.DESCRIPTION}
                  value={projects.filter((item) =>
                    editableRow.PRO_ID?.split(",").includes(String(item.PRO_ID))
                  )}
                  onChange={handleProjectChange}
                  multiple
                />
                {!editableRow.PRO_ID && (
                  <FormHelperText error>Select the projects.</FormHelperText>
                )}
              </FormControl>
            );
          }

          return (
            <Grid container spacing={1}>
              {projects
                .filter((item) =>
                  data[rowIndex].PRO_ID?.split(",").includes(
                    String(item.PRO_ID)
                  )
                )
                .map((item) => (
                  <Grid item>
                    <Chip variant="outlined" label={item.DESCRIPTION} />
                  </Grid>
                ))}
            </Grid>
          );
        },
      },
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleEdit && (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      {editableRowIndex === rowIndex ? (
                        <IconButton
                          title="save"
                          color="primary"
                          onClick={handleRowSave}
                          disabled={Boolean(
                            !editableRow.USER_ID || !editableRow.PRO_ID
                          )}
                        >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          title="edit"
                          color="primary"
                          onClick={(event) => handleRowEdit(event, rowIndex)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Grid>
                    {editableRowIndex === rowIndex && (
                      <Grid item>
                        <IconButton
                          title="cancel"
                          onClick={handleRowEditCancel}
                        >
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {handleDelete && editableRowIndex !== rowIndex && (
                <Grid item>
                  <IconButton
                    title="delete"
                    color="error"
                    onClick={(event) => handleRowDelete(event, rowData)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleRowAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null) setData([...items]);
  }, [items]);

  const handleForemanChange = (e, newValue) => {
    setErrors({
      ...errors,
      foreman: newValue ? "" : "Foreman is required.",
    });

    setEditableRow({
      ...editableRow,
      USER_ID: newValue.USER_ID,
    });
  };

  const handleProjectChange = (e, newValue) => {
    setErrors({
      ...errors,
      projects: newValue ? "" : "Projects are required.",
    });

    setEditableRow({
      ...editableRow,
      PRO_ID: newValue.map((item) => item.PRO_ID).join(","),
    });
  };

  const handleRowAdd = (event) => {
    event.preventDefault();

    let newItems = [];
    const newRow = {
      ID: items.length,
      USER_ID: null,
      PRO_ID: null,
    };

    newItems.push(newRow);
    newItems = [...newItems, ...items];

    setIsAdding(true);
    setData(newItems);
    setEditableRowIndex(0);
    setEditableRow(newRow);
  };

  const handleRowEdit = (event, index) => {
    event.preventDefault();

    if (isAdding) {
      setIsAdding(false);
      setData(items);
      setEditableRowIndex(index - 1);
    } else {
      setEditableRowIndex(index);
    }

    setEditableRow(data[index]);
  };

  const handleRowSave = (event) => {
    event.preventDefault();

    const rowValid = Boolean(editableRow.USER_ID && editableRow.PRO_ID);
    if (!rowValid) return;

    const rowData = {
      ...editableRow,
    };

    if (isAdding) {
      setIsAdding(false);
      handleAdd(rowData);
    } else handleEdit(rowData);
    setEditableRowIndex(-1);
    setEditableRow(null);
  };

  const handleRowEditCancel = () => {
    if (isAdding) {
      setIsAdding(false);
      setData(items);
    }
    setEditableRow(null);
    setEditableRowIndex(-1);
  };

  const handleRowDelete = (event, rowData) => {
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
    </Grid>
  );
}
