import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Typography } from "@mui/material";
import {
  Add as AddIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
  DoneOutline as DoneOutlineIcon,
  Edit as EditIcon,
} from "@mui/icons-material";

import { convertDate } from "../../utils/function";

export default function ToolOrdersTable({
  title,
  loading,
  items,
  hiddenColumns = [],
  handleAdd,
  handleEdit,
  handleDelete,
  handleApprove,
}) {
  const [data, setData] = useState([]);
  const [rowsExpanded, setRowsExpanded] = useState([]);

  const columns = [
    {
      name: "TOOL_REQUEST_ID",
      label: "TOOL REQUEST ID",
      options: {
        display: !hiddenColumns.includes("TOOL_REQUEST_ID"),
      },
    },
    {
      name: "USERNAME",
      label: "USERNAME",
      options: {
        display: !hiddenColumns.includes("USERNAME"),
      },
    },
    {
      name: "ID_TAG",
      label: "ID TAG",
      options: {
        display: !hiddenColumns.includes("ID_TAG"),
      },
    },
    {
      name: "TOOL_SHORT_DESCRIPTION",
      label: "TOOL",
      options: {
        display: !hiddenColumns.includes("TOOL_SHORT_DESCRIPTION"),
      },
    },
    {
      name: "TOOL_DESCRIPTION",
      label: "DESCRIPTION",
      options: {
        display: !hiddenColumns.includes("TOOL_DESCRIPTION"),
      },
    },
    {
      name: "REQUEST_DATE",
      label: "REQUEST DATE",
      options: {
        display: false,
      },
    },
    {
      name: "REQUEST_SENT_TO",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "DESCRIPTION",
      label: "PROJECT",
      options: {
        display: !hiddenColumns.includes("PROJECT"),
      },
    },
    {
      name: "JOB_DESC",
      label: "JOB SITE",
      options: {
        display: !hiddenColumns.includes("JOB"),
      },
    },
    {
      name: "APPROVED",
      label: "APPROVED",
      options: {
        display: handleApprove ? true : false,
        setCellProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return handleApprove ? (
            <ToggleButtonGroup
              value={
                !isNaN(parseInt(value)) ? (parseInt(value) ? 1 : 0) : undefined
              }
              onChange={(event, newValue) =>
                handleToolOrderApprove(event, newValue, rowData)
              }
              disabled={loading}
              color="primary"
              size="small"
              exclusive
              aria-label="Platform"
            >
              <ToggleButton value={1}>
                <DoneOutlineIcon fontSize="small" color="primary" />
              </ToggleButton>
              <ToggleButton value={0}>
                <ClearIcon fontSize="small" color="error" />
              </ToggleButton>
            </ToggleButtonGroup>
          ) : (
            <Chip
              label={
                value !== null
                  ? parseInt(value)
                    ? "Approved"
                    : "Declined"
                  : "Pending"
              }
              color={
                value !== null
                  ? parseInt(value)
                    ? "primary"
                    : "error"
                  : "success"
              }
            />
          );
        },
      },
    },
    {
      name: "ACTIONS",
      label: "ACTIONS",
      options: {
        display: Boolean(handleEdit || handleDelete),
        customBodyRender: (value, tableMeta) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = data[rowIndex];

          return (
            <Grid container alignItems="center" spacing={1}>
              {handleEdit && (
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={(event) => handleToolEdit(event, rowData)}
                    disabled={loading}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
              {handleDelete && (
                <Grid item>
                  <IconButton
                    color="error"
                    onClick={(event) => handleToolDelete(event, rowData)}
                    disabled={loading}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      console.log(rowData);
      const requestDate = rowData[5]; //REQUEST_DATE
      const requestSentTo = rowData[6]; //REQUSET_SENT_TO

      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <Grid container>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="span" component="p" align="right">
                          Purchase Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="span">
                          {requestDate ? convertDate(requestDate) : "undefined"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="span" component="p" align="right">
                          {`Request Sent ${handleApprove ? "From" : "To"}:`}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="span">
                          {requestSentTo ? requestSentTo : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      );
    },
    customToolbar: () => {
      return handleAdd ? (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAdd}
          disabled={loading}
        >
          Add
        </Button>
      ) : (
        <></>
      );
    },
  };

  useEffect(() => {
    if (items !== null) {
      setData([...items]);
    }
  }, [items]);

  const handleRowExpandToggle = (rowData) => {
    const expandedRowIndex = rowsExpanded.indexOf(rowData.index);

    if (expandedRowIndex === -1) {
      // Row is currently not expanded, so add it to the rowsExpanded array
      setRowsExpanded([...rowsExpanded, rowData.index]);
    } else {
      // Row is currently expanded, so remove it from the rowsExpanded array
      setRowsExpanded(rowsExpanded.filter((index) => index !== rowData.index));
    }
  };

  const handleToolEdit = (event, rowData) => {
    event.stopPropagation();
    handleEdit(rowData);
  };

  const handleToolDelete = (event, rowData) => {
    event.stopPropagation();
    event.preventDefault(); // Prevent default action
    handleDelete(rowData);
  };

  const handleToolOrderApprove = (event, newValue, rowData) => {
    event.stopPropagation();

    const newRowData = { ...rowData, APPROVED: newValue };
    handleApprove(newRowData);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <MUIDataTable
          title={
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              {loading && (
                <Grid item>
                  <CircularProgress size="1rem" />
                </Grid>
              )}
            </Grid>
          }
          data={data}
          columns={columns}
          options={options}
          onRowExpansionChange={handleRowExpandToggle}
        />
      </Grid>
    </Grid>
  );
}
