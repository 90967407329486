import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

const useStyles = makeStyles({
  root: {},
  navLink: {
    textDecoration: "none",
  },
});

const CREmailForm = ({ crData, supplier, onSubmit }) => {
  const classes = useStyles();
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [emailChoice, setEmailChoice] = useState("SEND_EMAIL_WITH_SIGNATURE");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [emailError, setEmailError] = useState("");
  const [dateError, setDateError] = useState("");
  const [formValid, setFormValid] = useState(false);
  const isEditable =
    crData?.APPROVED === null ||
    (crData?.APPROVED === "1" && crData?.PO_CREATE_DATE === null);

  useEffect(() => {
    if (crData) {
      if (crData?.PO_CREATE_DATE) setDeliveryDate(crData?.PO_CREATE_DATE);
      if (crData?.EMAIL_STATUS) setEmailChoice(crData?.EMAIL_STATUS);
      if (crData?.CUSTOMER_EMAIL_ADDRESS)
        setEmail(crData?.CUSTOMER_EMAIL_ADDRESS);
      if (crData?.NOTES) setNotes(crData?.NOTES);
    }
  }, [crData]);

  const isValidEmail = (value) => {
    // You can customize this email validation logic as needed
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(value);
  };

  const handleDeliveryDateChange = (value) => {
    if (!value) {
      setDateError("Invalid date");
      setFormValid(false);
    } else {
      setDeliveryDate(value);
      setDateError("");
      setFormValid(true);
    }
  };
  const handleEmailChoiceChange = (e) => {
    setEmailChoice(e.target.value);
  };
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;

    setEmail(emailValue);
    if (!isValidEmail(emailValue)) {
      setEmailError("Invalid email address");
      setFormValid(false);
    } else {
      setEmailError("");
      setFormValid(true);
    }
  };
  const handleNoteChange = (e) => {
    setNotes(e.target.value);
  };
  const handleSubmit = (action) => {
    const formData = {
      deliveryDate,
      emailChoice,
      email,
      notes,
      action,
    };

    onSubmit(formData);
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DatePicker
              label="Delivery Date"
              value={deliveryDate ? new Date(deliveryDate) : null}
              disabled={!isEditable}
              onChange={handleDeliveryDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  error={dateError !== ""}
                  helperText={dateError}
                  disabled={!isEditable}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                value={emailChoice}
                onChange={handleEmailChoiceChange}
              >
                <FormControlLabel
                  value="SEND_EMAIL_WITH_SIGNATURE"
                  control={<Radio size="small" />}
                  label="Send email with signature"
                  disabled={!isEditable}
                />
                <FormControlLabel
                  value="SEND_EMAIL"
                  control={<Radio size="small" />}
                  label="Send email"
                  disabled={!isEditable}
                />
                <FormControlLabel
                  value="NO_EMAIL"
                  control={<Radio size="small" />}
                  label="No email"
                  disabled={!isEditable}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              size="small"
              value={email}
              onChange={handleEmailChange}
              error={emailError !== ""}
              helperText={emailError}
              disabled={!isEditable}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              size="small"
              value={notes}
              onChange={handleNoteChange}
              disabled={!isEditable}
              minRows={3}
              maxRows={5}
              multiline
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  onClick={() => handleSubmit("ACCEPT")}
                  disabled={!formValid || !isEditable}
                  fullWidth
                >
                  Accept
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  onClick={() => handleSubmit("DENY")}
                  disabled={!isEditable}
                  fullWidth
                >
                  Deny
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ mt: 6 }}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                  <Link
                    to={`/po/preview?to=customer&crid=${
                      crData.CHANGE_REQUEST_ID
                    }&jid=${crData.JOB_ID}${
                      supplier ? `&sid=${supplier.SUPPLIER_ID}` : ""
                    }${`&ec=${emailChoice}`}${
                      email ? `&email=${email}` : ""
                    }&m=${crData.MARKUP || 0}`}
                    className={classes.navLink}
                    target="_blank"
                  >
                    <Button size="small" variant="text" fullWidth>
                      Customer's PO preview
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Link
                    to={`/po/preview?crid=${crData.CHANGE_REQUEST_ID}&jid=${
                      crData.JOB_ID
                    }${supplier ? `&sid=${supplier.SUPPLIER_ID}` : ""}`}
                    className={classes.navLink}
                    target="_blank"
                  >
                    <Button size="small" variant="text" fullWidth>
                      Supplier's PO preview
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CREmailForm;
